/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
    Route as ReactDOMRoute,
    RouteProps as ReactDOMRouterProps,
    Redirect,
} from 'react-router-dom'
import { BigLoader } from '../components/BigLoader'
import { TutorialSteps } from '../entitie/tutorial-steps'
import { useAuth } from '../hooks/use-auth'
import { useUpload } from '../hooks/use-upload'
import { useUser } from '../hooks/use-user'

interface RouterProps extends ReactDOMRouterProps {
    isPrivate: boolean
    component: React.ComponentType
}

export function Route({
    component: Component,
    isPrivate,
    ...rest
}: RouterProps) {
    // hooks
    const { token } = useAuth()
    const { getUser, user, getSubscription } = useUser()
    const { getUpload } = useUpload()

    // state
    const [busy, setBusy] = useState<boolean>(true)
    // const [interval, setMyInterval] = useState<NodeJS.Timeout>()

    useEffect(() => {
        setBusy(true)
        ;(async () => {
            // await getUpload()
            if (token) {
                await getUser()
                await getSubscription()
            }
            setBusy(false)
        })()
    }, [token])

    useEffect(() => {
        setInterval(async () => {
            await getUpload()
        }, 8000)
    }, [])

    // useEffect(() => {
    //     if (user) {
    //         setMyInterval(
    //             setInterval(async () => {
    //                 await getUpload()
    //             }, 8000)
    //         )
    //     }
    // }, [])

    return (
        <>
            {!busy ? (
                <ReactDOMRoute
                    {...rest}
                    render={() => {
                        if (isPrivate) {
                            if (!token) {
                                return <Redirect to={{ pathname: '/' }} />
                            }
                            if (
                                token &&
                                user.tutorialStep === TutorialSteps.choosePlan
                            ) {
                                return (
                                    <>
                                        <Redirect to={{ pathname: '/plans' }} />
                                        <Component />
                                    </>
                                )
                            }
                            return (
                                <>
                                    <Redirect to={{ pathname: '/home' }} />
                                    <Component />
                                </>
                            )
                        }
                        if (
                            token &&
                            user.tutorialStep === TutorialSteps.choosePlan
                        ) {
                            return <Redirect to={{ pathname: '/plans' }} />
                        }
                        if (
                            token &&
                            user.tutorialStep !== TutorialSteps.choosePlan
                        ) {
                            return <Redirect to={{ pathname: '/home' }} />
                        }

                        return <Component />
                    }}
                />
            ) : (
                <BigLoader />
            )}
        </>
    )
}
