/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { FaTasks } from 'react-icons/fa'
import { CgArrowsExchange } from 'react-icons/cg'

import { Pagination } from '@material-ui/lab'
import { RiPlaneFill } from 'react-icons/ri'
import { ImFloppyDisk } from 'react-icons/im'
import { v4 } from 'uuid'
import { FiAlertTriangle } from 'react-icons/fi'
import { PrimaryButton } from '../../components/PrimaryButton'
import { Container } from '../../container/Container'
import {
    AnalyticalPageContainer,
    PaginationContainer,
    Table,
    TablePending,
} from './styles'
import { useBooking } from '../../hooks/use-booking'
import { SingleBooking } from '../../components/SingleBooking'
import { CreditStatus } from '../../entitie/credit-status'
import { BigLoader } from '../../components/BigLoader'
import { SinglePendingBooking } from '../../components/SinglePendingBooking'

export function AnalyticalPage() {
    // hooks
    const {
        getBookings,
        bookings,
        count,
        filterBookings,
        handleFilterBookings,
        shouldRefresh,
        exportBookings,
        toggleLocator,
        isRecordLocator,
    } = useBooking()

    // state
    const [showAllPending, setShowAllPending] = useState(false)
    const [pageSelected, setPageSelected] = useState<number>(1)
    const [busyTable, setBusyTable] = useState<boolean>(false)
    const [busyExportBtn, setBusyExportBtn] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            await getBookings(0, filterBookings || {}, true)
        })()
    }, [])

    useEffect(() => {
        setPageSelected(1)
    }, [filterBookings])

    useEffect(() => {
        setBusyTable(true)
        ;(async () => {
            await getBookings(
                pageSelected * 15 - 15,
                filterBookings || {},
                true
            )
            setBusyTable(false)
        })()
    }, [filterBookings, pageSelected, shouldRefresh])

    function numberOfPages(num: number) {
        return Math.ceil(num / 15)
    }

    return (
        <Container active="DASHBOARD" switchType="ANALYTICAL">
            <AnalyticalPageContainer>
                <div className="first-row">
                    <div className="nav">
                        <button
                            type="button"
                            className="dash-btn"
                            onClick={() => {
                                setShowAllPending(false)
                                handleFilterBookings({
                                    ...filterBookings,
                                    creditStatus: undefined,
                                })
                            }}
                        >
                            <RiPlaneFill />
                            <h1>{`Todos os bilhetes ${
                                !filterBookings?.creditStatus?.filter(
                                    (c) => c === CreditStatus.PENDING
                                )
                                    ? `(${count})`
                                    : ''
                            }`}</h1>
                            <div
                                className={`${!showAllPending && 'bordered'}`}
                            />
                        </button>
                        <button
                            type="button"
                            className="config-btn"
                            onClick={() => {
                                setShowAllPending(true)
                                handleFilterBookings({
                                    ...filterBookings,
                                    creditStatus: [CreditStatus.PENDING],
                                })
                            }}
                        >
                            <FaTasks />
                            <h1>{`Bilhetes em aberto ${
                                filterBookings?.creditStatus?.filter(
                                    (c) => c === CreditStatus.PENDING
                                )
                                    ? `(${count})`
                                    : ''
                            }`}</h1>
                            <div
                                className={`${showAllPending && 'bordered'}`}
                            />
                        </button>
                    </div>
                    <div className="btn-explore">
                        <PrimaryButton
                            color="red-white"
                            title="Exportar"
                            icon={ImFloppyDisk}
                            busy={busyExportBtn}
                            asyncCallback={async () => {
                                setBusyExportBtn(true)
                                await exportBookings(filterBookings || {})
                                setBusyExportBtn(false)
                            }}
                        />
                    </div>
                </div>
                {busyTable ? (
                    <div
                        className="loader"
                        style={{ position: 'relative', height: '50vh' }}
                    >
                        <BigLoader />
                    </div>
                ) : (
                    <>
                        {showAllPending ? (
                            <TablePending>
                                <div className="primary-row">
                                    <h1 className="start">Passageiro</h1>
                                    <button
                                        className="start switch-btn"
                                        type="button"
                                        onClick={() =>
                                            toggleLocator(!isRecordLocator)
                                        }
                                    >
                                        {isRecordLocator
                                            ? 'Localizador'
                                            : 'Bilhete'}
                                        <CgArrowsExchange />
                                    </button>
                                    <h1 className="center">Companhia</h1>
                                    <h1 className="center">Situação do vôo</h1>
                                    <h1 className="center">Crédito</h1>
                                    <h1 className="center">Reembolsável</h1>
                                    <h1 className="center">
                                        Data de expiração
                                    </h1>
                                    <h1 className="end">Detalhes</h1>
                                </div>
                                {bookings?.map((booking) => {
                                    return (
                                        <SinglePendingBooking
                                            key={v4()}
                                            booking={booking}
                                        />
                                    )
                                })}
                            </TablePending>
                        ) : (
                            <Table>
                                <div className="primary-row">
                                    <h1 className="start">Passageiro</h1>
                                    <button
                                        className="start switch-btn"
                                        type="button"
                                        onClick={() =>
                                            toggleLocator(!isRecordLocator)
                                        }
                                    >
                                        {isRecordLocator
                                            ? 'Localizador'
                                            : 'Bilhete'}
                                        <CgArrowsExchange />
                                    </button>
                                    <h1 className="center">Companhia</h1>
                                    <h1 className="center">Valor pago</h1>
                                    <h1 className="center">
                                        Status do crédito
                                    </h1>
                                    <h1 className="center">
                                        Data de expiração
                                    </h1>
                                    <h1 className="end">Detalhes</h1>
                                </div>
                                {bookings?.map((booking) => {
                                    return (
                                        <SingleBooking
                                            key={v4()}
                                            booking={booking}
                                        />
                                    )
                                })}
                            </Table>
                        )}
                    </>
                )}
                <PaginationContainer>
                    <Pagination
                        count={numberOfPages(count || 0)}
                        color="secondary"
                        variant="outlined"
                        page={pageSelected}
                        size="large"
                        onChange={(event, page) => {
                            setPageSelected(page)
                        }}
                    />
                </PaginationContainer>
                <div className="legend">
                    <FiAlertTriangle />
                    <p>
                        Bilhetes com possíveis alterações de reembolso devido à
                        alterações involuntárias.
                    </p>
                </div>
            </AnalyticalPageContainer>
        </Container>
    )
}
