import styled from 'styled-components'

export const FiltersContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 3rem;
`

export const HomeAnalyticsContainer = styled.div`
    width: 100%;
    background-color: #fff;
    border: 1px solid #c7d3d1;
    border-radius: 1.6rem;
    padding: 4rem;
    margin: 2rem 0;
    .title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6rem;
        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            h1 {
                font-size: 2rem;
                font-weight: 500;
            }
            svg {
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
            }
        }
    }
    .values-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10rem;
        margin-bottom: 6rem;
        .value {
            p {
                font-size: 1.8rem;
            }
            h2 {
                margin-top: 0.7rem;
                font-size: 3.2rem;
                font-weight: 600;
            }
        }
    }
    .analytics-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 6rem;
    }
`

export const BoardingStatusContainer = styled.div`
    background-color: var(--background);
    border-radius: 1.6rem;
    padding: 2.5rem;
    .title-status-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            p {
                font-weight: 300;
                margin-top: 0.6rem;
                font-size: 1.6rem;
            }
        }
    }
    .chart-label {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        margin-top: 4rem;
        .labels {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .label {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .circle {
                        height: 1.8rem;
                        width: 1.8rem;
                        border-radius: 50%;
                        margin-right: 1.3rem;
                    }
                    .name {
                        font-size: 1.6rem;
                    }
                }
                .value {
                    font-size: 1.6rem;
                }
            }
        }
    }
`
export const PenaltiesStatusContainer = styled.div`
    border-radius: 1.6rem;
    padding: 2.5rem;
    .title-status-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            p {
                font-weight: 300;
                margin-top: 0.6rem;
                font-size: 1.6rem;
            }
        }
    }
    .charts-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem 0;
        margin-top: 4rem;
    }
`

export const PenaltyType = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .label-chart {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .label {
            h2 {
                font-size: 1.8rem;
                font-weight: 700;
            }
            p {
                font-size: 1.6rem;
            }
        }
        .value {
            font-size: 2rem;
            font-weight: 600;
        }
    }
`

export const HomeBookingsContainer = styled.div`
    width: 100%;
    background-color: #fff;
    border: 1px solid #c7d3d1;
    border-radius: 1.6rem;
    padding: 4rem;
    .title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        a {
            text-decoration: none;
        }
        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            h1 {
                font-size: 2rem;
                font-weight: 500;
            }
            svg {
                width: 1.6rem;
                height: 1.6rem;
                color: #929292;
                margin: 1rem;
            }
        }
    }
    .legend {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        p {
            color: rgba(51, 51, 51, 0.62);
            font-size: 1.6rem;
            font-weight: 400;
        }
        svg {
            color: #f1c523;
            height: 2rem;
            width: 2rem;
            margin-right: 1rem;
        }
    }
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const Table = styled.div`
    .primary-row {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 2fr 1fr 3fr 2fr 2fr;
        grid-template-rows: min-content;
        padding: 4.5rem 0;
        .center {
            text-align: center;
        }
        .start {
            text-align: start;
        }
        .end {
            text-align: end;
        }
        h1 {
            font-size: 1.6rem;
            font-weight: 500;
            color: #929292;
        }
    }
`

export const FreeContainer = styled.div`
    border: 1px solid #c7d3d1;
    border-radius: 1.2rem;
    padding: 3.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .img {
        margin-right: 2rem;
    }
    .free-plan {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
            h1 {
                font-size: 2rem;
                font-weight: 500;
                margin-bottom: 1.2rem;
            }
        }
        .info {
            p {
                font-size: 1.8rem;
                color: #929292;
                margin-bottom: 2rem;
            }
        }
        a {
            align-self: flex-end;
        }
    }
`
