import React from 'react'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import { Invoice } from '../../entitie/invoice'
import { SingleHistoryIncomeContainer } from './styles'

interface Props {
    invoice: Invoice
    description: string
}

export function SingleHistoryIncome({ invoice, description }: Props) {
    return (
        <SingleHistoryIncomeContainer>
            <h1 className="start">{`${format(
                new Date(invoice.date || ''),
                `dd'/'MM'/'Y`,
                {
                    locale: ptLocale,
                }
            )}`}</h1>
            <h1 className="center">{description}</h1>
            <h1 className="center">{`R$ ${
                (invoice.amount / 100).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }) || 0
            }`}</h1>
            <h1 className="center">{invoice.status}</h1>
            {/* <button type="button">
                <FiFileText />
            </button>
            <button type="button">
                <FiFileText />
            </button> */}
        </SingleHistoryIncomeContainer>
    )
}
