import React, { useState } from 'react'
import { FiCheck } from 'react-icons/fi'
import { Link, useLocation } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { MarketingContainer } from '../../container/MarketingContainer'
import { ParamsRoutePlan } from '../../entitie/params-route-plan'
import { useUser } from '../../hooks/use-user'
import { PlanInfoContainer } from './styles'

export function PlanInfoPage() {
    // location
    const params = useLocation().state as ParamsRoutePlan
    // hook
    const { user, createSubscription } = useUser()

    // state
    const [busyBtn, setBusyBtn] = useState(false)

    function labelPrice(
        type:
            | 'AGENCY'
            | 'AGENCY_PRO'
            | 'BUSINESS'
            | 'ENTERPRISE'
            | 'FREE'
            | undefined,
        value: number
    ) {
        if (type === 'FREE') {
            return 'Grátis'
        }

        return `R$ ${
            value.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) || 0
        }/mês`
    }

    return (
        <MarketingContainer
            title={`${
                !params?.userPlan
                    ? 'Seja bem-vindo ao Ada!'
                    : 'Alteração de plano'
            }`}
            subTitle={`${
                !params?.userPlan
                    ? 'Conclua seu cadastro e comece a utilizar a plataforma.'
                    : `Seu plano atual é o ${params.userPlan?.label}`
            }`}
        >
            <PlanInfoContainer>
                <div className="title">
                    <h1 className="font">{params?.plan?.label}</h1>
                    <p>{params?.plan?.description}</p>
                </div>

                <div className="grid">
                    {params?.plan?.features.map((feature) => {
                        return (
                            <div className="feature">
                                <div className="circle">
                                    <FiCheck />
                                </div>
                                <h3>{feature}</h3>
                            </div>
                        )
                    })}
                </div>
                <div className="row">
                    <div className="info">
                        <h1>
                            {labelPrice(
                                params?.plan?.type,
                                params?.plan?.monthlyPrice as number
                            )}
                        </h1>
                        <h2>
                            Sem compromisso. <br /> Cancele online quando
                            quiser.
                        </h2>
                    </div>
                    <div className="btns">
                        {/* ------------------------------ VERIFICAR SE ELE TEM CARTÃO DE CRÉDITO DISPONÍVEL ---------------------- */}
                        {params.plan?.type === 'FREE' ? (
                            <button
                                className="confirm-btn"
                                type="button"
                                onClick={async () => {
                                    setBusyBtn(true)
                                    try {
                                        if (params.plan) {
                                            await createSubscription(
                                                params.plan?.id
                                            )
                                            setBusyBtn(false)
                                            window.location.reload()
                                        }
                                    } catch (error) {
                                        setBusyBtn(false)
                                    }
                                }}
                            >
                                {busyBtn ? <ClipLoader /> : 'Escolher'}
                            </button>
                        ) : (
                            <>
                                {!user.creditCard ? (
                                    <Link
                                        to={{
                                            pathname: 'register-credit-card',
                                            state: params,
                                        }}
                                    >
                                        <button
                                            className="confirm-btn"
                                            type="button"
                                        >
                                            Continuar
                                        </button>
                                    </Link>
                                ) : (
                                    <Link
                                        to={{
                                            pathname: 'choose-credit-card',
                                            state: params,
                                        }}
                                    >
                                        <button
                                            className="confirm-btn"
                                            type="button"
                                        >
                                            Continuar
                                        </button>
                                    </Link>
                                )}
                            </>
                        )}
                        <Link
                            to={{
                                pathname: '/plans',
                                state: {
                                    plan: undefined,
                                    userPlan: params?.userPlan
                                        ? params.userPlan
                                        : undefined,
                                },
                            }}
                        >
                            <button className="back" type="button">
                                Escolher outro plano
                            </button>
                        </Link>
                    </div>
                </div>
            </PlanInfoContainer>
        </MarketingContainer>
    )
}
