/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { MarketingContainer } from '../../container/MarketingContainer'
import { RegisterCreditCardContainer } from './styles'
import { Input } from '../../components/Input'
import { creditCardNumberFormatter, formatExpiry } from '../../utils/formatter'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { useUser } from '../../hooks/use-user'
import { CreditCard } from '../../dtos/credit-card'
import { ParamsRoutePlan } from '../../entitie/params-route-plan'

interface CreditCardUi {
    cvc: string
    expiry: string
    name: string
    number: string
}

export function RegisterCreditCardPage() {
    // location
    const params = useLocation().state as ParamsRoutePlan
    // refs
    const formRef = useRef<FormHandles>(null)
    // hooks
    const { editProfile } = useUser()
    // state
    const [data, setData] = useState({
        cvc: '',
        expiry: '',
        name: '',
        number: '',
    })
    const [focused, setFocused] = useState<
        'name' | 'number' | 'expiry' | 'cvc'
    >('number')
    const [creditCardNumber, setCreditCardNumber] = useState('')
    const [busyBtn, setBusyBtn] = useState<boolean>(false)
    const [redirect, setRedirect] = useState<boolean>(false)
    const [expiry, setExpiry] = useState<string>('')

    const refCard = useRef<any>(null)

    function handleInputChange(
        inputName: 'name' | 'number' | 'expiry' | 'cvc',
        value: string | number
    ) {
        setData({
            ...data,
            [inputName]: value,
        })
    }
    function handleInputFocus(inputName: 'name' | 'number' | 'expiry' | 'cvc') {
        setFocused(inputName)
    }

    async function handlePayment(dataForm: CreditCardUi) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                name: Yup.string().required('Insira um nome'),
                expiry: Yup.string().required('Insira a data de inspiração'),
                cvc: Yup.string().required('Insira o código cvc'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            const createCreditCard: CreditCard = {
                number: data.number,
                holderName: dataForm.name,
                expirationMonth: dataForm.expiry.split('/')[0],
                expirationYear: dataForm.expiry.split('/')[1],
                brand: refCard.current.issuer as string,
                code: dataForm.cvc,
            }
            const response = await editProfile({ creditCard: createCreditCard })
            if (response) {
                setBusyBtn(false)
                setRedirect(true)
            }
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    function labelPrice(
        type:
            | 'AGENCY'
            | 'AGENCY_PRO'
            | 'BUSINESS'
            | 'ENTERPRISE'
            | 'FREE'
            | undefined,
        value: number
    ) {
        if (type === 'FREE') {
            return 'Grátis'
        }

        return `R$ ${
            value?.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) || 0
        }/mês`
    }

    function titleSubtitleLabels() {
        if (params.plan && params.userPlan) {
            return [
                'Alteração de plano',
                `Seu plano atual é o ${params.userPlan?.label}`,
            ]
        }
        if (params.plan && !params.userPlan) {
            return [
                'Seja bem-vindo ao Ada!',
                `Conclua seu cadastro e comece a utilizar a plataforma.`,
            ]
        }
        return [
            'Trocar forma de pagamento.',
            'Altere sua forma de pagamento cadastrando outro cartão',
        ]
    }

    return (
        <MarketingContainer
            title={titleSubtitleLabels()[0]}
            subTitle={titleSubtitleLabels()[1]}
        >
            <RegisterCreditCardContainer>
                <div className="title">
                    <h1 className="font">Informe seus dados de pagamento</h1>
                    <p>
                        Selecione a forma de pagamento que será usada para seu
                        novo plano.
                    </p>
                </div>
                <Form ref={formRef} onSubmit={handlePayment}>
                    <div className="grid">
                        <div className="form">
                            <div className="number">
                                <Input
                                    name="creditCardNumber"
                                    type="text"
                                    value={creditCardNumber}
                                    placeholder="Número do cartão"
                                    onChange={(e) => {
                                        if (
                                            ((e.target.value.toString()[0] +
                                                e.target.value.toString()[1] ===
                                                '37' ||
                                                e.target.value.toString()[0] +
                                                    e.target.value.toString()[1] ===
                                                    '34') &&
                                                e.target.value
                                                    .toString()
                                                    .replaceAll(' ', '')
                                                    .length <= 15) ||
                                            (e.target.value.toString()[0] +
                                                e.target.value.toString()[1] !==
                                                '37' &&
                                                e.target.value.toString()[0] +
                                                    e.target.value.toString()[1] !==
                                                    '34' &&
                                                e.target.value.toString()[0] +
                                                    e.target.value.toString()[1] !==
                                                    '36' &&
                                                e.target.value
                                                    .toString()
                                                    .replaceAll(' ', '')
                                                    .length <= 19) ||
                                            (e.target.value.toString()[0] +
                                                e.target.value.toString()[1] ===
                                                '36' &&
                                                e.target.value
                                                    .toString()
                                                    .replaceAll(' ', '')
                                                    .length <= 14)
                                        ) {
                                            setCreditCardNumber(
                                                creditCardNumberFormatter(
                                                    e.target.value || ''
                                                )
                                            )
                                            handleInputChange(
                                                'number',
                                                e.target.value.replaceAll(
                                                    '.',
                                                    ''
                                                )
                                            )
                                        }
                                    }}
                                    onFocus={() => handleInputFocus('number')}
                                />
                            </div>
                            <div className="validity">
                                <Input
                                    name="expiry"
                                    value={expiry}
                                    placeholder="Validade (MM/YY)"
                                    onChange={(e) => {
                                        if (e.target.value.length <= 5) {
                                            setExpiry(
                                                formatExpiry(e.target.value)
                                            )
                                            handleInputChange(
                                                'expiry',
                                                e.target.value
                                            )
                                        }
                                    }}
                                    onFocus={() => handleInputFocus('expiry')}
                                />
                            </div>
                            <div className="cvv">
                                <Input
                                    name="cvc"
                                    placeholder="CVC"
                                    type="number"
                                    onChange={(e) =>
                                        handleInputChange('cvc', e.target.value)
                                    }
                                    onFocus={() => handleInputFocus('cvc')}
                                />
                            </div>
                            <div className="name">
                                <Input
                                    name="name"
                                    placeholder="Nome (como consta no cartão)"
                                    onChange={(e) =>
                                        handleInputChange(
                                            'name',
                                            e.target.value
                                        )
                                    }
                                    onFocus={() => handleInputFocus('name')}
                                />
                            </div>
                        </div>
                        <Cards
                            ref={refCard}
                            cvc={data.cvc}
                            expiry={data.expiry}
                            name={data.name}
                            number={data.number}
                            focused={focused}
                            placeholders={{ name: 'Nome' }}
                            locale={{ valid: 'Expiração' }}
                        />

                        <div className="term">
                            <p>
                                {`Ao clicar no botão "Continuar" ao lado, você
                                confirma ter mais de 18 anos e aceita que a ADA
                                Travel Tech renovará automaticamente sua
                                assinatura e cobrará o valor (atualmente
                                ${
                                    params?.plan
                                        ? params.plan.label
                                        : params.userPlan?.label
                                }) da sua forma de pagamento. Você pode
                                cancelar sua assinatura quando quiser para
                                evitar novas cobranças. Para cancelar, acesse
                                “Configurações”, clique em "Cobranças" e então
                                “Cancelar plano”.`}
                            </p>
                        </div>
                        <div className="plan grid-container">
                            <div className="info">
                                <h2>{`Plano ${
                                    params?.plan
                                        ? params.plan.label
                                        : params.userPlan?.label
                                }`}</h2>
                                <h3>
                                    {labelPrice(
                                        params?.plan
                                            ? params.plan.type
                                            : params.userPlan?.type,
                                        params?.plan
                                            ? (params.plan
                                                  .monthlyPrice as number)
                                            : (params.userPlan
                                                  ?.monthlyPrice as number)
                                    )}
                                </h3>
                            </div>
                            <Link
                                to={{
                                    pathname: '/plans',
                                    state: {
                                        plan: undefined,
                                        userPlan: params.userPlan
                                            ? params.userPlan
                                            : undefined,
                                    },
                                }}
                            >
                                <button type="button">Trocar</button>
                            </Link>
                        </div>
                    </div>
                    <button className="confirm-btn" type="submit">
                        {busyBtn ? <ClipLoader /> : 'Continuar'}
                    </button>
                </Form>
            </RegisterCreditCardContainer>
            {redirect && !params.isChangePayment && (
                <Redirect to={{ pathname: 'confirm-plan', state: params }} />
            )}
            {redirect && params.isChangePayment && (
                <Redirect to={{ pathname: 'financial' }} />
            )}
        </MarketingContainer>
    )
}
