import styled from 'styled-components'

export const CheckEmailContainer = styled.div`
    text-align: center;
    margin: 0 auto;
    h1 {
        margin-bottom: 2rem;
        color: #000;
        font-weight: 600;
    }
    h2 {
        margin: 0 auto;
        margin-top: 2rem;
        width: 30rem;
        font-weight: 400;
        font-size: 1.8rem;
        color: #7e8483;
    }
`
