import styled, { keyframes } from 'styled-components'

const appear = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })

export const SinglePlanContainer = styled.div`
    width: 29rem;
    height: 100%;
    padding: 6rem 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: ${appear} 1s ease;
    h1 {
        font-size: 3.2rem;
        font-weight: 600;
    }
    p {
        margin-top: 1rem;
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
    h3 {
        font-size: 1.5rem;
    }
    h4 {
        text-align: center;
        font-size: 1.5rem;
    }
    .activated-plan {
        text-align: center;
    }
    .activated-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
    }
    .feature {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1rem;
        .circle {
            background-color: #e5e5e5;
            margin-right: 1rem;
            border-radius: 50%;
            height: 2rem;
            width: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &.white {
        .activated-btn {
            background-color: #000;
            color: white;
        }
        height: 100%;
        max-height: 70rem;
        margin-top: 4rem;
        background-color: #fff;
        box-shadow: 0px 0px 3px #aaa;
        h1 {
            color: #000;
        }
        p {
            color: #848199;
        }
        h3 {
            color: #848199;
        }
    }
    .choose-plan {
        align-self: center;
        margin-top: 2rem;
        h2 {
            font-size: 2.4rem;
            font-weight: 600;
            text-align: center;
            color: #000;
            margin-bottom: 1rem;
        }
        button {
            width: 20rem;
            height: 4.5rem;
            border-radius: 2.4rem;
            background-color: #e5e5e5;
            border: none;
            outline: none;
        }
    }
`
