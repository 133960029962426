/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react'
import { FiCheck } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { v4 } from 'uuid'
import { Plan } from '../../entitie/plan'
import { SinglePlanContainer } from './styles'

interface Props {
    type: 'black' | 'white'
    direction?: 'left' | 'right'
    userPlan?: Plan
    plan: Plan
}

function labelPrice(
    type: 'AGENCY' | 'AGENCY_PRO' | 'BUSINESS' | 'ENTERPRISE' | 'FREE',
    value: number
) {
    if (type === 'FREE') {
        return 'Grátis'
    }

    return `R$ ${
        value.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) || 0
    }/mês`
}

export function SinglePlan({ type, direction, plan, userPlan }: Props) {
    return (
        <SinglePlanContainer className={`${type} ${direction || ''}`}>
            {userPlan?.label === plan.label && (
                <div className="activated-btn">Plano atual</div>
            )}
            <h1>{plan.label}</h1>
            <p>{plan.description}</p>
            {plan.features.map((feature) => {
                return (
                    <div className="feature" key={v4()}>
                        <div className="circle">
                            <FiCheck />
                        </div>
                        <h3>{feature}</h3>
                    </div>
                )
            })}
            {userPlan?.label === plan.label ? (
                <p className="activated-plan">Plano ativo</p>
            ) : (
                <div className="choose-plan">
                    <h2>{labelPrice(plan.type, plan.monthlyPrice)}</h2>
                    <Link
                        to={{
                            pathname: 'plan-info',
                            state: {
                                plan,
                                userPlan: userPlan || undefined,
                            },
                        }}
                    >
                        <button type="button">Escolher plano</button>
                    </Link>
                </div>
            )}
        </SinglePlanContainer>
    )
}
