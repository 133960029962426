import styled from 'styled-components'

export const BookingCommentsContainer = styled.div`
    padding: 2rem;
    width: 70rem;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .title {
        border-bottom: 1px solid rgba(194, 194, 194, 0.31);
        padding: 0 0 1.6rem 0;
        margin-bottom: 2rem;
    }
    .comments {
        padding: 2rem;
        background-color: #f9f9f9f9;
        border-radius: 1.2rem;
        max-height: 30rem;
        overflow-y: scroll;
        .msg {
            width: 100%;
            height: auto;
            display: block;
            overflow: hidden;

            .bubble {
                float: left;
                max-width: 75%;
                width: auto;
                height: auto;
                display: block;
                background: #ebebeb;
                border-radius: 5px;
                position: relative;
                margin: 10px 0 3px 25px;
                box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);

                &.alt {
                    margin: 10px 25px 3px 0px;
                    background: #dcf8c6;
                    float: right;
                }
                &.follow {
                    margin: 2px 0 3px 25px;
                }

                &.altfollow {
                    margin: 2px 25px 3px 0px;
                    background: #dcf8c6;
                    float: right;
                }
                .txt {
                    padding: 8px 0 8px 0;
                    width: 100%;
                    .name {
                        font-weight: 600;
                        font-size: 14px;
                        display: inline-table;
                        padding: 0 0 0 15px;
                        margin: 0 0 4px 0;
                        color: #3498db;
                        span {
                            font-weight: normal;
                            color: #b3b3b3;
                            overflow: hidden;
                        }
                        &.alt {
                            color: #2ecc51;
                        }
                    }
                    .message {
                        font-size: 14px;
                        font-weight: 500;
                        padding: 0 15px 0 15px;
                        margin: auto;
                        color: #2b2b2b;
                        display: table;
                    }
                    .timestamp {
                        font-size: 11px;
                        margin: auto;
                        padding: 0 15px 0 0;
                        display: table;
                        float: right;
                        position: relative;
                        text-transform: uppercase;
                        color: #999;
                    }
                }
                .bubble-arrow {
                    position: absolute;
                    float: left;
                    left: -11px;
                    top: 0px;
                    &.alt {
                        bottom: 20px;
                        left: auto;
                        right: 4px;
                        float: right;
                    }
                }
                .bubble-arrow:after {
                    content: '';
                    position: absolute;
                    border-top: 15px solid #ebebeb;
                    border-left: 15px solid transparent;
                    border-radius: 4px 0 0 0px;
                    width: 0;
                    height: 0;
                    //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
                    //looks like I can't give a shadow to the arrows
                }
                .bubble-arrow.alt:after {
                    border-top: 15px solid #dcf8c6;
                    transform: scaleX(-1);
                }
            }
        }
    }
    .add-comment {
        display: flex;
        align-items: center;
        div {
            flex: 1;
        }
        .send-comment {
            padding: 1.2rem 1.5rem;
            border: 1px solid #c7d3d1;
            border-left: none;
            font-size: 1.5rem;
            border-top-right-radius: 1.2rem;
            border-bottom-right-radius: 1.2rem;
            outline: none;
        }
    }
`

export const InputContainer = styled.div`
    position: relative;
    input {
        font-size: 1.5rem;
        font-family: inherit;
        color: #4e5150;
        padding: 1.2rem 2rem;
        border-top-left-radius: 1.2rem;
        border-bottom-left-radius: 1.2rem;
        border: none;
        width: 100%;
        display: block;
        transition: all 0.3s;
        background-color: #fff;
        margin: 1rem 0;
        border: 1px solid #c7d3d1;

        &:focus {
            outline: none;
        }
        &:disabled {
            background-color: rgba(214, 221, 220, 0.39);
            color: #7e8483;
        }
    }
`
