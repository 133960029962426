/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { useUser } from '../../hooks/use-user'
import { PrimaryButton } from '../PrimaryButton'
import { CancelPlanContainer } from './styles'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    id?: string
}
export function CancelPlan({ isOpen, onRequestClose, id }: Props) {
    // hook
    const { cancelSubscription } = useUser()

    // state
    const [busy, setBusy] = useState<boolean>(false)

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <CancelPlanContainer>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <div className="title">
                    <h1 className="heading-font-configuration">
                        Cancelar assinatura
                    </h1>
                </div>
                <div className="description">
                    <p>
                        Ao cancelar sua assinatura, seu acesso a plataforma é
                        mantido até 1 mês após a data em que o seu último
                        pagamento foi realizado. Após esse período, seu acesso à
                        plataforma será cancelado e para reativa-lo será
                        necessário assinar um plano novamente.
                    </p>
                </div>
                <h2>Deseja realmente cancelar sua assinatura?</h2>
                <div className="btns">
                    <PrimaryButton
                        color="primary"
                        title="Não desejo cancelar"
                        callback={() => onRequestClose()}
                    />
                    <PrimaryButton
                        color="secondary"
                        title="Sim, cancelar assinatura"
                        busy={busy}
                        callback={() => {
                            setBusy(true)
                            ;(async () => {
                                if (id) {
                                    const response = await cancelSubscription(
                                        id
                                    )
                                    if (response) {
                                        setBusy(false)
                                        onRequestClose()
                                    }
                                    setBusy(false)
                                }
                            })()
                        }}
                    />
                </div>
            </CancelPlanContainer>
        </ModalContainer>
    )
}
