/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import React, { createContext, useContext, useState, ReactNode } from 'react'
import crypto from 'crypto'
import { EditProfileDto } from '../dtos/edit-profile'
import { SignupDto } from '../dtos/signup'
import { Airline } from '../entitie/airline'
import { CompanyInfo } from '../entitie/company-info'
import { Plan } from '../entitie/plan'
import { Subscription } from '../entitie/subscription'
import { TutorialSteps } from '../entitie/tutorial-steps'
import { User } from '../entitie/user'
import { UserInfo } from '../entitie/user-info'
import { UserResponse } from '../entitie/user-response'
import { api } from '../service/api'
import { useAuth } from './use-auth'
import { useFormatter } from './use-formatter'
import { useToast } from './use-toast'

interface IValidateEmail {
    available: boolean
}
interface AppProvideProps {
    children: ReactNode
}

interface UserContext {
    signUp(userInfo: UserInfo, companyInfo: CompanyInfo): Promise<boolean>
    validateEmail(email: string): Promise<boolean>
    getUser(): Promise<UserResponse | undefined>
    getPlans(): Promise<void>
    editProfile(data: EditProfileDto): Promise<boolean>
    getAirlines(): Promise<void>
    createSubscription(id: string): Promise<boolean>
    getSubscription(): Promise<void>
    cancelSubscription(id: string): Promise<boolean>
    paidCredit?: number
    airlines?: Airline[]
    subscription?: Subscription
    user: User
    tutorialStep?: TutorialSteps
    plans: Plan[]
}

const UserContext = createContext({} as UserContext)

export function UserProvider({ children }: AppProvideProps) {
    // hook
    const { addToast } = useToast()
    const { unformatCep, unformatPhone, unformatCnpj } = useFormatter()
    const { token } = useAuth()

    // state
    const [user, setUser] = useState<User>({} as User)
    const [tutorialStep, setTutorialStep] = useState<TutorialSteps>()
    const [paidCredit, setPaidCredit] = useState<number>()
    const [airlines, setAirlines] = useState<Airline[]>()
    const [plans, setPlans] = useState<Plan[]>([])
    const [subscription, setSubscription] = useState<Subscription>()

    async function getUser() {
        try {
            const response = await api.get<UserResponse>('users/me', {
                headers: { authorization: token },
            })
            setUser(response.data)
            // setPaidCredit(response.data.paidCredit)
            setTutorialStep(response.data.tutorialStep)
            return response.data
        } catch (error) {
            localStorage.removeItem('@benv:token')
            window.location.reload()
            return undefined
        }
    }

    async function validateEmail(email: string) {
        try {
            const response = await api.get<IValidateEmail>(
                `users/email-availability?email=${email}`
            )

            if (response.data.available) {
                return true
            }
            addToast({ title: 'Email já cadastrado.', type: 'info' })
            return false
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return false
        }
    }

    async function signUp(userInfo: UserInfo, companyInfo: CompanyInfo) {
        const zipCode = unformatCep(companyInfo.zipCode)
        const phone = unformatPhone(companyInfo.phone)
        const cnpj = unformatCnpj(companyInfo.cnpj)
        if (companyInfo.complement === '') {
            delete companyInfo.complement
        }
        try {
            const signUpData: SignupDto = {
                email: userInfo.email.toLocaleLowerCase(),
                password: userInfo.password,
                name: userInfo.name,
                cnpj,
                legalName: companyInfo.legalName,
                userType: userInfo.userType,
                phone,
                address: {
                    zipCode,
                    city: companyInfo.city,
                    complement: companyInfo.complement,
                    number: companyInfo.number,
                    state: companyInfo.state,
                    street: companyInfo.street,
                    neighborhood: companyInfo.neighborhood,
                },
                ref: companyInfo.ref,
            }

            await api.post('/users', signUpData)
            return true
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return false
        }
    }

    async function editProfile(data: EditProfileDto) {
        try {
            let hash: string | undefined

            if (data.phone) {
                data.phone = unformatPhone(data.phone)
            }

            if (data.creditCard) {
                const key = `-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzIltTidMfXSuvNRH8zzO\nud8m/Wa3VK3hT2jLm5RAA//aXCJg3Jhtfy9nhj74WkaDoDEhLejd3t7fIQfly/OM\nmfuPZr3lH0VKHryfsK7b8rfD79+cSRjUdU/1WCJgFhZR/tJGR7aY16wa7KkBXhUO\ngoMOb0yyLET+8OR4qLK1DdfS9NDH+8aWfgxaqg0kIVtKV3Tg77CVvdEUG12eCbO0\n6c9Im5LA5sUf9zu/OrYcdTyP6vQeN+0GW+6hctrrC7iv9MeQc/OvTmzOQgqhlBYW\nfOVdOfQSoj3y+/ex/yC/Ce4YLCX/Pud3hssatWjScQHj6FeWq8lqKBOMxZlXMCMg\n1QIDAQAB\n-----END PUBLIC KEY-----`

                hash = crypto
                    .publicEncrypt(
                        {
                            key,
                            padding: crypto.constants.RSA_PKCS1_PADDING,
                            passphrase: '',
                        },
                        Buffer.from(
                            JSON.stringify({
                                number: data.creditCard.number,
                                code: data.creditCard.code,
                                expirationMonth:
                                    data.creditCard.expirationMonth,
                                expirationYear: data.creditCard.expirationYear,
                            })
                        )
                    )
                    .toString('base64')
            }

            const response = await api.patch<User>(
                '/users/me',
                {
                    name: data.name,
                    email: data.email,
                    password: data.password,
                    phone: data.phone,
                    tutorialStep: data.tutorialStep,
                    complement: data.complement,
                    creditCardHash: hash,
                },
                {
                    headers: { authorization: token },
                }
            )
            setUser(response.data)
            addToast({ title: 'Perfil atualizado', type: 'success' })
            return true
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return false
        }
    }

    async function getAirlines() {
        try {
            const response = await api.get<Airline[]>('/airlines', {
                headers: {
                    authorization: token,
                },
            })
            setAirlines(response.data)
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function getPlans() {
        try {
            const response = await api.get<Plan[]>('/plans', {
                headers: {
                    authorization: token,
                },
            })
            setPlans(response.data)
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function createSubscription(id: string) {
        try {
            await api.post(
                '/subscriptions',
                { planId: id },
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            addToast({
                title: 'Plano ativado com sucesso',
                type: 'success',
            })
            if (user.tutorialStep === TutorialSteps.choosePlan) {
                await editProfile({ tutorialStep: TutorialSteps.credentials })
            }
            return true
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return false
        }
    }

    async function getSubscription() {
        try {
            const response = await api.get<Subscription>(
                '/subscriptions/current',
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            setSubscription(response.data)
        } catch (error) {
            setSubscription(undefined)
            // addToast({
            //     description:
            //         error.response && error.response.data.details
            //             ? error.response && error.response.data.details.pt
            //             : `${error.response.config.url} ${error.response.status}`,
            //     type: 'error',
            //     title: 'Ops!',
            // })
        }
    }

    async function cancelSubscription(id: string) {
        try {
            await api.patch<Subscription>(
                `/subscriptions/${id}/cancel`,
                {},
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            addToast({
                title: 'Assinatura cancelada com sucesso!',
                type: 'success',
            })
            return true
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return false
        }
    }

    return (
        <UserContext.Provider
            value={{
                editProfile,
                signUp,
                validateEmail,
                getUser,
                getAirlines,
                getPlans,
                createSubscription,
                getSubscription,
                cancelSubscription,
                tutorialStep,
                plans,
                airlines,
                subscription,
                paidCredit,
                user,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export function useUser(): UserContext {
    const context = useContext(UserContext)

    if (!context) {
        throw new Error('use auth must be within a FormatterProvider')
    }
    return context
}
