import styled from 'styled-components'

export const InitialContainerStyled = styled.div`
    display: grid;
    grid-template-columns: 3fr 6fr;
    background-color: #fff;
    min-height: 100vh;
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        .logo {
            text-align: center;
            margin: 10rem 0 5rem 0;
            img {
                width: 23rem;
            }
        }
        .form {
            width: 100%;
        }
    }
    .background {
        background-color: var(--background);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        h1 {
            font-size: 4.8rem;
            font-weight: 500;
        }
        img {
            width: 60rem;
        }
    }
    @media screen and (max-width: 87.5em) {
        .content {
            .logo {
                margin: 5rem 0 5rem 0;
            }
        }
        .background {
            img {
                width: 50rem;
            }
        }
    }
    @media screen and (max-width: 56.25em) {
        grid-template-columns: 1fr;
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .background {
            display: none;
        }
    }
`
