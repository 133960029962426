import styled, { css } from 'styled-components'
import { shade } from 'polished'

interface Props {
    color: string
}

export const PrimaryButtonContainer = styled.button<Props>`
    padding: 0.5rem 2.8rem;
    border: none;
    border-radius: 2rem;
    transition: all 0.4s;
    outline: none;
    .css-1aojsy8 {
        margin: 0;
    }
    ${(props) => {
        if (props.color === 'primary') {
            return css`
                background-color: #333;
                &:hover {
                    background-color: ${shade(0.2, '#333')};
                }
            `
        }
        if (props.color === 'secondary') {
            return css`
                background-color: #e74b70;
                &:hover {
                    background-color: ${shade(0.2, '#E74B70')};
                }
            `
        }
        if (props.color === 'bordered') {
            return css`
                background-color: inherit;
                border: 1px solid var(--dark);
                color: #333 !important;
            `
        }
        if (props.color === 'red-white') {
            return css`
                padding: 1rem;
                background-color: #fff;
                border: 1px solid #c7d3d1;
                color: #333 !important;
                display: flex;
                flex-direction: row;
                align-items: center;
                svg {
                    margin-right: 0.8rem;
                    height: 2rem;
                    width: 2rem;
                    color: var(--tertiary);
                }
            `
        }

        return css`
            background-color: #ccc;
        `
    }}
`
