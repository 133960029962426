export enum BookingUploadStatus {
    PENDING = 'PENDING', // Booking will be uploaded to the system
    PROCESSING = 'PROCESSING', // Booking is being processed
    FOUND = 'FOUND', // Booking was found in the system
    NOT_FOUND = 'NOT_FOUND', // Booking was not found in the system
    REPEATED = 'REPEATED', // Booking was found in the system, but it is a duplicate
    COMPLETE = 'COMPLETE', // Upload report was read
}

export interface Upload {
    items: {
        identifier: string
        iataCode: string
        status: BookingUploadStatus
    }[]
}
