/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-plusplus */
import React, { useState } from 'react'
import {
    getMonth,
    getDaysInMonth,
    getYear,
    getDay,
    startOfDay,
    subMonths,
    addMonths,
    isEqual,
    isWithinInterval,
    isBefore,
} from 'date-fns'
import { FiChevronLeft, FiChevronRight, FiX } from 'react-icons/fi'
import { v4 } from 'uuid'
import reactDOM from 'react-dom'
import { DatePickerContainer, OpacityContainer } from './styles'
import { useBooking } from '../../hooks/use-booking'

interface Props {
    hasRange: boolean
    closeDatePicker(): void
}

function Backdrop({ closeDatePicker }: { closeDatePicker(): void }) {
    return <OpacityContainer onClick={() => closeDatePicker()} />
}

export function DatePicker({ hasRange, closeDatePicker }: Props) {
    // hooks
    const { filterBookings, handleFilterBookings } = useBooking()

    // state
    const [currentDay, setCurrentDay] = useState<Date>(new Date())
    const [firstDateSelected, setFirstDateSelected] = useState<Date>()
    const [secondDateSelected, setSecondDateSelected] = useState<
        Date | undefined
    >()
    const [currentDateHovered, setCurrentDateHovered] = useState<
        Date | undefined
    >()

    const currentYear = getYear(currentDay)
    const currentMonth = getMonth(currentDay)
    const numberOfDaysInPreviousMonth = getDaysInMonth(
        new Date(currentYear, currentMonth - 1)
    )
    const numberOfDaysInMonth = getDaysInMonth(
        new Date(currentYear, currentMonth)
    )
    const firstDayOfMonth = getDay(
        startOfDay(new Date(currentYear, currentMonth))
    )
    const monts = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
    ]

    const days = ['dom.', 'seg.', 'ter.', 'quar.', 'qui.', 'sex.', 'sáb.']

    function getDaysToShowInCalander(
        firstWeekDay: number,
        numberOfDaysInPreviusMonth: number,
        numberOfDaysInThisMounth: number
    ) {
        const daysInCalander: string[] = []
        const previousDays: string[] = []
        const nextDays: string[] = []
        const gapNumber = firstWeekDay
        for (let j = 0; j < gapNumber; j++) {
            previousDays.push(
                (numberOfDaysInPreviusMonth - gapNumber + j + 1).toString()
            )
        }
        for (let i = 0; i < numberOfDaysInThisMounth; i++) {
            if (i < 9) {
                daysInCalander.push(`0${(i + 1).toString()}`)
            } else {
                daysInCalander.push((i + 1).toString())
            }
        }
        for (let w = 0; w < 42 - numberOfDaysInThisMounth - gapNumber; w++) {
            if (w < 9) {
                nextDays.push(`0${(w + 1).toString()}`)
            } else {
                nextDays.push((w + 1).toString())
            }
        }
        return { daysInCalander, previousDays, nextDays }
    }

    return (
        <>
            {reactDOM.createPortal(
                <DatePickerContainer>
                    <div className="date-picker-exit">
                        <button
                            className="exit"
                            type="button"
                            onClick={() => closeDatePicker()}
                        >
                            <FiX />
                        </button>
                    </div>
                    <div className="date-picker-header">
                        <button
                            className="left-btn"
                            type="button"
                            onClick={() =>
                                setCurrentDay(subMonths(currentDay, 1))
                            }
                        >
                            <FiChevronLeft />
                        </button>
                        <h1 className="f16-500-dark">{`${monts[currentMonth]} ${currentYear}`}</h1>
                        <button
                            className="right-btn"
                            type="button"
                            onClick={() =>
                                setCurrentDay(addMonths(currentDay, 1))
                            }
                        >
                            <FiChevronRight />
                        </button>
                    </div>
                    <div className="date-picker-table">
                        <div className="date-picker-table--header">
                            {days.map((day) => {
                                return (
                                    <h2 key={v4()} className="f14-500-dark">
                                        {day}
                                    </h2>
                                )
                            })}
                        </div>
                        <div className="date-picker-table--body">
                            {getDaysToShowInCalander(
                                firstDayOfMonth,
                                numberOfDaysInPreviousMonth,
                                numberOfDaysInMonth
                            ).previousDays.map((p) => {
                                return (
                                    <div className="unclickable-day" key={v4()}>
                                        <h1 className="unclickable-day--label">
                                            {p}
                                        </h1>
                                    </div>
                                )
                            })}
                            {getDaysToShowInCalander(
                                firstDayOfMonth,
                                numberOfDaysInPreviousMonth,
                                numberOfDaysInMonth
                            ).daysInCalander.map((d) => {
                                return (
                                    <React.Fragment key={v4()}>
                                        {!hasRange ? (
                                            <button
                                                key={v4()}
                                                className={`date-picker-day ${
                                                    firstDateSelected &&
                                                    isEqual(
                                                        firstDateSelected,
                                                        new Date(
                                                            currentYear,
                                                            currentMonth,
                                                            parseFloat(d)
                                                        )
                                                    ) &&
                                                    'active'
                                                }`}
                                                type="button"
                                                onClick={() => {
                                                    setFirstDateSelected(
                                                        new Date(
                                                            currentYear,
                                                            currentMonth,
                                                            parseFloat(d)
                                                        )
                                                    )
                                                }}
                                            >
                                                <h1 className="f14-500-dark">
                                                    {d}
                                                </h1>
                                            </button>
                                        ) : (
                                            <button
                                                key={v4()}
                                                className={`range-date-picker-day ${
                                                    firstDateSelected &&
                                                    isEqual(
                                                        firstDateSelected,
                                                        new Date(
                                                            currentYear,
                                                            currentMonth,
                                                            parseFloat(d)
                                                        )
                                                    ) &&
                                                    'first-active'
                                                } ${
                                                    secondDateSelected &&
                                                    isEqual(
                                                        secondDateSelected,
                                                        new Date(
                                                            currentYear,
                                                            currentMonth,
                                                            parseFloat(d)
                                                        )
                                                    ) &&
                                                    'second-active'
                                                } ${
                                                    firstDateSelected &&
                                                    currentDateHovered &&
                                                    isBefore(
                                                        firstDateSelected,
                                                        currentDateHovered
                                                    ) &&
                                                    isWithinInterval(
                                                        new Date(
                                                            currentYear,
                                                            currentMonth,
                                                            parseFloat(d)
                                                        ),
                                                        {
                                                            start: firstDateSelected,
                                                            end: currentDateHovered,
                                                        }
                                                    ) &&
                                                    'active-hover'
                                                }`}
                                                type="button"
                                                onClick={() => {
                                                    if (
                                                        !firstDateSelected &&
                                                        !secondDateSelected
                                                    ) {
                                                        setFirstDateSelected(
                                                            new Date(
                                                                currentYear,
                                                                currentMonth,
                                                                parseFloat(d)
                                                            )
                                                        )
                                                    }
                                                    if (
                                                        firstDateSelected &&
                                                        !secondDateSelected
                                                    ) {
                                                        setSecondDateSelected(
                                                            new Date(
                                                                currentYear,
                                                                currentMonth,
                                                                parseFloat(d)
                                                            )
                                                        )
                                                        handleFilterBookings({
                                                            ...filterBookings,
                                                            showExpired: false,
                                                            period: {
                                                                startDate:
                                                                    firstDateSelected,
                                                                endDate:
                                                                    new Date(
                                                                        currentYear,
                                                                        currentMonth,
                                                                        parseFloat(
                                                                            d
                                                                        )
                                                                    ),
                                                            },
                                                            typeOfPeriod:
                                                                'CUSTOM',
                                                        })
                                                    }
                                                    if (
                                                        firstDateSelected &&
                                                        secondDateSelected
                                                    ) {
                                                        setFirstDateSelected(
                                                            new Date(
                                                                currentYear,
                                                                currentMonth,
                                                                parseFloat(d)
                                                            )
                                                        )
                                                        setSecondDateSelected(
                                                            undefined
                                                        )
                                                    }
                                                }}
                                                onMouseEnter={() => {
                                                    if (
                                                        firstDateSelected &&
                                                        !secondDateSelected
                                                    ) {
                                                        setCurrentDateHovered(
                                                            new Date(
                                                                currentYear,
                                                                currentMonth,
                                                                parseFloat(d)
                                                            )
                                                        )
                                                    }
                                                }}
                                            >
                                                <h1 className="f14-500-dark">
                                                    {d}
                                                </h1>
                                            </button>
                                        )}
                                    </React.Fragment>
                                )
                            })}
                            {getDaysToShowInCalander(
                                firstDayOfMonth,
                                numberOfDaysInPreviousMonth,
                                numberOfDaysInMonth
                            ).nextDays.map((n) => {
                                return (
                                    <div className="unclickable-day" key={v4()}>
                                        <h1 className="unclickable-day--label">
                                            {n}
                                        </h1>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </DatePickerContainer>,
                document.getElementById('overlays')!
            )}
            {reactDOM.createPortal(
                <Backdrop closeDatePicker={closeDatePicker} />,
                document.getElementById('overlays')!
            )}
        </>
    )
}
