/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Switch } from 'react-router-dom'
import { AnalyticalPage } from '../pages/Analytical'
import { ChooseCreditCardPage } from '../pages/ChooseCreditCard'
import { CheckEmailPage } from '../pages/CheckEmail'
import { ConfirmEmailPage } from '../pages/ConfirmEmail'
import { ConfirmPlanPage } from '../pages/ConfirmPlan'
import { CredentialsPage } from '../pages/Credentials'
import { FinancialPage } from '../pages/Financial'
import { ForgotPasswordPage } from '../pages/ForgotPassword'
import { HomePage } from '../pages/Home'
import { LoginPage } from '../pages/Login'
import { PlanInfoPage } from '../pages/PlanInfo'
import { PlansPage } from '../pages/Plans'
import { ProfilePage } from '../pages/Profile'
import { RegisterCreditCardPage } from '../pages/RegisterCreditCard'
import { ResetPasswordPage } from '../pages/ResetPassword'
import { SignupFirstStepPage } from '../pages/SignupFirstStep'
import { SignupSecondStepPage } from '../pages/SignupSecondStep'
import { Route } from './route'

export function Routes() {
    return (
        <Switch>
            <Route path="/" isPrivate={false} exact component={LoginPage} />
            <Route
                path="/signup-first-step"
                isPrivate={false}
                exact
                component={SignupFirstStepPage}
            />
            <Route
                path="/signup-second-step"
                isPrivate={false}
                exact
                component={SignupSecondStepPage}
            />
            <Route
                path="/check-email"
                isPrivate={false}
                exact
                component={CheckEmailPage}
            />
            <Route
                path="/forgot-password"
                isPrivate={false}
                exact
                component={ForgotPasswordPage}
            />
            <Route
                path="/reset-password"
                isPrivate={false}
                exact
                component={ResetPasswordPage}
            />
            <Route
                path="/confirm-email"
                isPrivate={false}
                component={ConfirmEmailPage}
            />
            <Route path="/home" isPrivate exact component={HomePage} />
            <Route path="/profile" isPrivate exact component={ProfilePage} />
            <Route
                path="/credentials"
                isPrivate
                exact
                component={CredentialsPage}
            />
            <Route
                path="/analytics"
                isPrivate
                exact
                component={AnalyticalPage}
            />
            <Route
                path="/financial"
                isPrivate
                exact
                component={FinancialPage}
            />
            <Route path="/plans" isPrivate exact component={PlansPage} />
            <Route
                path="/choose-credit-card"
                isPrivate
                exact
                component={ChooseCreditCardPage}
            />
            <Route
                path="/confirm-plan"
                isPrivate
                exact
                component={ConfirmPlanPage}
            />
            <Route path="/plan-info" isPrivate exact component={PlanInfoPage} />
            <Route
                path="/register-credit-card"
                isPrivate
                exact
                component={RegisterCreditCardPage}
            />
        </Switch>
    )
}
