/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useCallback, ReactNode } from 'react'
import { useToast } from './use-toast'

interface IErrorsContext {
    shootError(code: string): void
}

interface AppProvideProps {
    children: ReactNode
}

const ErrorContext = createContext({} as IErrorsContext)

export function ErrorProvider({ children }: AppProvideProps) {
    // hooks
    const { addToast } = useToast()

    let error = ''
    let description = ''

    const shootError = useCallback((code: string) => {
        switch (code) {
            case 'INVALID_EMAIL_PASSWORD_COMBINATION':
                error = 'E-mail ou senha incorretos'
                description =
                    'Verifique as informações preenchidas ou utilize o esqueci minha senha'
                break
            case 'WRONG_OLD_PASSWORD':
                error = 'Senha antiga está incorreta'
                break
            case 'WRONG_PASSWORD':
                error = 'Senha incorreta'
                break
            case 'NOT_AUTHORIZED':
                error = 'Você não possui autorização'
                break
            case 'EXPIRED_TOKEN':
                error = 'Acesso expirado'
                description = 'Recarregue a página e faça o login novamente'
                break
            case 'UNCONFIRMED_EMAIL':
                error = 'Sua conta ainda não foi confirmada'
                description =
                    'Valide sua conta utilizando o link enviado por e-mail'
                break
            case 'EMAIL_IN_USE':
                error = 'E-mail já está em uso'
                break
            case 'EMAIL_ALREADY_CONFIRMED':
                error = 'Sua conta já foi confirmada'
                break
            case 'PHONE_IN_USE':
                error = 'Este telefone já está em uso'
                break
            case 'CNPJ_IN_USE':
                error = 'CNPJ já está em uso'
                break
            case 'USER_NOT_FOUND':
                error = 'Usuário não encontrado'
                break
            case 'CNPJ_NOT_FOUND':
                error = 'CNPJ não encontrado'
                break
            case 'CREDENTIAL_NOT_FOUND':
                error = 'Credencial não encontrada'
                break
            case 'CREDENTIAL_ALREADY_SHARED':
                error = 'Credencial já está compartilhada com este usuário'
                break
            case 'SHARING_WITH_YOURSELF':
                error =
                    'Você não pode compartilhar suas credenciais com a sua próŕia conta'
                break
            case 'INVALID_CREDENTIAL':
                error = 'Não foi possível efetuar login com esta credêncial'
                description =
                    'Verefique se os dados foram preenchidos corretamente'
                break
            case 'NO_VALID_CREDENTIALS':
                error = 'Você não possui credêncial ativa para esta companhia'
                break
            case 'IATA_CODE_IN_USE':
                error = 'Este código de IATA já está em uso'
                break
            case 'CNPJ_DATA_NOT_FOUND':
                error = 'CNPJ não encontrado'
                break
            case 'BOOKING_ALREADY_EXISTS':
                error = 'Este bilhete já está na plataforma'
                break
            case 'FAILED_TO_GET_BOOKING':
                error = 'Não conseguimos encontrar este bilhete'
                break
            case 'AIRLINE_NOT_FOUND':
                error = 'Cia Aérea não encontrada'
                break
            case 'NOT_AUTHENTICATED':
                error = 'Você não está autenticado'
                break
            case 'EMAIL_NOT_CONFIRMED':
                error = 'Sua conta ainda não foi confirmada'
                description =
                    'Valide sua conta utilizando o link enviado por e-mail'
                break
            case 'INVALID_TOKEN':
                error =
                    'Sessão expirada. Realize o login novamente ou entre em contato'
                break
            case 'USER_IS_ALREADY_UPLOADING':
                error =
                    'Você já possui um upload em progresso, espere ele ser finalizado.'
                break
            case 'UPLOAD_NOT_COMPLETE':
                error = 'Seu upload ainda não foi finalizado.'
                break
            case 'INVALID_FILE_FORMAT':
                error =
                    'São aceitos apenas documentos nos formatos .xls e .xlsx.'
                break
            case 'VOUCHER_NOT_FOUND':
                error = 'Esse cupom não existe'
                break
            case 'VOUCHER_EXPIRED':
                error = 'Cupom expirado.'
                break
            case 'VOUCHER_ALREADY_USED':
                error = 'Cupom já utilizado.'
                break
            case 'NO_CREDITS':
                error = 'Créditos insuficientes para o upload.'
                description =
                    'Você já atingiu seu limite mensal ou está tentando carregar mais bilhetes do que o contratado'
                break
            case 'FILE_TOO_LARGE':
                error = 'Aquivo pode conter no máximo 1MB.'
                break
            case 'INVALID_FILE_STRUCTURE':
                error = 'A estrutura do excel está fora do padrão aceito.'
                break
            default:
                error = 'Um erro inesperado aconteceu'
                break
        }
        if (error !== '') {
            addToast({
                title: error,
                description: description || '',
                type: 'error',
            })
        }
    }, [])

    return (
        <ErrorContext.Provider value={{ shootError }}>
            {children}
        </ErrorContext.Provider>
    )
}

export function useError(): IErrorsContext {
    const context = useContext(ErrorContext)
    if (!context) {
        throw new Error('Use error must be inside an ErrorProvider')
    }
    return context
}
