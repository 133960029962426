import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/Button'
import { InitialInput } from '../../components/InitialInput'
import { InitialContainer } from '../../container/InitialContainer'
import { LoginDto } from '../../dtos/login'
import { useAuth } from '../../hooks/use-auth'
import { LoginContent } from './styles'
import { getValidationErrors } from '../../utils/getValidationErrors'

export function LoginPage() {
    // refs
    const formRef = useRef<FormHandles>(null)

    // hooks
    const { login } = useAuth()

    // state
    const [busyBtn, setBusyBtn] = useState<boolean>(false)

    async function handleLogin(data: LoginDto) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                email: Yup.string().required('Insira um email'),
                password: Yup.string().required('Insira uma senha'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            await login(data)
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <InitialContainer>
            <LoginContent>
                <h1 className="heading-font">Seja bem-vindo ao ADA!</h1>
                <Form ref={formRef} onSubmit={handleLogin}>
                    <InitialInput
                        type="email"
                        name="email"
                        labelTitle="E-mail"
                    />
                    <InitialInput
                        name="password"
                        labelTitle="Senha"
                        type="password"
                    />
                    <div className="forgot-password">
                        <Link to="forgot-password">
                            <button className="forgot-btn" type="button">
                                Esqueci minha senha
                            </button>
                        </Link>
                    </div>
                    <div className="login-btn">
                        <Button
                            color="primary"
                            title="Continuar"
                            isSubmit
                            busy={busyBtn}
                        />
                    </div>
                </Form>
                <div className="signup">
                    <Link to="/signup-first-step">
                        <Button title="Cadastre-se" color="bordered" />
                    </Link>
                </div>
            </LoginContent>
        </InitialContainer>
    )
}
