/* eslint-disable consistent-return */
/* eslint-disable no-empty */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState, ReactNode } from 'react'
import { CreateCredentialDto } from '../dtos/create-credential'
import { EditCredentialDto } from '../dtos/edit-credential'
import { RequestCredentialDto } from '../dtos/request-credential'
import { ShareCredentialDto } from '../dtos/share-credential'
import { ExternalCredential } from '../entitie/external-credential'
import { PersonalCredential } from '../entitie/personal-credential'
import { SharedUser } from '../entitie/shared-user'
import { TutorialSteps } from '../entitie/tutorial-steps'
import { UserResponse } from '../entitie/user-response'
import { api } from '../service/api'
import { useAuth } from './use-auth'
import { useError } from './use-error'
import { useToast } from './use-toast'
import { useUser } from './use-user'

interface CredentialContext {
    getCredentials(): Promise<void>
    createCredential(data: CreateCredentialDto): Promise<void>
    editCredential(
        data: Omit<EditCredentialDto, 'id'>,
        id: string
    ): Promise<void>
    sendRequestCredential(data: RequestCredentialDto): Promise<boolean>
    shareCredential(
        targetUserEmail: ShareCredentialDto,
        id: string
    ): Promise<void>
    deleteCredential(id: string): Promise<boolean>
    unShareCredential(
        targetUserEmail: ShareCredentialDto,
        id: string
    ): Promise<void>
    toggleCreateCredential(bool: boolean): void
    toggleRequestCredential(bool: boolean): void
    toggleDeleteCredetial(id?: string): void
    toggleShareCredential(id?: string): void
    toggleEditCredential(id?: string): void
    personalCredentials?: PersonalCredential[]
    externalCredentials?: ExternalCredential[]
    showCredentialRequest: boolean
    showCreateCredential: boolean
    showDeleteCredential?: string
    showShareCredential?: string
    showEditCredential?: string
}

interface AppProvideProps {
    children: ReactNode
}

const CredentialContext = createContext({} as CredentialContext)

export function CredentialProvider({ children }: AppProvideProps) {
    // hook
    const { shootError } = useError()
    const { addToast } = useToast()
    const { token } = useAuth()
    const { editProfile, user, airlines } = useUser()

    // State
    const [showCreateCredential, setShowCreateCredential] = useState(false)
    const [showCredentialRequest, setShowCredentialRequest] = useState(false)
    const [showDeleteCredential, setShowDeleteCredential] = useState<string>()
    const [showShareCredential, setShowShareCredential] = useState<string>()
    const [showEditCredential, setShowEditCredential] = useState<string>()

    const [externalCredentials, setExternalCredentials] = useState<
        ExternalCredential[]
    >([] as ExternalCredential[])
    const [personalCredentials, setPersonalCredentials] = useState<
        PersonalCredential[]
    >([])

    function toggleCreateCredential(bool: boolean) {
        setShowCreateCredential(bool)
    }
    function toggleRequestCredential(bool: boolean) {
        setShowCredentialRequest(bool)
    }
    function toggleDeleteCredetial(id?: string) {
        if (id) {
            setShowDeleteCredential(id)
        } else {
            setShowDeleteCredential(undefined)
        }
    }
    function toggleShareCredential(id?: string) {
        if (id) {
            setShowShareCredential(id)
        } else {
            setShowShareCredential(undefined)
        }
    }
    function toggleEditCredential(id?: string) {
        if (id) {
            setShowEditCredential(id)
        } else {
            setShowEditCredential(undefined)
        }
    }

    async function getCredentials() {
        try {
            const response = await api.get<UserResponse>('credentials', {
                headers: { authorization: token },
            })
            setPersonalCredentials(response.data.personalCredentials)
            setExternalCredentials(response.data.externalCredentials)
        } catch (error) {}
    }

    async function createCredential(data: CreateCredentialDto) {
        try {
            if (data.organization === '') {
                delete data.organization
            }
            if (data.domain === '') {
                delete data.domain
            }
            const response = await api.post<PersonalCredential>(
                '/credentials',
                data,
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            const airline = airlines?.find(
                (a) => a.id === response.data.airlineId
            )
            if (airline) {
                setPersonalCredentials((state) => {
                    return [...state, { ...response.data, airline }]
                })
            }
            if (user?.tutorialStep === TutorialSteps.credentials) {
                await editProfile({ tutorialStep: TutorialSteps.importBooking })
            }
            addToast({
                title: 'Credencial cadastrada com sucesso',
                type: 'success',
            })
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function editCredential(
        data: Omit<EditCredentialDto, 'id'>,
        id: string
    ) {
        try {
            if (data.domain === '') {
                delete data.domain
            }
            const response = await api.patch<PersonalCredential>(
                `/credentials/${id}`,
                data,
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            setPersonalCredentials((state) => {
                if (state) {
                    const index = personalCredentials.findIndex(
                        (credential) => credential.id === id
                    )
                    personalCredentials[index] = {
                        ...response.data,
                        sharedWith: personalCredentials[index].sharedWith,
                        users: personalCredentials[index].users,
                        organization: personalCredentials[index].organization,
                        domain: personalCredentials[index].domain,
                        username: personalCredentials[index].username,
                        airline: personalCredentials[index].airline,
                    }
                    return state
                }
                return state
            })
            addToast({
                title: 'Credencial editada com sucesso',
                type: 'success',
            })
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function sendRequestCredential(data: RequestCredentialDto) {
        try {
            await api.post(`/credentials/request-credential`, data, {
                headers: {
                    authorization: token,
                },
            })
            addToast({
                title: 'Solicitação enviada com sucesso',
                type: 'success',
            })
            return true
        } catch (error) {
            addToast({
                title: 'Este e-mail não está cadastrado no sistema',
                type: 'error',
            })
            return false
        }
    }

    async function shareCredential(
        targetUserEmail: ShareCredentialDto,
        id: string
    ) {
        try {
            const response = await api.patch<SharedUser>(
                `/credentials/${id}/share`,
                { email: targetUserEmail.targetUserEmail },
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            setPersonalCredentials((state) => {
                const index = state.findIndex(
                    (credencial) => credencial.id === id
                )
                if (!state[index].users) {
                    state[index].users = []
                }
                state[index].users.push(response.data)
                return state
            })
            addToast({
                title: 'Credencial vinculada com sucesso',
                type: 'success',
            })
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function unShareCredential(
        targetUserEmail: ShareCredentialDto,
        id: string
    ) {
        try {
            await api.patch(
                `/credentials/${id}/unshare`,
                { email: targetUserEmail.targetUserEmail },
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            setPersonalCredentials((state) => {
                state.map((credential) => {
                    if (credential.id === id) {
                        if (credential.users) {
                            const userIndex = credential.users.findIndex(
                                (userD) =>
                                    userD.email ===
                                    `${targetUserEmail.targetUserEmail}`
                            )
                            credential.users?.splice(userIndex, 1)
                        }
                    }
                    return state
                })
                return state
            })
            addToast({
                title: 'Credencial desvinculada com sucesso',
                type: 'success',
            })
        } catch (error) {
            shootError(error.response.errorCode)
        }
    }

    async function deleteCredential(id: string) {
        try {
            await api.delete(`/credentials/${id}`, {
                headers: {
                    authorization: token,
                },
            })
            setPersonalCredentials((state) => {
                const index = state.findIndex(
                    (credential) => credential.id === id
                )
                state.splice(index, 1)
                return [...state]
            })
            addToast({
                title: 'Credencial deletada com sucesso',
                type: 'success',
            })
            return true
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return false
        }
    }

    return (
        <CredentialContext.Provider
            value={{
                getCredentials,
                createCredential,
                editCredential,
                sendRequestCredential,
                shareCredential,
                unShareCredential,
                deleteCredential,
                toggleCreateCredential,
                toggleRequestCredential,
                toggleDeleteCredetial,
                toggleShareCredential,
                toggleEditCredential,
                externalCredentials,
                personalCredentials,
                showDeleteCredential,
                showCredentialRequest,
                showCreateCredential,
                showShareCredential,
                showEditCredential,
            }}
        >
            {children}
        </CredentialContext.Provider>
    )
}

export function useCredential(): CredentialContext {
    const context = useContext(CredentialContext)
    if (!context) {
        throw new Error('useCredential must be used within a ToastProvider')
    }
    return context
}
