import styled from 'styled-components'

interface Props {
    names: string
}

export const SinglePendingBookingContainer = styled.div<Props>`
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr;

    grid-template-rows: min-content;
    .passanger-name {
        .row {
            display: flex;
            align-items: center;
        }
        .more-passangers {
            position: relative;
            width: 2rem;
            height: 2rem;
            &:hover {
                cursor: pointer;
            }
            &:hover::before {
                content: '${(props) => props.names}';
                position: absolute;
                bottom: 2rem;
                left: 50%;
                padding: 1rem;
                width: 20rem;
                border: 1px solid #ddd;
                border-radius: 1rem;
                box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
            }
        }
    }
    .center {
        text-align: center;
    }
    .start {
        text-align: start;
    }
    .end {
        text-align: end;
    }
    .info {
        border: none;
        background-color: inherit;
        outline: none;
        color: var(--secondary);
    }
    .relative {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: #f1c523;
            height: 2rem;
            width: 2rem;
            margin-left: 1rem;
        }
    }
    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            padding: 0.5rem;
            border-radius: 6px;
        }
    }
    h1 {
        font-size: 1.6rem;
        font-weight: 500;
        color: #333;
        padding: 1.6rem 0;
    }
    &:not(:last-child) {
        border-bottom: 1px solid rgba(194, 194, 194, 0.31);
    }
`
