/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { FiLogOut } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { AiOutlineMail } from 'react-icons/ai'
import { HeaderContainer } from './styles'
import logo from '../../assets/adalogo.png'
import { useUser } from '../../hooks/use-user'
import { useAuth } from '../../hooks/use-auth'
import HeadPhone from '../../assets/head-phone.png'

interface Props {
    active: 'DASHBOARD' | 'CONFIGURATIONS'
}

export function Header({ active }: Props) {
    // hooks
    const { user } = useUser()
    const { logout } = useAuth()

    // state
    const [showHelpContainer, setShowHelpContainer] = useState(false)

    return (
        <HeaderContainer>
            <div className="logo">
                <img src={logo} alt="ada logo" />
            </div>
            <div className="nav">
                <Link to="/home">
                    <button type="button" className="dash-btn">
                        <h1>Dashboard</h1>
                        <div
                            className={`${
                                active === 'DASHBOARD' && 'bordered'
                            }`}
                        />
                    </button>
                </Link>
                <Link to="/profile">
                    <button type="button" className="config-btn">
                        <h1>Configurações</h1>
                        <div
                            className={`${
                                active === 'CONFIGURATIONS' && 'bordered'
                            }`}
                        />
                    </button>
                </Link>
            </div>
            {showHelpContainer && (
                <div
                    className="opacity"
                    onClick={() => setShowHelpContainer(false)}
                />
            )}
            <div className="profile">
                {showHelpContainer && (
                    <>
                        <div className="help-container">
                            {/* <div className="info">
                                <MdLocalPhone />
                                <p>+55 47 9 9979-0961</p>
                            </div>
                            <div className="info">
                                <FaWhatsapp />
                                <p>+55 47 9 9979-0961</p>
                            </div> */}
                            <div className="info">
                                <AiOutlineMail />
                                <p>help@adatravel.com.br</p>
                            </div>
                        </div>
                    </>
                )}
                <button
                    type="button"
                    className="need-help"
                    onClick={() => setShowHelpContainer(!showHelpContainer)}
                >
                    <h2>Precisa de ajuda?</h2>
                    <img src={HeadPhone} alt="head-phone" />
                </button>

                <div className="divider" />
                <div className="user">
                    <h2>{user.name}</h2>
                    <p>{user.email}</p>
                </div>
                <button
                    type="button"
                    className="exit-btn"
                    onClick={() => logout()}
                >
                    <FiLogOut />
                </button>
            </div>
        </HeaderContainer>
    )
}
