import styled from 'styled-components'

export const HeaderContainer = styled.div`
    background-color: var(--light);
    grid-row: start-container / end-header;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
    div {
        text-align: center;
    }
    .logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 7rem;
        img {
            width: 18rem;
        }
    }
    .nav {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            &:not(:last-child) {
                margin-right: 4rem;
            }
            button {
                position: relative;
                height: 6rem;
                background-color: inherit;
                outline: none;
                border: none;

                h1 {
                    font-size: 2rem;
                }
                div {
                    position: absolute;
                    bottom: 0;
                    height: 0.5rem;
                    width: 100%;
                    background-color: transparent;
                    visibility: none;
                    border-top-right-radius: 0.5rem;
                    border-top-left-radius: 0.5rem;
                    transition: all 0.4s ease;
                }
                .bordered {
                    background-color: #333;
                }
                &:hover {
                    div {
                        background-color: #333;
                        visibility: initial;
                    }
                }
            }
        }
    }
    .opacity {
        position: absolute;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: transparent;
    }
    .profile {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .help-container {
            position: absolute;
            top: 6rem;
            left: 6rem;
            background-color: #fff;
            border-radius: 8px;
            padding: 2.5rem;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            .info {
                display: flex;
                align-items: center;
                flex-direction: row;
                p {
                    font-weight: 400;
                    font-size: 1.6rem;
                    color: #333;
                }
                svg {
                    margin-right: 1rem;
                    height: 1.8rem;
                    width: 1.8rem;
                }
                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }
        }

        .need-help {
            display: flex;
            align-items: center;
            background-color: inherit;
            border: none;
            padding: 1rem 0;
            outline: none;
            h2 {
                margin-right: 1rem;
                font-size: 1.6rem;
                font-weight: 400;
            }
        }

        .divider {
            margin: 0 1rem;
            height: 4rem;
            width: 0.2rem;
            background-color: #333;
        }
        .user {
            text-align: start;
            h2 {
                font-size: 1.6rem;
                font-weight: 500;
            }
            p {
                font-size: 1.4rem;
            }
        }
        .exit-btn {
            z-index: 90000;
            background-color: inherit;
            border: none;
            padding: 0 3rem 0 1rem;
            svg {
                height: 2rem;
                width: 2rem;
            }
        }
    }
`
