import styled from 'styled-components'

export const BookingDetailContainer = styled.div`
    padding: 2rem;
    width: 70rem;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .header {
        margin-bottom: 4.5rem;
        h1 {
            font-size: 2.4rem;
            font-weight: 600;
        }
        p {
            margin-top: 0.6rem;
            font-size: 1.6rem;
        }
        span {
            font-weight: 600;
        }
    }
    .center {
        text-align: end;
    }
`

export const Table = styled.div`
    width: 80%;
    margin: 0 auto;
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 3rem;
    .primary-row {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-template-rows: min-content;
        .center {
            text-align: center;
        }
        .start {
            text-align: start;
        }
        .end {
            text-align: end;
        }
        .switch-btn {
            border: none;
            background-color: inherit;
            font-size: 1.6rem;
            font-weight: 500;
            color: #929292;
            outline: none;
            svg {
                height: 2rem;
                width: 2rem;
                margin-left: 1rem;
            }
        }
        h1 {
            font-size: 1.6rem;
            font-weight: 500;
            color: #929292;
        }
    }
`
export const SingleError = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    .center {
        text-align: center;
    }
    .start {
        text-align: start;
    }
    .end {
        text-align: end;
    }

    h1 {
        font-size: 1.6rem;
        font-weight: 500;
        color: #333;
        padding: 1.6rem 0;
    }
    &:not(:last-child) {
        border-bottom: 1px solid rgba(194, 194, 194, 0.31);
    }
`
