import styled from 'styled-components'

export const CredentialsContent = styled.div`
    padding: 4.2rem;
    .loader {
        text-align: center;
    }
    .my-credentials {
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 3rem;
        }
        margin-bottom: 10rem;
    }
    .shared-credentials {
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 3rem;
        }
    }
`

export const Table = styled.div`
    .primary-row {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, minmax(100px, 1fr));
        grid-template-rows: min-content;
        padding: 1rem;
        border-top: 2px solid #c2c2c2;
        padding: 1.6rem 0;
        .center {
            text-align: center;
        }
        .start {
            text-align: start;
        }
        .end {
            text-align: end;
        }
        h1 {
            font-size: 1.6rem;
            font-weight: 500;
        }
    }
`
