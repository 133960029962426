/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import React, { useRef, useState } from 'react'
import ModalContainer from 'react-modal'
import { v4 } from 'uuid'
import * as Yup from 'yup'
import { ShareCredentialDto } from '../../dtos/share-credential'
import { PersonalCredential } from '../../entitie/personal-credential'
import { useCredential } from '../../hooks/use-credential'
import { useToast } from '../../hooks/use-toast'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { Input } from '../Input'
import { PrimaryButton } from '../PrimaryButton'
import { SingleSharedUser } from '../SingleSharedUser'
import { ShareCredentialsContainer, Table } from './styles'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    personalCredential?: PersonalCredential
}

export function ShareCredentials({
    isOpen,
    onRequestClose,
    personalCredential,
}: Props) {
    // ref
    const formRef = useRef<FormHandles>(null)

    // hooks
    const { shareCredential } = useCredential()
    const { addToast } = useToast()

    // state
    const [busyBtn, setBusyBtn] = useState(false)

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <ShareCredentialsContainer>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <div className="title">
                    <h1 className="heading-font-configuration">
                        Compartilhar credencial
                    </h1>
                </div>
                <div className="description">
                    <p>
                        Para compartilhar uma credencial insira o e-mail do
                        usúario abaixo.
                    </p>
                </div>
                <Form
                    ref={formRef}
                    onSubmit={async (data: ShareCredentialDto) => {
                        setBusyBtn(true)
                        try {
                            formRef.current?.setErrors({})
                            const schema = Yup.object().shape({
                                targetUserEmail: Yup.string()
                                    .email('Formato de e-mail inválido')
                                    .required('E-mail obrigatório'),
                            })
                            await schema.validate(data, {
                                abortEarly: false,
                            })
                            if (personalCredential) {
                                await shareCredential(
                                    data,
                                    personalCredential.id
                                )
                                setBusyBtn(false)
                            }
                        } catch (error) {
                            setBusyBtn(false)
                            if (error instanceof Yup.ValidationError) {
                                const errors = getValidationErrors(error)
                                addToast({
                                    title: 'Atenção',
                                    description: 'Formato de e-mail inválido',
                                })
                                formRef.current?.setErrors(errors)
                            }
                        }
                    }}
                >
                    <Input labelTitle="E-mail" name="targetUserEmail" />
                    <div className="btn">
                        <PrimaryButton
                            color="primary"
                            title="Compartilhar"
                            busy={busyBtn}
                            isSubmit
                        />
                    </div>
                </Form>
                <div className="title">
                    <h1 className="heading-font-configuration">
                        Usuários com acesso
                    </h1>
                </div>
                <Table>
                    <div className="primary-row">
                        <h1 className="start">E-mail</h1>
                        <h1 className="center">Nome</h1>
                        <h1 className="center">Remover acesso</h1>
                    </div>
                    {personalCredential?.users?.map((sharedUser) => {
                        return (
                            <SingleSharedUser
                                key={v4()}
                                sharedUser={sharedUser}
                                id={personalCredential.id}
                            />
                        )
                    })}
                </Table>
            </ShareCredentialsContainer>
        </ModalContainer>
    )
}
