import React from 'react'
import Modal from 'react-modal'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppProvider } from './hooks'
import { Routes } from './routes'
import GlobalStyle from './styles/global'

function App() {
    Modal.setAppElement('#root')

    return (
        <>
            <Router>
                <AppProvider>
                    <Routes />
                </AppProvider>
            </Router>
            <GlobalStyle />
        </>
    )
}

export default App
