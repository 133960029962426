/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import ModalContainer from 'react-modal'
import { Booking } from '../../entitie/booking'
import { useBooking } from '../../hooks/use-booking'
import { BookingCommentsContainer, InputContainer } from './styles'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    singleBooking?: Booking
}

export function BookingComments({
    isOpen,
    onRequestClose,
    singleBooking,
}: Props) {
    // hooks
    const { addBookingComment } = useBooking()

    // state
    // const [busyBtn, setBusyBtn] = useState(false)
    const [comment, setComment] = useState<string>()

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <BookingCommentsContainer>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <div className="title">
                    <h1 className="heading-font-configuration">
                        Seus comentários
                    </h1>
                </div>
                <div className="comments">
                    {singleBooking?.comments.map((commentData) => {
                        return (
                            <div className="msg">
                                <div className="bubble">
                                    <div className="txt">
                                        <span className="timestamp">
                                            {format(
                                                new Date(commentData.date),
                                                `dd'/'MM'/'Y`,
                                                {
                                                    locale: ptLocale,
                                                }
                                            )}
                                        </span>
                                        <span className="message">
                                            {commentData.comment}
                                        </span>
                                    </div>
                                    <div className="bubble-arrow" />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="add-comment">
                    <InputContainer>
                        <input
                            name="comment"
                            placeholder="Insira um comentário"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </InputContainer>
                    <button
                        className="send-comment"
                        type="button"
                        onClick={async () => {
                            if (comment && singleBooking?.id) {
                                await addBookingComment(
                                    comment,
                                    singleBooking?.id
                                )
                            }
                        }}
                    >
                        Enviar
                    </button>
                </div>
            </BookingCommentsContainer>
        </ModalContainer>
    )
}
