/* eslint-disable react/require-default-props */
import React from 'react'
import { ClipLoader } from 'react-spinners'
import { ButtonContainer } from './styles'

interface Props {
    title: string
    isSubmit?: boolean
    color: string
    callback?(): void | Promise<void>
    busy?: boolean
}

export function Button({
    title,
    isSubmit,
    color,
    callback,
    busy = false,
}: Props) {
    return (
        <ButtonContainer
            type={isSubmit ? 'submit' : 'button'}
            className="btn-font"
            color={color}
            onClick={() => {
                if (callback) callback()
            }}
        >
            {busy ? <ClipLoader size={15} color="#fff" /> : title}
        </ButtonContainer>
    )
}
