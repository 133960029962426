import React, { useState } from 'react'
import { ProgressiveBarContainer } from './styles'

interface Props {
    done: number
}

export function ProgressiveBar({ done }: Props) {
    // state
    const [style, setStyle] = useState<{ opacity: number; width: string }>(
        {} as { opacity: number; width: string }
    )

    setTimeout(() => {
        const newStyle = {
            opacity: 1,
            width: `${done * 0.8333}%`,
        }
        setStyle(newStyle)
    }, 500)

    return (
        <ProgressiveBarContainer>
            <div className="progress-done" style={style}>
                {(done - 20).toFixed(1)}%
            </div>
        </ProgressiveBarContainer>
    )
}
