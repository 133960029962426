/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { v4 } from 'uuid'
import { ConfigurationContent } from '../../components/ConfigirationContent'
import { PrimaryButton } from '../../components/PrimaryButton'
import { SingleHistoryIncome } from '../../components/SingleHistoryIncome'
import { Container } from '../../container/Container'
import { FinancialContent, Table } from './styles'
import { useModal } from '../../hooks/use-modal'
import { CancelPlan } from '../../components/CancelPlan'
import { useUser } from '../../hooks/use-user'
import masterCard from '../../assets/mastercard.png'
import elo from '../../assets/elo.png'
import visa from '../../assets/visa.png'
import amex from '../../assets/amex.png'

export function FinancialPage() {
    // hooks
    const { closeModal, openModal, showModal } = useModal()
    const { user, getSubscription, subscription } = useUser()
    const [busy, setBusy] = useState(false)

    function returnBrand() {
        if (user.creditCard?.brand === 'elo') {
            return elo
        }
        if (user.creditCard?.brand === 'visa') {
            return visa
        }
        if (user.creditCard?.brand === 'amex') {
            return amex
        }
        return masterCard
    }

    useEffect(() => {
        ;(async () => {
            setBusy(true)
            await getSubscription()
            setBusy(false)
        })()
    }, [showModal])

    return (
        <Container active="CONFIGURATIONS" switchType={undefined}>
            <ConfigurationContent active="financial">
                <FinancialContent>
                    {busy ? (
                        <div className="loader">
                            <ClipLoader />
                        </div>
                    ) : (
                        <>
                            <div className="financials">
                                <div className="title">
                                    <h1 className="heading-font-configuration">
                                        Cobranças
                                    </h1>
                                </div>
                                <div className="plan">
                                    <div className="info">
                                        <h1>{`Plano ${
                                            subscription?.subscription
                                                ? subscription.subscription.plan
                                                      .label
                                                : '1'
                                        }`}</h1>
                                        {subscription?.subscription.plan
                                            .type !== 'FREE' && (
                                            <>
                                                <p>Plano mensal</p>
                                                {/* <p>
                                                    A próxima cobrança ocorrerá
                                                    no dia 23 de maio, 2021.
                                                </p> */}
                                            </>
                                        )}
                                    </div>
                                    <div className="actions">
                                        <Link
                                            to={{
                                                pathname: '/plans',
                                                state: {
                                                    plan: undefined,
                                                    userPlan:
                                                        subscription?.subscription
                                                            ? subscription
                                                                  ?.subscription
                                                                  .plan
                                                            : undefined,
                                                },
                                            }}
                                        >
                                            <PrimaryButton
                                                title="Alterar plano"
                                                color="primary"
                                            />
                                        </Link>
                                        {subscription?.subscription.plan
                                            .type !== 'FREE' && (
                                            <PrimaryButton
                                                title="Cancelar plano"
                                                color="bordered"
                                                callback={() =>
                                                    openModal('CANCEL_PLAN')
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="payment">
                                    <h1>Forma de pagamento</h1>
                                    <div className="info-payment">
                                        {user.creditCard ? (
                                            <div className="credit-card">
                                                <div className="logo">
                                                    <img
                                                        src={returnBrand()}
                                                        alt="cartão logo"
                                                    />
                                                </div>
                                                <div className="credit-card-info">
                                                    <h2>{`${
                                                        user.creditCard &&
                                                        user.creditCard.brand
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            user.creditCard.brand.slice(
                                                                1
                                                            )
                                                    } final ${
                                                        user.creditCard
                                                            ?.lastFourDigits
                                                    }`}</h2>
                                                    <h3>
                                                        {
                                                            user.creditCard
                                                                ?.holderName
                                                        }
                                                    </h3>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="creditCa">
                                                <div className="credit-card-info">
                                                    <h2>
                                                        Você não possui uma
                                                        forma de pagamento
                                                    </h2>
                                                </div>
                                            </div>
                                        )}
                                        <div className="actions">
                                            <Link
                                                to={{
                                                    pathname:
                                                        'register-credit-card',
                                                    state: {
                                                        plan: undefined,
                                                        userPlan:
                                                            subscription?.subscription
                                                                ? subscription
                                                                      .subscription
                                                                      .plan
                                                                : undefined,
                                                        isChangePayment: true,
                                                    },
                                                }}
                                            >
                                                <PrimaryButton
                                                    title={`${
                                                        !user.creditCard
                                                            ? 'Cadastrar forma de pagamento '
                                                            : 'Trocar forma de pagamento'
                                                    }`}
                                                    color="primary"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {subscription && subscription.invoices.length > 0 && (
                                <div className="history-income">
                                    <div className="title">
                                        <h1 className="heading-font-configuration">
                                            Histórico de faturamentos
                                        </h1>
                                    </div>
                                    <Table>
                                        <div className="primary-row">
                                            <h1 className="start">Data</h1>
                                            <h1 className="center">
                                                Descrição
                                            </h1>
                                            <h1 className="center">Valor</h1>
                                            <h1 className="center">Status</h1>
                                            {/* <h1 className="center">Fatura</h1> */}
                                            {/* <h1 className="center">NFS-e</h1> */}
                                        </div>
                                        {subscription &&
                                            subscription.invoices &&
                                            subscription.invoices.map(
                                                (invoice) => {
                                                    return (
                                                        <SingleHistoryIncome
                                                            key={v4()}
                                                            invoice={invoice}
                                                            description={`Plano ${subscription?.subscription.plan.label}`}
                                                        />
                                                    )
                                                }
                                            )}
                                    </Table>
                                </div>
                            )}
                        </>
                    )}
                </FinancialContent>
            </ConfigurationContent>
            {showModal === 'CANCEL_PLAN' && (
                <CancelPlan
                    onRequestClose={() => closeModal()}
                    isOpen={showModal === 'CANCEL_PLAN'}
                    id={subscription?.subscription.id}
                />
            )}
        </Container>
    )
}
