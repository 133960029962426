import styled from 'styled-components'

export const ReportContainer = styled.div`
    padding: 2rem;
    width: 70rem;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .header {
        margin-bottom: 4.5rem;
        h1 {
            font-size: 2.4rem;
            font-weight: 600;
        }
        p {
            margin-top: 0.6rem;
            font-size: 1.6rem;
        }
    }
    .infos {
        .info {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 2rem;
            p {
                span {
                    font-weight: 600;
                }
                font-size: 1.8rem;
            }
            svg {
                width: 2rem;
                height: 2rem;
                margin-right: 2rem;
            }
        }
        .not-found {
            .info-btn {
                margin-left: 1rem;
                border: none;
                background-color: inherit;
                outline: none;
            }
        }
    }
    .btns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem;
        .submit {
            padding: 0.5rem 2.8rem;
            border: none;
            border-radius: 1.5rem;
            transition: all 0.4s;
            outline: none;
            background-color: #333;
            color: #fff;
            font-size: 1.6rem;
        }
    }
`

export const InfoContainer = styled.div`
    padding: 2rem;
    width: 50rem;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    h1 {
        font-weight: 600;
        font-size: 2rem;
    }
    p {
        margin: 2rem 0;
        size: 1.5rem;
        font-weight: 400;
    }
    ul {
        margin-left: 2rem;
        li {
            font-weight: 600;
            font-size: 1.6rem;
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
        }
    }
`
