import React from 'react'
import { FiCheck } from 'react-icons/fi'
import { Link, useLocation } from 'react-router-dom'
import { MarketingContainer } from '../../container/MarketingContainer'
import { ChangePlanContainer } from './styles'
import { useUser } from '../../hooks/use-user'
import { ParamsRoutePlan } from '../../entitie/params-route-plan'
import masterCard from '../../assets/mastercard.png'
import elo from '../../assets/elo.png'
import visa from '../../assets/visa.png'
import amex from '../../assets/amex.png'

export function ChooseCreditCardPage() {
    // location
    const params = useLocation().state as ParamsRoutePlan

    // hooks
    const { user } = useUser()

    function labelPrice(
        type:
            | 'AGENCY'
            | 'AGENCY_PRO'
            | 'BUSINESS'
            | 'ENTERPRISE'
            | 'FREE'
            | undefined,
        value: number
    ) {
        if (type === 'FREE') {
            return 'Grátis'
        }

        return `R$ ${
            value.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) || 0
        }/mês`
    }

    function returnBrand() {
        if (user.creditCard?.brand === 'elo') {
            return elo
        }
        if (user.creditCard?.brand === 'visa') {
            return visa
        }
        if (user.creditCard?.brand === 'amex') {
            return amex
        }
        return masterCard
    }

    return (
        <MarketingContainer
            title={`${
                !params?.userPlan
                    ? 'Seja bem-vindo ao Ada!'
                    : 'Alteração de plano'
            }`}
            subTitle={`${
                !params?.userPlan
                    ? 'Conclua seu cadastro e comece a utilizar a plataforma.'
                    : `Seu plano atual é o ${params.userPlan?.label}`
            }`}
        >
            <ChangePlanContainer>
                <div className="title">
                    <h1 className="font">Informe seus dados de pagamento</h1>
                    <p>
                        Selecione a forma de pagamento que será usada para seu
                        novo plano.
                    </p>
                </div>
                <div className="grid">
                    <div className="credit-card grid-container">
                        <div className="logo">
                            <img src={returnBrand()} alt="cartão logo" />
                        </div>
                        <div className="info">
                            <h2>{`${
                                user.creditCard &&
                                user.creditCard.brand.charAt(0).toUpperCase() +
                                    user.creditCard.brand.slice(1)
                            } final ${user.creditCard?.lastFourDigits}`}</h2>
                            <h3>{user.creditCard?.holderName}</h3>
                        </div>
                        <div className="circle">
                            <FiCheck />
                        </div>
                    </div>
                    <div className="plan grid-container">
                        <div className="info">
                            <h2>{`Plano ${params?.plan?.label}`}</h2>
                            <h3>
                                {labelPrice(
                                    params?.plan?.type,
                                    params?.plan?.monthlyPrice as number
                                )}
                            </h3>
                        </div>
                        <div className="change-plan-btn">
                            <Link
                                to={{
                                    pathname: '/plans',
                                    state: {
                                        plan: undefined,
                                        userPlan: params.userPlan
                                            ? params.userPlan
                                            : undefined,
                                    },
                                }}
                            >
                                <button type="button">Trocar</button>
                            </Link>
                        </div>
                    </div>
                    <Link
                        to={{ pathname: 'register-credit-card', state: params }}
                    >
                        <button className="grid-container" type="button">
                            <div className="circle">+</div>
                            <h1>Alterar forma de pagamento</h1>
                            <div className="blank" />
                        </button>
                    </Link>
                </div>
                <Link to={{ pathname: 'confirm-plan', state: params }}>
                    <button className="confirm-btn" type="button">
                        Próximo
                    </button>
                </Link>
            </ChangePlanContainer>
        </MarketingContainer>
    )
}
