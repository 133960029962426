/* eslint-disable react/require-default-props */
import React from 'react'
import { MdEdit } from 'react-icons/md'
import { FaUserPlus } from 'react-icons/fa'
import { AiFillCloseCircle } from 'react-icons/ai'
import { PersonalCredential } from '../../entitie/personal-credential'
import { PersonalCredentialContainer } from './styles'
import { useCredential } from '../../hooks/use-credential'
import { WarningModal } from '../WarningModal'
import { ShareCredentials } from '../ShareCredentials'
import { HandleCredential } from '../HandleCredential'

interface Props {
    personalCredential?: PersonalCredential
}

export function SinglePersonalCredential({ personalCredential }: Props) {
    // hooks
    const {
        toggleDeleteCredetial,
        showDeleteCredential,
        toggleShareCredential,
        showShareCredential,
        toggleEditCredential,
        showEditCredential,
    } = useCredential()

    return (
        <PersonalCredentialContainer>
            <h1 className="start">{personalCredential?.label}</h1>
            <h1 className="center">
                {personalCredential?.airline
                    ? personalCredential.airline.label
                    : 'azul'}
            </h1>
            <h1 className="center">
                {personalCredential?.isActive ? 'Ativa' : 'Inativa'}
            </h1>
            <button
                type="button"
                className="center share-credential"
                onClick={() => toggleShareCredential(personalCredential?.id)}
            >
                <p>
                    {personalCredential?.users
                        ? personalCredential?.users.length
                        : 0}
                </p>
                <FaUserPlus />
            </button>
            <div className="edit-remove center">
                <button
                    className="edit"
                    type="button"
                    onClick={() => toggleEditCredential(personalCredential?.id)}
                >
                    <MdEdit />
                </button>
                <button
                    className="exclude"
                    type="button"
                    onClick={() =>
                        toggleDeleteCredetial(personalCredential?.id)
                    }
                >
                    <AiFillCloseCircle />
                </button>
            </div>
            {showDeleteCredential === personalCredential?.id && (
                <WarningModal
                    isOpen={!!showDeleteCredential}
                    onRequestClose={() => toggleDeleteCredetial(undefined)}
                    title="Deletar credencial"
                    description={`Tem certeza que você deseja deletar esta credencial ${personalCredential?.label}?`}
                    type="DELETE_CREDENTIAL"
                    id={personalCredential?.id}
                />
            )}
            {showShareCredential === personalCredential?.id && (
                <ShareCredentials
                    isOpen={!!showShareCredential}
                    onRequestClose={() => toggleShareCredential(undefined)}
                    personalCredential={personalCredential}
                />
            )}
            {showEditCredential === personalCredential?.id && (
                <HandleCredential
                    isOpen={!!showEditCredential}
                    onRequestClose={() => toggleEditCredential(undefined)}
                    initialData={personalCredential}
                />
            )}
        </PersonalCredentialContainer>
    )
}
