/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { ClipLoader } from 'react-spinners'
import { ConfigurationContent } from '../../components/ConfigirationContent'
import { PrimaryButton } from '../../components/PrimaryButton'
import { Container } from '../../container/Container'
import { CredentialsContent, Table } from './styles'
import { useCredential } from '../../hooks/use-credential'
import { HandleCredential } from '../../components/HandleCredential'
import { useUser } from '../../hooks/use-user'
import { SinglePersonalCredential } from '../../components/SinglePersonalCredential'
import { SingleExternalCredential } from '../../components/SingleExternalCredential'
import { RequestCredential } from '../../components/RequestCredential'

export function CredentialsPage() {
    // hooks
    const {
        getCredentials,
        toggleCreateCredential,
        showCreateCredential,
        toggleRequestCredential,
        showCredentialRequest,
        personalCredentials,
        externalCredentials,
    } = useCredential()
    const { user } = useUser()

    // state
    const [busy, setBusy] = useState(false)

    useEffect(() => {
        ;(async () => {
            setBusy(true)
            await getCredentials()
            setBusy(false)
        })()
    }, [])

    return (
        <Container active="CONFIGURATIONS" switchType={undefined}>
            <ConfigurationContent active="credentials">
                <CredentialsContent>
                    {busy ? (
                        <div className="loader">
                            <ClipLoader />
                        </div>
                    ) : (
                        <>
                            {user.userType === 'AGENCY' && (
                                <div className="my-credentials">
                                    <div className="title">
                                        <h1 className="heading-font-configuration">
                                            Minhas Credenciais
                                        </h1>
                                        <PrimaryButton
                                            color="primary"
                                            title="Cadastrar nova"
                                            callback={() =>
                                                toggleCreateCredential(true)
                                            }
                                        />
                                    </div>
                                    <Table>
                                        <div className="primary-row">
                                            <h1 className="start">
                                                Identificador
                                            </h1>
                                            <h1 className="center">
                                                Companhia
                                            </h1>
                                            <h1 className="center">Status</h1>
                                            <h1 className="center">
                                                Compartilhamentos
                                            </h1>
                                            <h1 className="center">
                                                Editar/Remover
                                            </h1>
                                        </div>
                                        {personalCredentials?.map(
                                            (personalCredential) => {
                                                return (
                                                    <SinglePersonalCredential
                                                        key={v4()}
                                                        personalCredential={
                                                            personalCredential
                                                        }
                                                    />
                                                )
                                            }
                                        )}
                                    </Table>
                                </div>
                            )}
                            <div className="shared-credentials">
                                <div className="title">
                                    <h1 className="heading-font-configuration">
                                        Compartilhadas comigo
                                    </h1>
                                    <PrimaryButton
                                        color="primary"
                                        title="Solicitar compartilhamento"
                                        callback={() =>
                                            toggleRequestCredential(true)
                                        }
                                    />
                                </div>
                                <Table>
                                    <div className="primary-row">
                                        <h1 className="start">Identificador</h1>
                                        <h1 className="center">
                                            Compartilhada por
                                        </h1>
                                        <h1 className="center">Companhia</h1>
                                        <h1 className="center">Status</h1>
                                        <h1 className="center">Remover</h1>
                                    </div>
                                    {externalCredentials?.map(
                                        (externalCredential) => {
                                            return (
                                                <SingleExternalCredential
                                                    key={v4()}
                                                    externalCredential={
                                                        externalCredential
                                                    }
                                                />
                                            )
                                        }
                                    )}
                                </Table>
                            </div>
                        </>
                    )}
                </CredentialsContent>
            </ConfigurationContent>
            <HandleCredential
                isOpen={showCreateCredential}
                onRequestClose={() => toggleCreateCredential(false)}
            />
            <RequestCredential
                isOpen={showCredentialRequest}
                onRequestClose={() => toggleRequestCredential(false)}
            />
        </Container>
    )
}
