import styled from 'styled-components'

export const ModalContent = styled.div`
    padding: 2rem;
    width: 100rem;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .title {
        h1 {
            margin-bottom: 1.3rem;
        }
        p {
            font-size: 1.6rem;
            font-weight: 400;
            color: #929292;
        }
    }
    .selectors {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        .colored {
            background-color: rgba(237, 243, 242, 0.53);
            border-radius: 12px 12px 0px 0px;
        }
    }
    form {
        width: 100%;
        .grid {
            background-color: rgba(237, 243, 242, 0.53);
            padding: 2rem;
            border-radius: 0px 12px 12px 12px;
            display: grid;
            grid-template-columns: repeat(12, [col-start] 1fr);
            grid-gap: 1rem;
            margin-bottom: 3rem;
            .username {
                grid-column: col-start / col-start 7;
            }
            .password {
                position: relative;
                grid-column: col-start 7 / col-start 13;
                .edit-password {
                    position: absolute;
                    right: 0;
                    border: none;
                    background-color: inherit;
                    color: var(--secondary);
                    outline: none;
                    z-index: 200;
                }
            }
            .label {
                grid-column: col-start / col-start 7;
            }
            .domain-code {
                grid-column: col-start 7 / col-start 10;
            }
            .organization {
                grid-column: col-start 10 / col-start 13;
            }
        }
        .button {
            width: 100%;
            text-align: center;
        }
    }
`

export const CiaSelector = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    border: none;
    padding: 2rem 0;
    outline: none;
    background-color: inherit;
    margin-top: 3rem;
    padding: 1.8rem;
    h2 {
        font-size: 1.8rem;
        font-weight: 500;
    }
    svg {
        height: 2.2rem;
        width: 2.2rem;
        margin-right: 1.3rem;
        color: #757575;
    }
`
