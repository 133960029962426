/* eslint-disable react/require-default-props */
import React from 'react'
// eslint-disable-next-line import/no-duplicates
import { format, addYears } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import { FiAlertTriangle } from 'react-icons/fi'
import { Booking } from '../../entitie/booking'
import { SigleDashboardBookingContainer } from './styles'
import { BoardingStatus } from '../../entitie/boarding-status'

interface Props {
    booking?: Booking
}

export function SigleDashboardBooking({ booking }: Props) {
    function boardingStatusLabel(status?: BoardingStatus) {
        if (status === BoardingStatus.COMPLETELY_FLOWN) {
            return <span>Voado</span>
        }
        if (status === BoardingStatus.NOT_FLOWN) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(231, 75, 112, 0.2)',
                        color: '#E74B70',
                    }}
                >
                    Não voado
                </span>
            )
        }
        if (status === BoardingStatus.PARTIALLY_FLOWN) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(241, 197, 35, 0.2)',
                        color: '#F1C523',
                    }}
                >
                    Parcialmente voado
                </span>
            )
        }
        return 'Informações insuficientes'
    }

    return (
        <SigleDashboardBookingContainer>
            <h1 className="start">{booking?.passengers[0]}</h1>
            <h1 className="start">{booking?.recordLocator}</h1>
            <h1 className="center">
                {booking?.airline && booking.airline.label}
            </h1>
            <div className="center status">
                {boardingStatusLabel(booking?.boardingStatus)}
            </div>
            <div className="center relative">
                <h1>
                    {booking?.reissueCredit !== null &&
                    booking?.reissueCredit !== undefined
                        ? `R$ ${
                              booking?.reissueCredit.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                              }) || 0
                          }`
                        : 'Dados insuficientes'}
                </h1>
                {booking?.isCovid && <FiAlertTriangle />}
            </div>
            <h1 className="end">{`${format(
                addYears(new Date(booking?.issueDate || ''), 1),
                `dd'/'MM'/'Y`,
                {
                    locale: ptLocale,
                }
            )}`}</h1>
        </SigleDashboardBookingContainer>
    )
}
