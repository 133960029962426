import styled, { keyframes } from 'styled-components'

const appear = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })

export const ConfirmPlanContainer = styled.div`
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 82rem;
    border-radius: 2.6rem;
    background-color: #000;
    padding: 3rem;
    box-shadow: 0.2px 0.2px 60px #aaa;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: ${appear} 1s ease;

    .title {
        width: 45rem;
        .font {
            font-weight: 600;
            font-size: 2.4rem;
            color: #fff;
        }
        p {
            color: #fff;
            font-weight: 400;
            font-size: 1.4rem;
        }
    }
    .grid {
        margin-top: 3rem;
        display: grid;
        grid-template-columns: 6fr 5fr;
        column-gap: 5rem;
        row-gap: 2rem;

        .grid-container {
            background-color: inherit;
            border: 1px solid #fff;
            padding: 1.5rem;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 8rem;
            .circle {
                background-color: #e5e5e5;
                border-radius: 50%;
                height: 1.8rem;
                width: 1.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            h1 {
                font-weight: 600;
                font-size: 1.5rem;
                color: #fff;
            }
            a {
                button {
                    background-color: inherit;
                    border: none;
                    color: #fff;
                }
            }
        }
        .card-term {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .credit-card {
                .logo {
                    width: 6rem;
                    position: relative;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        height: 6rem;
                        width: 8rem;
                        margin-left: 1rem;
                        object-fit: contain;
                    }
                }
            }
            .term {
                margin-top: 2rem;
                p {
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 1.2rem;
                    font-weight: 300;
                }
            }
        }
        .card-plan {
            .plan {
                width: 290px;
                margin: 0 auto 0 auto;
            }
        }
        .info {
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            h2 {
                font-weight: 600;
                font-size: 1.5rem;
                color: #fff;
                margin-bottom: 0.5rem;
            }
            h3 {
                font-weight: 400;
                font-size: 1.3rem;
                color: #fff;
            }
        }
    }

    .confirm-btn {
        align-self: flex-end;
        width: 20rem;
        height: 4.5rem;
        border-radius: 2.4rem;
        background-color: #e5e5e5;
        border: none;
        outline: none;
        margin-top: 2rem;
    }
`
