import styled from 'styled-components'

export const ProfileContent = styled.div`
    padding: 4.2rem;
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3rem;
    }
    .grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12, [col-start] 1fr);
        grid-gap: 1rem;
        margin-bottom: 3rem;
        .name {
            grid-column: col-start / col-start 13;
        }
        .legal-name {
            grid-column: col-start / col-start 13;
        }
        .cep {
            grid-column: col-start / col-start 4;
        }
        .street {
            grid-column: col-start 4 / col-start 10;
        }
        .state {
            grid-column: col-start 10 / col-start 13;
        }
        .complement {
            grid-column: col-start / col-start 10;
        }
        .number {
            grid-column: col-start 10 / col-start 13;
        }
        .phone {
            grid-column: col-start / col-start 13;
        }
        .email {
            grid-column: col-start / col-start 7;
        }
        .fake-password {
            grid-column: col-start 7 / col-start 13;
        }
        .old-password {
            grid-column: col-start / col-start 7;
        }
        .new-password {
            grid-column: col-start 7 / col-start 13;
        }
        .change-password {
            display: none;
            div {
                display: none;
            }
        }
    }
    .change-password {
        margin-top: -3rem;
        margin-bottom: 3rem;
        text-align: end;
        button {
            border: none;
            background-color: inherit;
            color: #7a807f;
            outline: none;
            border-bottom: 1px solid #7a807f;
        }
    }
`
