import styled from 'styled-components'

export const RequestCredentialContainer = styled.div`
    padding: 2rem;
    width: 70rem;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .title {
        border-bottom: 1px solid rgba(194, 194, 194, 0.31);
        padding: 0 0 1.6rem 0;
        margin-bottom: 2rem;
    }
    .description {
        p {
            line-height: 2.6rem;
        }
    }
    form {
        .row {
            margin: 3rem 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
        }
        .btn {
            width: 100%;
            text-align: center;
        }
    }
`
