import React from 'react'
import { MdCloudUpload } from 'react-icons/md'
import { FirstStepContent, SecondStep, StepsContent } from './styles'
import stepOne from '../../assets/step-one.png'
import stepTwo from '../../assets/step-two.png'
import { PrimaryButton } from '../../components/PrimaryButton'
import { useBooking } from '../../hooks/use-booking'
import { useUpload } from '../../hooks/use-upload'
import { ProgressiveBar } from '../../components/ProgressiveBar'
import { useUser } from '../../hooks/use-user'
import { TutorialSteps } from '../../entitie/tutorial-steps'
import { BookingUploadStatus, Upload } from '../../entitie/upload'

export function Steps() {
    // hooks
    const { toggleImportBookinfg } = useBooking()
    const { currentUpload } = useUpload()
    const { tutorialStep } = useUser()

    function uploadPercentage(items: Upload['items']) {
        const numberOfPendings = items.filter(
            (item) => item.status === BookingUploadStatus.PENDING
        ).length
        const numberOfProcessing = items.filter(
            (item) => item.status === BookingUploadStatus.PROCESSING
        ).length
        return (
            ((items.length - (numberOfPendings + numberOfProcessing)) /
                items.length) *
            100
        )
    }

    return (
        <>
            <StepsContent>
                <div className="title">
                    <h1>Primeiros passos</h1>
                </div>
                <div className="steps">
                    <div className="progress">
                        <div className="ball-label">
                            <div className="ball colored">
                                <h1>1</h1>
                            </div>
                            <h2>Cadastrar</h2>
                        </div>
                        <div className="first-line colored" />
                        <div className="ball-label">
                            <div
                                className={`ball ${
                                    tutorialStep === TutorialSteps.importBooking
                                        ? 'colored'
                                        : ''
                                }`}
                            >
                                <h1>2</h1>
                            </div>
                            <h2>Configurar credênciais</h2>
                        </div>
                        <div
                            className={`second-line ${
                                tutorialStep === TutorialSteps.importBooking
                                    ? 'colored'
                                    : ''
                            }`}
                        />
                        <div className="ball-label">
                            <div className="ball">
                                <h1>3</h1>
                            </div>
                            <h2>Consultar bilhetes</h2>
                        </div>
                    </div>
                </div>
                {tutorialStep === TutorialSteps.credentials && (
                    <FirstStepContent>
                        <div className="step-title">
                            <h1>Configurar credênciais</h1>
                            <p>{`Configurações > Credênciais > Nova Credêncial ou Solicitar`}</p>
                        </div>
                        <div className="image">
                            <img src={stepOne} alt="Primeiro passo" />
                        </div>
                        <div className="description">
                            <p>
                                Para consultar seus bilhetes, é necessário que
                                você cadastre as credênciais utilizadas por sua
                                agência
                            </p>
                        </div>
                    </FirstStepContent>
                )}
                {tutorialStep === TutorialSteps.importBooking && (
                    <SecondStep>
                        <div className="second-step-title">
                            <h1>Consultar bilhetes</h1>
                            <p>
                                Consulte seu primeiro bilhete individualmente ou
                                carregue uma planilha clicando em “Importar
                                bilhetes”!
                            </p>
                        </div>
                        <div className="image">
                            <img src={stepTwo} alt="Primeiro passo" />
                            {currentUpload ? (
                                <ProgressiveBar
                                    done={
                                        20 +
                                        uploadPercentage(currentUpload.items)
                                    }
                                />
                            ) : (
                                <PrimaryButton
                                    icon={MdCloudUpload}
                                    title="Importar bilhetes"
                                    color="red-white"
                                    callback={() => toggleImportBookinfg(true)}
                                />
                            )}
                        </div>
                    </SecondStep>
                )}
            </StepsContent>
        </>
    )
}
