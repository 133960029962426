/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import React, { ReactNode, useEffect } from 'react'
import { MdCloudUpload } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BookingDetail } from '../../components/BookingDetail'
import { ExcelErrors } from '../../components/ExcelErrors'
import { FiltersComponent } from '../../components/Filters'
import { Header } from '../../components/Header'
import { ImportBooking } from '../../components/ImportBooking'
import { PrimaryButton } from '../../components/PrimaryButton'
import { ProgressiveBar } from '../../components/ProgressiveBar'
import { Report } from '../../components/Report'
import { Switch } from '../../components/Switch'
import { TutorialSteps } from '../../entitie/tutorial-steps'
import { BookingUploadStatus, Upload } from '../../entitie/upload'
import { useBooking } from '../../hooks/use-booking'
import { useUpload } from '../../hooks/use-upload'
import { useUser } from '../../hooks/use-user'
import { FiltersContainer } from '../../pages/Analytical/styles'
import { ContainerStyled } from './styles'

interface Props {
    children: ReactNode
    active: 'DASHBOARD' | 'CONFIGURATIONS'
    switchType: 'SYNTHETIC' | 'ANALYTICAL' | undefined
}

export function Container({ children, active, switchType }: Props) {
    const { upload, currentUpload, showReport, toggleReport, getUpload} = useUpload()
    const { getUser, user, getAirlines, airlines,  subscription } = useUser()
    const {toggleImportBookinfg, showImportBooking, getFilters, filters, showBookingDetail,
        toggleBookingDetail, excelErrors, clearExcelErrors} = useBooking()
    
    useEffect(() => {
        (async() => {
            await getUser()
            await getAirlines()
            await getFilters()
            await getUpload()
        })()
    }, [])

    function uploadPercentage(items: Upload['items']) {
        const numberOfPendings = items.filter(
            (item) => item.status === BookingUploadStatus.PENDING
        ).length
        const numberOfProcessing = items.filter(
            (item) => item.status === BookingUploadStatus.PROCESSING
        ).length
        return (
            ((items.length - (numberOfPendings + numberOfProcessing)) /
                items.length) *
            100
        )
    }


    
    return (
        <ContainerStyled>
            <Header active={active} />
            {user.tutorialStep === TutorialSteps.finished && switchType && subscription?.subscription.plan.type !== 'FREE' && (
                <div className="switch">
                    <Link
                        to={
                            switchType === 'SYNTHETIC'
                                ? '/analytics'
                                : '/home'
                        }
                    >
                        <Switch switchType={switchType} />
                    </Link>
                </div>
            )}
            <div className="main">
                {user.tutorialStep === TutorialSteps.finished && (
                    <>
                    {active === 'DASHBOARD' && <FiltersContainer>
                        {subscription?.subscription.plan.type !== 'FREE' && <FiltersComponent airlines={airlines} filters={filters} type={switchType}/>}
                        <div className="import-btn">
                        {upload ? (
                            <ProgressiveBar
                                done={
                                    20 +
                                    uploadPercentage(upload.items)
                                }
                            />
                            ) : (
                                <PrimaryButton
                                    icon={MdCloudUpload}
                                    title="Importar bilhetes"
                                    color="red-white"
                                    callback={() => toggleImportBookinfg(true)}
                                />
                            )}
                         </div>
                    </FiltersContainer>}
                </>
                )}
                   
                    {children}
            </div>
             {showReport && <Report isOpen={showReport} onRequestClose={() => toggleReport(false)} currentUpload={currentUpload}/>}
             {showImportBooking && (
                <ImportBooking
                    isOpen={showImportBooking}
                    user={user}
                    onRequestClose={() => toggleImportBookinfg(false)}
                    airlines={airlines}
                />
            )}
            <BookingDetail
                isOpen={!!showBookingDetail}
                onRequestClose={() => toggleBookingDetail(undefined)}
                bookingId={showBookingDetail}
            />
            {excelErrors && <ExcelErrors isOpen={excelErrors.length > 0} onRequestClose={() => clearExcelErrors()} excelErrors={excelErrors}/> }
        </ContainerStyled>
    )
}
