/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState, ReactNode } from 'react'

interface ModalContext {
    openModal(type: 'CANCEL_PLAN' | 'SECOND'): void
    closeModal(): void
    showModal: 'CANCEL_PLAN' | 'SECOND' | undefined
}

interface AppProvideProps {
    children: ReactNode
}

const ModalContext = createContext({} as ModalContext)

export function ModalProvider({ children }: AppProvideProps) {
    // state
    const [showModal, setShowModal] = useState<
        'CANCEL_PLAN' | 'SECOND' | undefined
    >()

    function openModal(type: 'CANCEL_PLAN' | 'SECOND') {
        setShowModal(type)
    }

    function closeModal() {
        setShowModal(undefined)
    }

    return (
        <ModalContext.Provider value={{ showModal, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    )
}

export function useModal(): ModalContext {
    const context = useContext(ModalContext)
    if (!context) {
        throw new Error('useModal must be used within a ToastProvider')
    }
    return context
}
