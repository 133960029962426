import styled from 'styled-components'

export const CancelPlanContainer = styled.div`
    padding: 2rem;
    width: 70rem;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .title {
        border-bottom: 1px solid rgba(194, 194, 194, 0.31);
        padding: 0 0 1.6rem 0;
        margin-bottom: 2rem;
        h1 {
            color: #323232;
            font-weight: 500;
            font-size: 2.2rem;
        }
    }
    .description {
        p {
            line-height: 1.6rem;
            color: #929292;
            font-weight: 400;
        }
        margin-bottom: 5rem;
    }
    h2 {
        color: #323232;
        font-weight: 500;
        font-size: 2rem;
        margin-bottom: 5rem;
    }
    .btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        button {
            &:not(:last-child) {
                margin-right: 2rem;
            }
        }
    }
`
