import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import React, { useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { InitialInput } from '../../components/InitialInput'
import { InitialContainer } from '../../container/InitialContainer'
import { useAuth } from '../../hooks/use-auth'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { ResetPasswordContainer } from './styles'
import { Button } from '../../components/Button'

export function ResetPasswordPage() {
    // refs
    const formRef = useRef<FormHandles>(null)

    // hooks
    const { changePassword } = useAuth()

    // state
    const [busyBtn, setBusyBtn] = useState<boolean>(false)
    const [redirect, setRedirect] = useState<boolean>(false)

    const [, token] = window.location.search.split('=')

    async function handleResetPassword(data: {
        password: string
        confirmPassword: string
    }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                password: Yup.string()
                    .required('Insira uma senha valida ')
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                        'Senha deve conter: letra maiúscula; letra minúscula; número, caracter especial. Mínimo de 6 dígitos.'
                    )
                    .max(32, 'A senha pode conter no máximo 32 dígitos'),
                confirmPassword: Yup.string()
                    .required('Confirme sua senha')
                    .oneOf([Yup.ref('password')], 'Senhas não coincidem'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            await changePassword({ password: data.password, token })
            setBusyBtn(false)
            setRedirect(true)
        } catch (error) {
            setBusyBtn(false)
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }
    return (
        <InitialContainer>
            <ResetPasswordContainer>
                <h1 className="heading-font">Trocar de senha.</h1>
                <Form ref={formRef} onSubmit={handleResetPassword}>
                    <InitialInput
                        name="password"
                        labelTitle="Senha"
                        type="password"
                    />
                    <InitialInput
                        name="confirmPassword"
                        labelTitle="Confirmar senha"
                        type="password"
                    />
                    <div className="reset-btn">
                        <Button
                            title="Confirmar"
                            color="primary"
                            busy={busyBtn}
                            isSubmit
                        />
                    </div>
                </Form>
            </ResetPasswordContainer>
            {redirect && <Redirect to="/" />}
        </InitialContainer>
    )
}
