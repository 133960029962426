import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { ExternalCredential } from '../../entitie/external-credential'
import { SingleExternalCredentialContainer } from './styles'

interface Props {
    externalCredential: ExternalCredential
}

export function SingleExternalCredential({ externalCredential }: Props) {
    return (
        <SingleExternalCredentialContainer>
            <h1 className="start">{externalCredential?.label}</h1>
            <h1 className="center">{externalCredential?.owner.name}</h1>
            <h1 className="center">{externalCredential?.airline.label}</h1>
            <h1 className="center">
                {externalCredential?.isActive ? 'Ativa' : 'Inativa'}
            </h1>
            <div className="remove center">
                <button className="exclude" type="button">
                    <AiFillCloseCircle />
                </button>
            </div>
        </SingleExternalCredentialContainer>
    )
}
