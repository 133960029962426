import React from 'react'

import {
    IgrDoughnutChartModule,
    IgrDoughnutChart,
    IgrRingSeriesModule,
    IgrRingSeries,
} from 'igniteui-react-charts'
import { DoughnutPenaltyChartContainer } from './styles'

IgrDoughnutChartModule.register()
IgrRingSeriesModule.register()

interface Props {
    color: string[]
    data: { Value?: number; Label: string }[]
}

export function DoughnutPenaltyChart({ color, data }: Props) {
    return (
        <DoughnutPenaltyChartContainer>
            <IgrDoughnutChart height="100%" width="100%" innerExtent={65}>
                <IgrRingSeries
                    startAngle={270}
                    name="ring1"
                    dataSource={data}
                    valueMemberPath="Value"
                    brushes={color}
                />
            </IgrDoughnutChart>
        </DoughnutPenaltyChartContainer>
    )
}
