import styled from 'styled-components'

export const SignupFirstStepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    h1 {
        margin-bottom: 5rem;
        font-weight: 600;
    }
    form {
        width: 100%;
        .select {
            margin-bottom: 2rem;
        }
        .policies {
            .policies-checkbox {
                font-size: 1.2rem;
                color: #333;
                font-weight: 600;
                display: flex;
                input {
                    margin-right: 0.3rem;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            p {
                margin-top: 0.5rem;
                font-weight: 300;
                font-size: 1.1rem;
                color: #888;
                button {
                    background-color: inherit;
                    font-weight: 600;
                    font-size: 1.1rem;
                    color: #333;
                    border: none;
                }
            }
        }
        .next-step {
            button {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 56.25em) {
        width: 90%;
    }
`
