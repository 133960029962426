import React, {
    createContext,
    useState,
    useCallback,
    useContext,
    ReactNode,
} from 'react'
import { v4 } from 'uuid'
import ToastContainer from '../container/ToastContainer'

export interface ToastMessage {
    id: string
    type?: 'success' | 'error' | 'info'
    title: string
    description?: string
    duration?: number
}

interface ToastContextData {
    addToast(message: Omit<ToastMessage, 'id'>): void
    removeToast(id: string): void
}

interface AppProvideProps {
    children: ReactNode
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

export function ToastProvider({ children }: AppProvideProps) {
    // state
    const [messages, setMessages] = useState<ToastMessage[]>([])

    const addToast = useCallback(
        ({ type, title, description, duration }: Omit<ToastMessage, 'id'>) => {
            const id = v4()

            const toast = {
                id,
                type,
                title,
                description,
                duration,
            }
            setMessages((state) => [...state, toast])
        },
        []
    )

    const removeToast = useCallback((id: string) => {
        setMessages((state) => state.filter((message) => message.id !== id))
    }, [])

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {children}
            <ToastContainer messages={messages} />
        </ToastContext.Provider>
    )
}

export function useToast(): ToastContextData {
    const context = useContext(ToastContext)

    if (!context) {
        throw new Error('useToast must be used within a ToastProvider')
    }
    return context
}
