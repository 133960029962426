/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { useCredential } from '../../hooks/use-credential'
import { PrimaryButton } from '../PrimaryButton'
import { WarningModalContainer } from './styles'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    title: string
    description: string
    type: 'DELETE_CREDENTIAL'
    id?: string
}

export function WarningModal({
    isOpen,
    onRequestClose,
    title,
    description,
    type,
    id,
}: Props) {
    // hooks
    const { deleteCredential } = useCredential()

    // state
    const [busyBtn, setBusyBtn] = useState(false)

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <WarningModalContainer>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <div className="title">
                    <h1 className="heading-font-configuration">{title}</h1>
                </div>
                <div className="description">
                    <p>{description}</p>
                </div>
                <div className="btns">
                    <PrimaryButton
                        title="Cancelar"
                        color="bordered"
                        callback={() => onRequestClose()}
                    />
                    <PrimaryButton
                        title="Excluir"
                        color="primary"
                        busy={busyBtn}
                        callback={async () => {
                            setBusyBtn(true)
                            if (type === 'DELETE_CREDENTIAL' && id) {
                                const response = await deleteCredential(id)
                                if (response) {
                                    setBusyBtn(false)
                                    onRequestClose()
                                }
                                setBusyBtn(false)
                            }
                        }}
                    />
                </div>
            </WarningModalContainer>
        </ModalContainer>
    )
}
