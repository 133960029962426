/* eslint-disable consistent-return */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState, ReactNode } from 'react'
import { BookingUploadStatus, Upload } from '../entitie/upload'
import { UploadV2 } from '../entitie/upload-v2'
import { api } from '../service/api'
import { useAuth } from './use-auth'

interface UploadContext {
    getUpload(): Promise<UploadV2 | undefined>
    deleteUpload(): Promise<void>
    updateUpload(payload: Upload): void
    toggleReport(bool: boolean): void
    showReport: boolean
    currentUpload?: Upload
    upload?: UploadV2 | undefined
    noUpload: boolean
}

interface AppProvideProps {
    children: ReactNode
}

const UploadContext = createContext({} as UploadContext)

export function UploadProvider({ children }: AppProvideProps) {
    // hooks
    const { token } = useAuth()

    // state
    const [currentUpload, setCurrentUpload] = useState<Upload | undefined>(
        undefined
    )
    const [showReport, setShowReport] = useState<boolean>(false)
    const [upload, setUpload] = useState<UploadV2 | undefined>()
    const [noUpload, setNoUpload] = useState<boolean>(false)

    function toggleReport(bool: boolean) {
        setShowReport(bool)
    }

    async function getUpload() {
        try {
            if (!token) {
                return
            }
            const response = await api.get<UploadV2>('/uploads', {
                headers: {
                    authorization: token,
                },
            })
            setCurrentUpload(response.data)
            setUpload(response.data)
            if (
                response.data.items.every(
                    (item) =>
                        item.status !== BookingUploadStatus.PENDING &&
                        item.status !== BookingUploadStatus.PROCESSING
                )
            ) {
                toggleReport(true)
            }
            setNoUpload(true)
            return response.data
        } catch (error) {
            setNoUpload(false)
            setUpload(undefined)
            return undefined
        }
    }

    async function deleteUpload() {
        try {
            await api.post(
                `/uploads/complete`,
                {},
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
        } catch (error) {}
    }

    function updateUpload(payload: Upload) {
        setCurrentUpload(payload)
        if (
            payload.items.every(
                (item) =>
                    item.status !== BookingUploadStatus.PENDING &&
                    item.status !== BookingUploadStatus.PROCESSING
            )
        ) {
            toggleReport(true)
        }
    }

    return (
        <UploadContext.Provider
            value={{
                getUpload,
                deleteUpload,
                updateUpload,
                toggleReport,
                upload,
                showReport,
                currentUpload,
                noUpload,
            }}
        >
            {children}
        </UploadContext.Provider>
    )
}

export function useUpload(): UploadContext {
    const context = useContext(UploadContext)
    if (!context) {
        throw new Error('useUpload must be used within a ToastProvider')
    }
    return context
}
