import styled from 'styled-components'

export const ImportBookingContainer = styled.div`
    padding: 2rem;
    width: 70rem;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .header {
        margin-bottom: 4.5rem;
        h1 {
            font-size: 2.4rem;
            font-weight: 600;
        }
        p {
            margin-top: 0.6rem;
            font-size: 1.6rem;
        }
        span {
            font-weight: 600;
        }
    }
    .title {
        margin-bottom: 3rem;
        h1 {
            font-size: 2rem;
            font-weight: 500;
        }
        p {
            margin-top: 0.6rem;
            font-size: 1.6rem;
        }
        a {
            color: var(--tertiary);
        }
    }
    .multiple-bookings {
        margin-bottom: 3rem;
    }
    .import-btn {
        padding: 1.2rem 2.8rem;
        border: none;
        border-radius: 1.5rem;
        transition: all 0.4s;
        background-color: #333;
        outline: none;
        color: #fff;
    }
    .row {
        display: flex;
        align-items: center;
        justify-content: start;
        button {
            margin-left: 3rem;
        }
    }
    .import-single-booking {
        form {
            display: grid;
            grid-template-columns: 2fr 2fr 2fr;
            grid-gap: 2rem;
            .locator-input {
                margin: 0;
                height: 38px;
                border-radius: 3px;
            }
            button {
                height: 38px;
                padding: 0;
                width: 12rem;
            }
        }
    }
`

export const MultipleBookingsBtn = styled.div`
    width: 40rem;
    height: 4.7rem;
    label {
        .container {
            width: 40rem;
            height: 4.7rem;
            border-radius: 6px;
            border: 1px dashed #000;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                color: #555;
                font-size: 1.6rem;
            }
        }
        input {
            display: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
`
