import styled from 'styled-components'

export const ProgressiveBarContainer = styled.div`
    background-color: #d8d8d8;
    border-radius: 20px;
    position: relative;
    height: 4.3rem;
    width: 300px;
    .progress-done {
        background: linear-gradient(to left, #f2909c, #ff9472);
        box-shadow: 0 3px 3px -5px #f2909c, 0 2px 5px #f2909c;
        border-radius: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0; /* dynamic - controlled by js */
        height: 100%;
        width: 0; /* dynamic - controlled by js */
        transition: 2s ease;
        animation: takes (1 second), type=ease;
    }
`
