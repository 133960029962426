import styled from 'styled-components'
import backgroundImage from '../../assets/plan-background.png'

export const MarketingContainerStyle = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    background: url(${backgroundImage}) no-repeat center #fff;
    img {
        position: absolute;
        top: 3rem;
        left: 3rem;
        width: 18rem;
    }
    @media screen and (max-width: 87.5em) {
        background-size: 120vh;
    }
`
export const ContentMarketingContainer = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 80vh;
    width: 70%;
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            font-size: 4.8rem;
            font-weight: 500;
            color: #000;
        }
        p {
            margin-top: 1rem;
            font-size: 1.8rem;
            font-weight: 500;
            color: #555;
        }
    }
`
