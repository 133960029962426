/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react'
// eslint-disable-next-line import/no-duplicates
import { addYears } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import { utcToZonedTime, format } from 'date-fns-tz'
import ModalContainer from 'react-modal'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { FiEdit, FiSave } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'
import { BookingDetailContainer } from './styles'
import { CreditStatus } from '../../entitie/credit-status'
import { BoardingStatus } from '../../entitie/boarding-status'
import gol from '../../assets/gol.png'
import azul from '../../assets/azul.png'
import voepass from '../../assets/voepass.png'
import latam from '../../assets/latam.png'
import { useBooking } from '../../hooks/use-booking'
import { Input } from '../Input'
import { PrimaryButton } from '../PrimaryButton'
import { EditBookingDto } from '../../dtos/edit-booking'
import { BookingComments } from '../BookingComments'
import { cpfFormatter } from '../../utils/formatter'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    bookingId?: string
}

export function BookingDetail({ isOpen, onRequestClose, bookingId }: Props) {
    // hooks
    const {
        getSingleBooking,
        singleBooking,
        editBooking,
        toggleBookingComments,
        showBookingComments,
    } = useBooking()

    // refs
    const formRef = useRef<FormHandles>(null)

    // state
    const [busy, setBusy] = useState(false)
    const [busyBtn, setBusyBtn] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [client, setClient] = useState<string | undefined>(
        singleBooking?.client
    )
    const [costCenter, setCostCenter] = useState<string | undefined>(
        singleBooking?.costCenter
    )
    const [serviceOrderNumber, setServiceOrderNumber] = useState<
        string | undefined
    >(singleBooking?.serviceOrderNumber)
    const [cpf, seCpf] = useState<string | undefined>(singleBooking?.cpf)

    function creditStatusLabel(status?: CreditStatus) {
        if (status === CreditStatus.FUTURE) {
            return (
                <span
                    style={{
                        backgroundColor: '#D6DDDC',
                        color: '#333333',
                    }}
                >
                    Futuro
                </span>
            )
        }
        if (status === CreditStatus.NO_CREDIT) {
            return (
                <span
                    style={{
                        backgroundColor: '#D6DDDC',
                        color: '#333333',
                    }}
                >
                    Sem crédito
                </span>
            )
        }
        if (status === CreditStatus.PENDING) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(231, 75, 112, 0.2)',
                        color: '#E74B70',
                    }}
                >
                    Em aberto
                </span>
            )
        }
        return (
            <span
                style={{
                    backgroundColor: 'rgba(23, 158, 233, 0.2)',
                    color: '#179EE9',
                }}
            >
                Crédito utilizado
            </span>
        )
    }

    function airlineImg(
        iata: 'AD' | 'LA' | 'G3' | '2Z' | 'LAS' | 'LAN' | 'ADR'
    ) {
        if (iata === 'AD') return azul
        if (iata === 'G3') return gol
        if (iata === 'LA') return latam
        if (iata === 'LAS') return latam
        if (iata === 'LAN') return latam
        if (iata === 'ADR') return azul

        return voepass
    }

    function refundCredit(value?: number) {
        if (value === undefined || value === null) {
            return 'Dados insuficientes'
        }

        if (value < 0) {
            return 'Taxa(s) de embarque'
        }
        return `R$ ${value.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}`
    }

    function boardingStatusLabel(status?: BoardingStatus) {
        if (status === BoardingStatus.COMPLETELY_FLOWN) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(23, 158, 233, 0.2)',
                        color: '#179EE9',
                    }}
                >
                    Bilhete voado
                </span>
            )
        }
        if (status === BoardingStatus.NOT_FLOWN) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(231, 75, 112, 0.2)',
                        color: '#E74B70',
                    }}
                >
                    Bilhete não voado
                </span>
            )
        }
        if (status === BoardingStatus.PARTIALLY_FLOWN) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(241, 197, 35, 0.2)',
                        color: '#F1C523',
                    }}
                >
                    Bilhete parcialmente voado
                </span>
            )
        }
        return 'Informações insuficientes'
    }

    useEffect(() => {
        setBusy(true)
        ;(async () => {
            if (bookingId) {
                await getSingleBooking(bookingId)
            }
            setBusy(false)
        })()
        setCanEdit(false)
    }, [isOpen])

    useEffect(() => {
        if (canEdit) {
            formRef.current?.setData({
                client: singleBooking?.client,
                costCenter: singleBooking?.costCenter,
                serviceOrderNumber: singleBooking?.serviceOrderNumber,
                cpf: singleBooking?.cpf,
            })
        }
    }, [canEdit])

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <BookingDetailContainer>
                {busy ? (
                    <div className="loader">
                        <ClipLoader />
                    </div>
                ) : (
                    <Form
                        ref={formRef}
                        onSubmit={async (data) => {
                            setBusyBtn(true)
                            const editData: EditBookingDto = {
                                client: client === '' ? undefined : client,
                                costCenter:
                                    costCenter === '' ? undefined : costCenter,
                                serviceOrderNumber:
                                    serviceOrderNumber === ''
                                        ? undefined
                                        : serviceOrderNumber,
                                cpf: cpf === '' ? undefined : cpf,
                            }
                            await editBooking(editData, singleBooking?.id || '')
                            setBusyBtn(false)
                        }}
                    >
                        <button
                            className="close"
                            type="button"
                            onClick={() => onRequestClose()}
                        >
                            X
                        </button>
                        <div className="header">
                            {singleBooking?.airline && (
                                <img
                                    src={airlineImg(
                                        singleBooking.airline.iataCode
                                    )}
                                    alt="Gol"
                                />
                            )}
                            <div className="title-header">
                                <div className="locator">
                                    {singleBooking?.airline ? (
                                        <h1>{singleBooking?.airline.label}</h1>
                                    ) : null}
                                    <p>
                                        {singleBooking?.recordLocator ||
                                            '123456'}
                                    </p>
                                </div>
                                <div className="status">
                                    {creditStatusLabel(
                                        singleBooking?.creditStatus
                                    )}
                                    {singleBooking?.creditStatus ===
                                        CreditStatus.NO_CREDIT && (
                                        <h2>Bilhete não possui crédito</h2>
                                    )}
                                    {(singleBooking?.creditStatus ===
                                        CreditStatus.REQUESTED ||
                                        singleBooking?.creditStatus ===
                                            CreditStatus.REUSED) && (
                                        <h2>
                                            Bilhete remarcado ou reembolsado
                                        </h2>
                                    )}
                                    {singleBooking?.creditStatus ===
                                        CreditStatus.PENDING && (
                                        <h2>{`Crédito disponível até ${
                                            singleBooking &&
                                            singleBooking.refundExpirationDate
                                                ? format(
                                                      utcToZonedTime(
                                                          new Date(
                                                              singleBooking.refundExpirationDate
                                                          ),
                                                          'Europe/Berlin'
                                                      ),
                                                      `dd'/'MM'/'Y`
                                                  )
                                                : `${format(
                                                      addYears(
                                                          utcToZonedTime(
                                                              new Date(
                                                                  singleBooking?.issueDate ||
                                                                      ''
                                                              ),
                                                              'Europe/Berlin'
                                                          ) || 0,
                                                          1
                                                      ),
                                                      `dd'/'MM'/'Y`,
                                                      {
                                                          locale: ptLocale,
                                                      }
                                                  )}`
                                        }`}</h2>
                                    )}
                                    {singleBooking?.creditStatus ===
                                        CreditStatus.FUTURE && (
                                        <h2>Bilhete em monitoramento</h2>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="title">
                            <h1 className="font-title">
                                Informações do bilhete
                            </h1>
                            <PrimaryButton
                                color="primary"
                                title="Comentários"
                                callback={() => toggleBookingComments(true)}
                            />

                            <PrimaryButton
                                color="red-white"
                                icon={canEdit ? FiSave : FiEdit}
                                title={canEdit ? 'Salvar' : 'Editar'}
                                callback={() => setCanEdit(!canEdit)}
                                isSubmit={!canEdit}
                                busy={busyBtn}
                            />
                        </div>
                        <div className="booking-infos">
                            <div className="info">
                                <h2>Passageiro</h2>
                                <h1>{singleBooking?.passengers[0]}</h1>
                            </div>
                            <div className="info">
                                <h2>Localizador</h2>
                                <h1>{singleBooking?.recordLocator}</h1>
                            </div>
                            <div className="info">
                                <h2>Classe</h2>
                                {singleBooking?.brandedFare ? (
                                    <h1>{singleBooking?.brandedFare.label}</h1>
                                ) : null}
                            </div>
                            <div className="info">
                                <h2>Empresa</h2>
                                {canEdit ? (
                                    <Input
                                        type="text"
                                        name="client"
                                        value={client}
                                        onChange={(e) =>
                                            setClient(e.target.value)
                                        }
                                    />
                                ) : (
                                    <h1>{singleBooking?.client || '-'}</h1>
                                )}
                            </div>
                            <div className="info">
                                <h2>CPF</h2>
                                {canEdit ? (
                                    <Input
                                        type="text"
                                        name="client"
                                        value={cpf}
                                        onChange={(e) =>
                                            seCpf(
                                                cpfFormatter(
                                                    e.target.value as string,
                                                    'INPUT'
                                                )
                                            )
                                        }
                                    />
                                ) : (
                                    <h1>{singleBooking?.cpf || '-'}</h1>
                                )}
                            </div>
                            <div className="info">
                                <h2>Centro de custo</h2>
                                {canEdit ? (
                                    <Input
                                        type="text"
                                        name="costCenter"
                                        value={costCenter}
                                        onChange={(e) =>
                                            setCostCenter(e.target.value)
                                        }
                                    />
                                ) : (
                                    <h1>{singleBooking?.costCenter || '-'}</h1>
                                )}
                            </div>
                            <div className="info">
                                <h2>Ordem de serviço</h2>
                                {canEdit ? (
                                    <Input
                                        type="text"
                                        name="serviceOrderNumber"
                                        value={serviceOrderNumber}
                                        onChange={(e) =>
                                            setServiceOrderNumber(
                                                e.target.value
                                            )
                                        }
                                    />
                                ) : (
                                    <h1>
                                        {singleBooking?.serviceOrderNumber ||
                                            '-'}
                                    </h1>
                                )}
                            </div>

                            <div className="info">
                                <h2>Valor pago</h2>
                                <h1>
                                    {singleBooking?.paidAmount !== null &&
                                    singleBooking?.paidAmount !== undefined
                                        ? `R$ ${
                                              singleBooking?.paidAmount.toLocaleString(
                                                  'pt-br',
                                                  {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  }
                                              ) || 0
                                          }`
                                        : 'Dados insuficientes'}
                                </h1>
                            </div>
                            <div className="info">
                                <h2>Taxa de embarque</h2>
                                <h1>
                                    {singleBooking?.travelFee !== null &&
                                    singleBooking?.travelFee !== undefined
                                        ? `R$ ${
                                              singleBooking?.travelFee.toLocaleString(
                                                  'pt-br',
                                                  {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  }
                                              ) || 0
                                          }`
                                        : 'Dados insuficientes'}
                                </h1>
                            </div>
                            <div className="info">
                                <h2>Data de emissão</h2>
                                <h1>
                                    {singleBooking &&
                                        format(
                                            new Date(singleBooking.issueDate),
                                            `dd'/'MM'/'Y`,
                                            {
                                                locale: ptLocale,
                                            }
                                        )}
                                </h1>
                            </div>
                        </div>
                        <div className="title">
                            <h1 className="font-title">
                                Informações de crédito
                            </h1>
                            {boardingStatusLabel(singleBooking?.boardingStatus)}
                        </div>
                        <div className="credit-infos">
                            <div className="credit-info">
                                <h2>Crédito para reemissão</h2>
                                <h1>
                                    {singleBooking?.reissueCredit !== null &&
                                    singleBooking?.reissueCredit !== undefined
                                        ? `R$ ${
                                              singleBooking?.reissueCredit.toLocaleString(
                                                  'pt-br',
                                                  {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  }
                                              ) || 0
                                          }`
                                        : 'Dados insuficientes'}
                                </h1>
                            </div>
                            <div className="credit-info">
                                <h2>Total reembolsável</h2>
                                <h1>
                                    {refundCredit(singleBooking?.refundCredit)}
                                </h1>
                            </div>
                            <div className="credit-info">
                                <h2>Valor pago em multas</h2>
                                <h1>
                                    {singleBooking?.totalPenalty !== null &&
                                    singleBooking?.totalPenalty !== undefined
                                        ? `R$ ${
                                              singleBooking?.totalPenalty.toLocaleString(
                                                  'pt-br',
                                                  {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  }
                                              ) || 0
                                          }`
                                        : 'Dados insuficientes'}
                                </h1>
                            </div>
                        </div>
                    </Form>
                )}
            </BookingDetailContainer>
            <BookingComments
                isOpen={showBookingComments}
                onRequestClose={() => toggleBookingComments(false)}
                singleBooking={singleBooking}
            />
        </ModalContainer>
    )
}
