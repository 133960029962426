import styled from 'styled-components'

export const ShareCredentialsContainer = styled.div`
    padding: 2rem;
    width: 70rem;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .title {
        border-bottom: 1px solid rgba(194, 194, 194, 0.31);
        padding: 0 0 1.6rem 0;
        margin-bottom: 2rem;
    }
    .description {
        p {
            line-height: 2.6rem;
            margin-bottom: 1rem;
        }
    }
    form {
        .btn {
            width: 100%;
            text-align: center;
            margin-top: 2rem;
        }
        margin-bottom: 3rem;
    }
`

export const Table = styled.div`
    overflow-y: scroll;
    max-height: 25rem;
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 0.5rem;
        background: #edeff2;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background: #96989b;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #96989b;
    }
    .primary-row {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-rows: min-content;
        padding: 1.6rem 0;
        .center {
            text-align: center;
        }
        .start {
            text-align: start;
        }
        .end {
            text-align: end;
        }
        h1 {
            font-size: 1.6rem;
            font-weight: 500;
        }
    }
`
