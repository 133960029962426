import styled from 'styled-components'

export const FinancialContent = styled.div`
    padding: 4.2rem;
    .loader {
        text-align: center;
    }
    .financials {
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 3rem;
            border-bottom: 1.5px solid rgba(194, 194, 194, 0.31);
        }
        .plan {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin-top: 3rem;
            .info {
                h1 {
                    font-size: 2.6rem;
                    font-weight: 600;
                    color: #323232;
                    margin-bottom: 1.8rem;
                }
                p {
                    font-weight: 400;
                    color: #333;
                    font-size: 1.4rem;
                }
            }
            .actions {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
            }
        }
        .payment {
            margin-top: 5rem;
            h1 {
                font-weight: 600;
                font-size: 2.2rem;
                color: #888;
                margin-bottom: 1.8rem;
            }
            .info-payment {
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                .credit-card {
                    display: flex;
                    align-items: stretch;
                    .logo {
                        margin-right: 1rem;
                        img {
                            height: 8rem;
                            width: 10rem;
                        }
                    }
                }
                .credit-card-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h2 {
                        font-weight: 600;
                        font-size: 1.6rem;
                        color: #323232;
                        margin-bottom: 1rem;
                    }
                    h3 {
                        font-weight: 400;
                        font-size: 1.4rem;
                        color: #333;
                    }
                }
            }
        }
        margin-bottom: 10rem;
    }
    .history-income {
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 3rem;
        }
    }
`

export const Table = styled.div`
    .primary-row {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr;
        grid-template-rows: min-content;
        padding: 1rem;
        border-top: 2px solid #c2c2c2;
        padding: 1.6rem 0;
        .center {
            text-align: center;
        }
        .start {
            text-align: start;
        }
        .end {
            text-align: end;
        }
        h1 {
            font-size: 1.6rem;
            font-weight: 500;
        }
    }
`
