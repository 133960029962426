import styled from 'styled-components'

export const SwitchButtonContainer = styled.button`
    position: relative;
    width: 7rem;
    height: 4rem;
    border: none;
    border-radius: 4rem;
    background-color: #d6dddc;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    outline: none;
    svg {
        width: 1.8rem;
        height: 1.8rem;
        z-index: 50;
    }
    .selector {
        position: absolute;
        right: 1px;
        width: 3.9rem;
        height: 3.8rem;
        border-radius: 4rem;
        background-color: #fff;
        transition: all 0.3s ease;
    }
    .left {
        right: 3rem;
    }
`
