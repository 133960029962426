import React from 'react'
import { useLocation } from 'react-router-dom'
import { InitialContainer } from '../../container/InitialContainer'
import ConfirmPhoto from '../../assets/confirm-email.png'
import { CheckEmailContainer } from './styles'

export function CheckEmailPage() {
    // location
    const name = useLocation().state as string

    return (
        <InitialContainer>
            <CheckEmailContainer>
                <h1 className="heading-font">Confirme seu e-mail!</h1>
                <img src={ConfirmPhoto} alt="Confirme seu email" />
                <h2 className="heading-secondary-font">
                    {name} para concluir seu cadastro, acesse o link que foi
                    enviado no seu e-mail.
                </h2>
            </CheckEmailContainer>
        </InitialContainer>
    )
}
