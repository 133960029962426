/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { SinglePlan } from '../../components/SinglePlan'
import { MarketingContainer } from '../../container/MarketingContainer'
import { ParamsRoutePlan } from '../../entitie/params-route-plan'
import { useUser } from '../../hooks/use-user'
import { PlansContainer } from './styles'
import { SinglePlanFixed } from '../../components/SinglePlanFixed'

export function PlansPage() {
    // loaction
    const params = useLocation().state as ParamsRoutePlan

    // hooks
    const { getPlans, plans, user } = useUser()

    // state
    const [busy, setBusy] = useState(false)

    useEffect(() => {
        setBusy(true)
        ;(async () => {
            await getPlans()
            setBusy(false)
        })()
    }, [])

    function position(
        type: 'AGENCY' | 'AGENCY_PRO' | 'BUSINESS' | 'ENTERPRISE' | 'FREE'
    ) {
        if (plans.length > 2) {
            if (type === 'FREE') {
                return 'left'
            }
            if (type === 'ENTERPRISE' || type === 'AGENCY_PRO') {
                return 'right'
            }
            return undefined
        }
        if (type === 'FREE') {
            return 'left'
        }
        return 'right'
    }

    return (
        <MarketingContainer
            title={`${
                !params || !params.userPlan
                    ? 'Seja bem-vindo ao Ada!'
                    : 'Alteração de plano'
            }`}
            subTitle={`${
                !params || !params.userPlan
                    ? 'Conclua seu cadastro e comece a utilizar a plataforma.'
                    : `Seu plano atual é o ${params.userPlan?.label}`
            }`}
        >
            {!busy ? (
                <>
                    <PlansContainer>
                        {plans.length > 2 && (
                            <div className="plans">
                                {plans.map((plan) => {
                                    if (user?.userType === plan.userType) {
                                        return (
                                            <SinglePlan
                                                type={
                                                    `${
                                                        plan.type ===
                                                            'BUSINESS' ||
                                                        plan.type === 'AGENCY'
                                                            ? 'black'
                                                            : 'white'
                                                    }` as 'white' | 'black'
                                                }
                                                direction={position(plan.type)}
                                                plan={plan}
                                                userPlan={
                                                    params
                                                        ? params.userPlan
                                                        : undefined
                                                }
                                            />
                                        )
                                    }
                                    return null
                                })}
                            </div>
                        )}
                        {plans.length <= 2 && (
                            <div className="plans">
                                {plans.map((plan) => {
                                    if (user?.userType === plan.userType) {
                                        return (
                                            <SinglePlanFixed
                                                type="white"
                                                direction={position(plan.type)}
                                                plan={plan}
                                                userPlan={
                                                    params
                                                        ? params.userPlan
                                                        : undefined
                                                }
                                            />
                                        )
                                    }
                                    return null
                                })}
                            </div>
                        )}
                    </PlansContainer>
                </>
            ) : (
                <ClipLoader />
            )}
        </MarketingContainer>
    )
}
