import React, { useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { FiCheck } from 'react-icons/fi'
import { Link, useLocation } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { MarketingContainer } from '../../container/MarketingContainer'
import { ConfirmPlanContainer } from './styles'
import masterCard from '../../assets/mastercard.png'
import elo from '../../assets/elo.png'
import visa from '../../assets/visa.png'
import amex from '../../assets/amex.png'
import { useUser } from '../../hooks/use-user'
import { ParamsRoutePlan } from '../../entitie/params-route-plan'

export function ConfirmPlanPage() {
    // location
    const params = useLocation().state as ParamsRoutePlan

    // hooks
    const { user, createSubscription } = useUser()

    // state
    const [busyBtn, setBusyBtn] = useState(false)

    function labelPrice(
        type:
            | 'AGENCY'
            | 'AGENCY_PRO'
            | 'BUSINESS'
            | 'ENTERPRISE'
            | 'FREE'
            | undefined,
        value: number
    ) {
        if (type === 'FREE') {
            return 'Grátis'
        }

        return `R$ ${
            value.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) || 0
        }/mês`
    }

    async function handleSubscription(id: string) {
        setBusyBtn(true)
        const response = await createSubscription(id)
        if (response) {
            setBusyBtn(false)
            window.location.reload()
            return
        }
        setBusyBtn(false)
    }

    function returnBrand() {
        if (user.creditCard?.brand === 'elo') {
            return elo
        }
        if (user.creditCard?.brand === 'visa') {
            return visa
        }
        if (user.creditCard?.brand === 'amex') {
            return amex
        }
        return masterCard
    }

    return (
        <MarketingContainer
            title="Finalizando cadastro de plano"
            subTitle="Verifique seus dados"
        >
            <ConfirmPlanContainer>
                <div className="title">
                    <h1 className="font">Informe seus dados de pagamento</h1>
                    <p>
                        Selecione a forma de pagamento que será usada para seu
                        novo plano.
                    </p>
                </div>

                <div className="grid">
                    <div className="card-term">
                        <div className="credit-card grid-container">
                            <div className="logo">
                                <img src={returnBrand()} alt="cartão logo" />
                            </div>
                            <div className="info">
                                <h2>{`${
                                    user.creditCard &&
                                    user.creditCard.brand
                                        .charAt(0)
                                        .toUpperCase() +
                                        user.creditCard.brand.slice(1)
                                } final ${
                                    user.creditCard?.lastFourDigits
                                }`}</h2>
                                <h3>{user.creditCard?.holderName}</h3>
                            </div>
                            <div className="circle">
                                <FiCheck />
                            </div>
                        </div>
                        <div className="term">
                            <p>
                                {`Ao clicar no botão "Continuar" ao lado, você
                                confirma ter mais de 18 anos e aceita que a ADA
                                Travel Tech renovará automaticamente sua
                                assinatura e cobrará o valor (atualmente
                                ${params?.plan?.type}) da sua forma de pagamento. Você pode
                                cancelar sua assinatura quando quiser para
                                evitar novas cobranças. Para cancelar, acesse
                                “Configurações”, clique em "Cobranças" e então
                                “Cancelar plano”.`}
                            </p>
                        </div>
                    </div>
                    <div className="card-plan">
                        <div className="plan grid-container">
                            <div className="info">
                                <h2>{`Plano ${params?.plan?.label}`}</h2>
                                <h3>
                                    {labelPrice(
                                        params?.plan?.type,
                                        params?.plan?.monthlyPrice as number
                                    )}
                                </h3>
                            </div>
                            <Link
                                to={{
                                    pathname: '/plans',
                                    state: {
                                        plan: undefined,
                                        userPlan: params.userPlan
                                            ? params.userPlan
                                            : undefined,
                                    },
                                }}
                            >
                                <button type="button">Trocar</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <button
                    className="confirm-btn"
                    type="button"
                    onClick={() => handleSubscription(params?.plan?.id || '')}
                >
                    {busyBtn ? <ClipLoader /> : 'Continuar'}
                </button>
            </ConfirmPlanContainer>
        </MarketingContainer>
    )
}
