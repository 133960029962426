import React, { ReactNode } from 'react'
import { ContentMarketingContainer, MarketingContainerStyle } from './styles'
import logo from '../../assets/adalogo.png'

interface Props {
    children: ReactNode
    title: string
    subTitle: string
}

export function MarketingContainer({ children, title, subTitle }: Props) {
    return (
        <MarketingContainerStyle>
            <img src={logo} alt="Logo" />
            <ContentMarketingContainer>
                <div className="title">
                    <h1>{title}</h1>
                    <p>{subTitle}</p>
                </div>
                {children}
            </ContentMarketingContainer>
        </MarketingContainerStyle>
    )
}
