import React, { useState } from 'react'
import {
    FiChevronRight,
    FiCircle,
    FiPocket,
    FiUsers,
    FiCreditCard,
} from 'react-icons/fi'
import { IconType } from 'react-icons/lib'
import { Link } from 'react-router-dom'
import { v4 } from 'uuid'
import { NavBtn, NavSelectBtn, SideBarContainer, SideBarNav } from './styles'

interface SubMenu {
    menu: string
    path: string
    class: string
}

interface SideBarMenu {
    menu: string
    icon: IconType
    path?: string
    subMenu?: SubMenu[]
    class: string
}

interface Props {
    active: string
}

export function SideMenu({ active }: Props) {
    const sideBarMenu: SideBarMenu[] = [
        {
            menu: 'Perfil',
            icon: FiUsers,
            path: '/profile',
            class: 'profile',
        },

        {
            menu: 'Credenciais',
            icon: FiPocket,
            path: '/credentials',
            class: 'credentials',
        },
        {
            menu: 'Cobranças',
            icon: FiCreditCard,
            class: 'financial',
            path: '/financial',
            // subMenu: [
            //     {
            //         menu: 'Visão geral',
            //         path: '/general-info',
            //         class: 'general-info',
            //     },
            //     {
            //         menu: 'Formas de pagamento',
            //         path: '/payment-methods',
            //         class: 'payment-methods',
            //     },
            // ],
        },
    ]

    function check(value: string, item: SideBarMenu) {
        const match = item.subMenu?.find((sub) => sub.class === value)
        if (match) {
            return true
        }
        return false
    }

    // state
    const [isToggle] = useState<boolean>(false)

    return (
        <SideBarContainer isToggle={isToggle}>
            <div className="title">
                <h3>Minha conta</h3>
            </div>
            <SideBarNav>
                {sideBarMenu.map((item) => {
                    if (item.subMenu) {
                        return (
                            <NavSelectBtn key={v4()}>
                                <input
                                    type="checkbox"
                                    name={item.menu}
                                    id={item.menu}
                                    onChange={(e) => {
                                        if (check(active, item)) {
                                            e.target.checked = true
                                        }
                                    }}
                                    defaultChecked={check(active, item)}
                                />
                                <label htmlFor={item.menu}>
                                    <div className="nav-link">
                                        <item.icon />
                                        <span className="side-bar-primary-font">
                                            {item.menu}
                                        </span>
                                        <div className="icon">
                                            <FiChevronRight size={18} />
                                        </div>
                                    </div>

                                    {item.subMenu.map((sub) => {
                                        return (
                                            <div
                                                className="select-btns"
                                                key={v4()}
                                            >
                                                <Link to={sub.path}>
                                                    <FiCircle
                                                        size={14}
                                                        color={
                                                            sub.class === active
                                                                ? '#E74B70'
                                                                : undefined
                                                        }
                                                    />
                                                    <p
                                                        className="side-bar-secondary-font"
                                                        style={
                                                            sub.class === active
                                                                ? {
                                                                      color: '#E74B70',
                                                                  }
                                                                : undefined
                                                        }
                                                    >
                                                        {sub.menu}
                                                    </p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </label>
                            </NavSelectBtn>
                        )
                    }
                    return (
                        <NavBtn key={v4()}>
                            <Link to={item.path ? item.path : '/'}>
                                <div
                                    className={`nav-link ${item.class}`}
                                    style={
                                        item.class === active
                                            ? {
                                                  backgroundColor:
                                                      'rgba(237, 243, 242, 0.45)',
                                                  borderLeft:
                                                      ' 0.5rem solid var(--tertiary)',
                                              }
                                            : undefined
                                    }
                                >
                                    <item.icon />
                                    <span className="side-bar-primary-font">
                                        {item.menu}
                                    </span>
                                    <div className="icon" />
                                </div>
                            </Link>
                        </NavBtn>
                    )
                })}
            </SideBarNav>
        </SideBarContainer>
    )
}
