import styled from 'styled-components'

export const SigleDashboardBookingContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 3fr 2fr 2fr;
    grid-template-rows: min-content;
    .center {
        text-align: center;
    }
    .start {
        text-align: start;
    }
    .end {
        text-align: end;
    }
    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            padding: 0.5rem;
            border-radius: 6px;
        }
    }
    .relative {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: #f1c523;
            height: 2rem;
            width: 2rem;
            margin-left: 1rem;
        }
    }
    h1 {
        font-size: 1.6rem;
        font-weight: 500;
        color: #333;
        padding: 1.6rem 0;
    }
    &:not(:last-child) {
        border-bottom: 1px solid rgba(194, 194, 194, 0.31);
    }
`
