import styled from 'styled-components'

export const StepsContainer = styled.div`
    min-height: 100vh;
    width: 100%;
    background-color: var(--background);
    padding-bottom: 5rem;
`

export const StepsContent = styled.div`
    margin: 4rem auto 0 auto;
    text-align: center;
    .title {
        h1 {
            font-weight: 500;
            font-size: 4rem;
        }
    }
    .steps {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .progress {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 4rem;
            .ball-label {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 22rem;
                .ball {
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 50%;
                    background-color: #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 2rem;
                    z-index: 100;
                    h1 {
                        font-weight: 700;
                        color: #fff;
                        font-size: 1.6rem;
                    }
                }
                .colored {
                    background-color: #00a4e0;
                }
                h2 {
                    font-weight: 500;
                    font-size: 1.5rem;
                }
            }
            .first-line {
                position: absolute;
                top: 1.25rem;
                left: 15%;
                width: 22rem;
                height: 1rem;
                background-color: #000;
            }
            .second-line {
                position: absolute;
                top: 1.25rem;
                left: 50%;
                width: 22rem;
                height: 1rem;
                background-color: #000;
            }
            .colored {
                background-color: #00a4e0;
            }
        }
    }
`

export const FirstStepContent = styled.div`
    width: 83rem;
    height: 55rem;
    border-radius: 2.5rem;
    background-color: #fff;
    margin: 4rem auto 0 auto;
    padding: 2.5rem;
    .step-title {
        text-align: start;
        h1 {
            font-size: 2.2rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }
    }
    .image {
        margin: 2rem 0;
    }
    .description {
        width: 40rem;
        margin: 0 auto;
    }
`

export const SecondStep = styled.div`
    width: 83rem;
    height: 55rem;
    border-radius: 2.5rem;
    background-color: #fff;
    margin: 4rem auto 0 auto;
    padding: 2.5rem;
    .second-step-title {
        text-align: start;
        width: 50rem;
        h1 {
            font-size: 2.2rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }
    }
    .image {
        position: relative;
        margin: 4rem 0;
        button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-65%, -50%);
        }
    }
`
