import styled from 'styled-components'

interface PropsContainer {
    isToggle: boolean
}

export const SideBarContainer = styled.div<PropsContainer>`
    border: 1px solid #c7d3d1;
    border-radius: 1.6rem;
    background-color: var(--light);
    overflow: hidden;
    .title {
        padding: 2.5rem;
        border-bottom: 1px solid #c7d3d1;
        h3 {
            color: #929292;
            font-size: 1.6rem;
            font-weight: 500;
        }
    }
`

export const SideBarNav = styled.nav`
    width: 100%;
`

export const NavBtn = styled.div`
    &:not(:last-child) {
        border-bottom: 1px solid #c7d3d1;
    }
    width: 100%;
    a {
        text-decoration: none;
    }
    .nav-link {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2rem 1rem;
        border: none;
        color: #333;
        transition: all 0.4s;

        svg {
            flex: 1;
        }
        .icon {
            flex: 1;
            transform: rotate(0deg);
            transition: transform 0.4s;
        }
        span {
            text-align: start;
            flex: 5;
            padding-left: 1rem;
        }
        &:hover,
        &:active {
            outline: none;
            background-color: rgba(237, 243, 242, 0.45);
            border-left: 0.5rem solid var(--tertiary);

            cursor: pointer;
        }
    }
`

export const NavSelectBtn = styled.div`
    width: 100%;
    margin-bottom: 0.5rem;
    &:not(:last-child) {
        border-bottom: 1px solid #c7d3d1;
    }

    input {
        display: none;
    }
    input[type='checkbox'] + label {
        .nav-link {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 2rem 1rem;
            border: none;
            width: 100%;
            color: #333;
            text-decoration: none;
            transition: all 0.4s;
            svg {
                flex: 1;
            }
            .icon {
                flex: 1;
                transform: rotate(0deg);
                transition: transform 0.4s;
            }
            span {
                text-align: start;
                flex: 5;
                padding-left: 1rem;
            }
            &:hover,
            &:active {
                outline: none;
                background-color: rgba(237, 243, 242, 0.45);
                cursor: pointer;
            }
        }
        .select-btns {
            opacity: 0;
            visibility: none;
            width: 0;
            transition: all 0.2s;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            margin-left: 2rem;
            height: 0rem;
            a {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 1.5rem;
                color: #333;
                text-decoration: none;
                svg {
                    margin-right: 1rem;
                }
            }
        }
    }
    input[type='checkbox']:checked + label {
        .nav-link {
            color: #333;
            background-color: rgba(237, 243, 242, 0.45);
            border-left: 0.5rem solid var(--tertiary);
            .icon {
                transform: rotate(90deg);
            }
        }
        .select-btns {
            opacity: 1;
            visibility: visible;
            width: 100%;
            height: 3rem;
        }
    }
`
