import styled, { keyframes } from 'styled-components'

const appear = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })

export const RegisterCreditCardContainer = styled.div`
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 82rem;
    border-radius: 2.6rem;
    background-color: #000;
    padding: 3rem;
    box-shadow: 0.2px 0.2px 60px #aaa;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: ${appear} 1s ease;

    .title {
        width: 45rem;
        .font {
            font-weight: 600;
            font-size: 2.4rem;
            color: #fff;
        }
        p {
            color: #fff;
            font-weight: 400;
            font-size: 1.4rem;
        }
    }
    .grid {
        margin-top: 3rem;
        display: grid;
        grid-template-columns: 6fr 5fr;
        column-gap: 5rem;
        row-gap: 2rem;
        .form {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(12, [col-start] 1fr);
            column-gap: 1rem;
            row-gap: 2.5rem;
            margin-bottom: 1.5rem;
            div {
                input {
                    margin: 0;
                }
            }
            .number {
                grid-column: col-start / col-start 13;
            }
            .validity {
                grid-column: col-start / col-start 8;
            }
            .cvv {
                grid-column: 8 / col-start 13;
            }
            .name {
                grid-column: col-start / col-start 13;
            }
        }
        .grid-container {
            margin: 0 auto;
            background-color: inherit;
            border: 1px solid #fff;
            padding: 1.5rem;
            border-radius: 8px;
            height: 6.5rem;
            width: 29.2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
                background-color: inherit;
                border: none;
                color: #fff;
            }
            .info {
                margin-left: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                h2 {
                    font-weight: 600;
                    font-size: 1.5rem;
                    color: #fff;
                    margin-bottom: 0.5rem;
                }
                h3 {
                    font-weight: 400;
                    font-size: 1.3rem;
                    color: #fff;
                }
            }
        }
        .term {
            p {
                color: rgba(255, 255, 255, 0.6);
                font-size: 1.2rem;
                font-weight: 300;
            }
        }
    }
    form {
        display: flex;
        flex-direction: column;
        .confirm-btn {
            align-self: flex-end;
            width: 20rem;
            height: 4.5rem;
            border-radius: 2.4rem;
            background-color: #e5e5e5;
            border: none;
            outline: none;
        }
    }
`
