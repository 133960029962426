import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    padding: 3rem;
    overflow: hidden;
    z-index: 30500;
`
