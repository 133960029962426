/* eslint-disable react/require-default-props */
import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { SharedUser } from '../../entitie/shared-user'
import { useCredential } from '../../hooks/use-credential'
import { SingleSharedUserContainer } from './styles'

interface Props {
    sharedUser?: SharedUser
    id?: string
}

export function SingleSharedUser({ sharedUser, id }: Props) {
    // hooks
    const { unShareCredential } = useCredential()

    return (
        <SingleSharedUserContainer>
            <h1 className="start">{sharedUser?.email}</h1>
            <h1 className="end">{sharedUser?.name}</h1>
            <button
                type="button"
                className="center remove"
                onClick={async () => {
                    if (sharedUser && id) {
                        await unShareCredential(
                            {
                                targetUserEmail: sharedUser.email,
                            },
                            id
                        )
                    }
                }}
            >
                <AiFillCloseCircle />
            </button>
        </SingleSharedUserContainer>
    )
}
