import styled, { keyframes } from 'styled-components'

const appear = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })

export const PlanInfoContainer = styled.div`
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70rem;
    border-radius: 2.6rem;
    background-color: #000;
    padding: 3rem;
    box-shadow: 0.2px 0.2px 60px #aaa;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: ${appear} 1s ease;

    .title {
        width: 45rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .font {
            font-weight: 600;
            font-size: 2.4rem;
            color: #fff;
        }
        p {
            color: #fff;
            font-weight: 400;
            font-size: 1.4rem;
        }
    }
    .grid {
        margin-top: 3rem;
        display: grid;
        grid-template-columns: 6fr 5fr;
        row-gap: 2rem;
        .feature {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 1rem;
            .circle {
                background-color: #e5e5e5;
                margin-right: 1rem;
                border-radius: 50%;
                height: 2rem;
                width: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            h3 {
                color: #fff;
            }
        }
    }
    .row {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 3rem;
        .info {
            display: flex;
            flex-direction: column;
            h1 {
                font-weight: 600;
                font-size: 2.4rem;
                color: #fff;
                margin-bottom: 1rem;
            }
            h2 {
                font-weight: 400;
                font-size: 1.6rem;
                color: #fff;
            }
        }
        .btns {
            display: flex;
            flex-direction: column;
            align-items: center;
            .confirm-btn {
                align-self: flex-end;
                width: 20rem;
                height: 4.5rem;
                border-radius: 2.4rem;
                background-color: #e5e5e5;
                border: none;
                outline: none;
                margin-top: 2rem;
            }
            .back {
                background-color: inherit;
                color: #fff;
                margin-top: 1rem;
                border: none;
            }
        }
    }
`
