/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { createContext, useContext, useState, ReactNode } from 'react'
import { startOfDay, startOfYear, subYears } from 'date-fns'
import { EditBookingDto } from '../dtos/edit-booking'
import { ImportSingleBookingDto } from '../dtos/import-single-booking'
import { Analytics } from '../entitie/analytics'
import { Booking } from '../entitie/booking'
import { FilterBookings } from '../entitie/filter-bookings'
import { Filters } from '../entitie/filters'
import { api } from '../service/api'
import { useAuth } from './use-auth'
import { useToast } from './use-toast'
import { cpfFormatter } from '../utils/formatter'

interface AppProvideProps {
    children: ReactNode
}

interface BookingContext {
    getAnalytics(filter: FilterBookings): Promise<void>
    getBookings(
        page: number,
        filter: FilterBookings,
        fullTable: boolean
    ): Promise<void>
    getSingleBooking(id: string): Promise<Booking | undefined>
    editBooking(data: EditBookingDto, id: string): Promise<void>
    addBookingComment(data: string, id: string): Promise<void>
    getFilters(): Promise<void>
    exportBookings(filter: FilterBookings): Promise<void>
    searchMultipleBookings(data: any): Promise<boolean>
    searchSingleBooking(
        data: ImportSingleBookingDto
    ): Promise<Booking | undefined>
    handleFilterBookings(filter: FilterBookings | undefined): void
    toggleBookingDetail(id?: string): void
    toggleImportBookinfg(bool: boolean, refreshType?: boolean): void
    toggleBookingComments(bool: boolean): void
    toggleLocator(bool: boolean): void
    clearExcelErrors(): void
    refresh(): void
    shouldRefresh: boolean
    showImportBooking: boolean
    showBookingDetail?: string
    showBookingComments: boolean
    filters?: Filters
    analytics?: Analytics
    bookings: Booking[]
    dashboardBookings: Booking[]
    booking?: Booking
    count?: number
    filterBookings?: FilterBookings
    singleBooking?: Booking
    isRecordLocator: boolean
    excelErrors:
        | { line: number; type: 'IATA' | 'EMPTY' | 'LENGTH' }[]
        | undefined
}

const BookingContext = createContext({} as BookingContext)

export function BookingProvider({ children }: AppProvideProps) {
    // hook
    const { token } = useAuth()
    const { addToast } = useToast()

    // state
    const [analytics, setAnalytics] = useState<Analytics>()
    const [bookings, setBookings] = useState<Booking[]>([])
    const [dashboardBookings, setDashboardBookings] = useState<Booking[]>([])
    const [booking, setBooking] = useState<Booking>()
    const [count, setCount] = useState<number>()
    const [filterBookings, setFilterBookings] = useState<
        FilterBookings | undefined
    >({
        typeOfPeriod: 'YEAR',
        period: {
            startDate: startOfDay(subYears(new Date(), 1)),
            endDate: new Date(),
        },
    })
    const [filters, setFilters] = useState<Filters>()
    const [showBookingDetail, setShowBookingDetail] = useState<
        string | undefined
    >()
    const [showImportBooking, setShowImportBooking] = useState(false)
    const [showBookingComments, setShowBookingComments] = useState(false)
    const [shouldRefresh, setShouldRefresh] = useState(false)
    const [singleBooking, setSingleBooking] = useState<Booking>()
    const [isRecordLocator, setIsRecordLocator] = useState(true)
    const [excelErrors, setExcelErrors] = useState<
        { line: number; type: 'IATA' | 'EMPTY' | 'LENGTH' }[] | undefined
    >(undefined)

    function handleFilterBookings(filter: FilterBookings | undefined) {
        if (filter) {
            setFilterBookings(filter)
        } else {
            setFilterBookings(undefined)
        }
    }

    async function getBookings(
        page: number,
        filter: FilterBookings,
        fullTable: boolean
    ) {
        try {
            const response = await api.get<{
                bookings: Booking[]
                count: number
            }>(`/bookings`, {
                headers: {
                    authorization: token,
                },
                params: {
                    limit: fullTable ? 15 : 5,
                    offset: page,
                    record_locator: filter.recordLocator
                        ? filter.recordLocator
                        : undefined,
                    ticket_number: filter.ticketNumber
                        ? filter.ticketNumber
                        : undefined,
                    passenger_name: filter.passenger
                        ? filter.passenger
                        : undefined,
                    // expired: !!filter.showExpired,
                    boarding_status: filter.boardingStatus
                        ? filter.boardingStatus
                        : undefined,
                    clients: filter.client ? filter.client : undefined,
                    airline_ids: filter.airline ? filter.airline : undefined,
                    credit_status: filter.creditStatus
                        ? filter.creditStatus
                        : undefined,
                    cost_centers: filter.costsCenters
                        ? filter.costsCenters.map((costCenter) => costCenter)
                        : undefined,
                    start_date: filter.period
                        ? filter.period.startDate
                        : undefined,
                    end_date: filter.period ? filter.period.endDate : undefined,
                    agencies: filter.agencies ? filter.agencies : undefined,
                },
            })
            if (!fullTable) {
                setDashboardBookings(response.data.bookings)
            } else {
                setBookings(response.data.bookings)
            }
            setCount(response.data.count)
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config?.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function getSingleBooking(id: string) {
        try {
            const response = await api.get(`/bookings/${id}`, {
                headers: {
                    authorization: token,
                },
            })
            setSingleBooking(response.data)
            return response.data
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return undefined
        }
    }

    async function searchMultipleBookings(data: any) {
        try {
            const formData = new FormData()
            formData.append('file', data)
            await api.post('/uploads', formData, {
                headers: {
                    authorization: token,
                },
            })
            return true
        } catch (error) {
            setExcelErrors(error.response.data.errors)
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return false
        }
    }

    async function searchSingleBooking(data: ImportSingleBookingDto) {
        try {
            const dataImport: ImportSingleBookingDto = {
                airlineIataCode: data.airlineIataCode,
                client: data.client,
                costCenter: data.costCenter,
                identifier: data.identifier?.toUpperCase(),
                serviceOrderNumber: data.serviceOrderNumber,
                cpf: data.cpf,
            }
            if (dataImport.client === '') {
                delete dataImport.client
            }
            if (dataImport.costCenter === '') {
                delete dataImport.costCenter
            }
            if (dataImport.serviceOrderNumber === '') {
                delete dataImport.serviceOrderNumber
            }
            if (dataImport.cpf === '') {
                delete dataImport.cpf
            }

            const response = await api.post<Booking>('/bookings', dataImport, {
                headers: {
                    authorization: token,
                },
            })
            addToast({
                title: 'Bilhete importado com sucesso',
                type: 'success',
            })
            setBooking(response.data)
            return response.data
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return undefined
        }
    }

    async function editBooking(data: EditBookingDto, id: string) {
        try {
            const response = await api.patch<Booking>(`/bookings/${id}`, data, {
                headers: {
                    authorization: token,
                },
            })
            setSingleBooking({
                ...singleBooking!,
                costCenter: response.data.costCenter || '',
                client: response.data.client || '',
                serviceOrderNumber: response.data.serviceOrderNumber || '',
                cpf: response.data.cpf || '',
            })
            addToast({ title: 'Bilhete editado com sucesso', type: 'success' })
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function addBookingComment(data: string, id: string) {
        try {
            const response = await api.post<{ comment: string; date: Date }>(
                `/bookings/${id}/comments`,
                { comment: data },
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            if (singleBooking) {
                setSingleBooking({
                    ...singleBooking,
                    comments: [...singleBooking?.comments, response.data],
                })
            }
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function getAnalytics(filter: FilterBookings) {
        try {
            const response = await api.get<Analytics>('/analytics/dashboard', {
                headers: {
                    authorization: token,
                },
                params: {
                    clients: filter.client ? filter.client : undefined,
                    agencies: filter.agencies ? filter.agencies : undefined,
                    cost_centers: filter.costsCenters
                        ? filter.costsCenters.map((costCenter) => costCenter)
                        : undefined,
                    start_date: filter.period
                        ? filter.period.startDate
                        : undefined,
                    end_date: filter.period ? filter.period.endDate : undefined,
                },
            })
            setAnalytics(response.data)
        } catch (error) {
            addToast({
                description:
                    error.response &&
                    error.response &&
                    error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function exportBookings(filter: FilterBookings) {
        try {
            const response = await api.get(`/bookings/xlsx`, {
                headers: {
                    authorization: token,
                },
                params: {
                    record_locator: filter.recordLocator
                        ? filter.recordLocator
                        : undefined,
                    ticket_number: filter.ticketNumber
                        ? filter.ticketNumber
                        : undefined,
                    passenger_name: filter.passenger
                        ? filter.passenger
                        : undefined,
                    // expired: !!filter.showExpired,
                    boarding_status: filter.boardingStatus
                        ? filter.boardingStatus
                        : undefined,
                    clients: filter.client ? filter.client : undefined,
                    airline_ids: filter.airline ? filter.airline : undefined,
                    credit_status: filter.creditStatus
                        ? filter.creditStatus
                        : undefined,
                    cost_centers: filter.costsCenters
                        ? filter.costsCenters.map((costCenter) => costCenter)
                        : undefined,
                    start_date: filter.period
                        ? filter.period.startDate
                        : undefined,
                    end_date: filter.period ? filter.period.endDate : undefined,
                    agencies: filter.agencies ? filter.agencies : undefined,
                },
            })
            window.open(response.data)
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function getFilters() {
        try {
            const response = await api.get<Filters>('/bookings/filters', {
                headers: {
                    authorization: token,
                },
            })
            setFilters({
                agencies: response.data.agencies
                    ? response.data.agencies.sort()
                    : [],
                clients: response.data.clients
                    ? response.data.clients.sort()
                    : [],
                costCenters: response.data.costCenters
                    ? response.data.costCenters.sort()
                    : [],
            })
        } catch (error) {
            addToast({
                description:
                    error.response &&
                    error.response &&
                    error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    function toggleBookingDetail(id?: string) {
        if (id) {
            setShowBookingDetail(id)
        } else {
            setShowBookingDetail(undefined)
        }
    }

    function toggleBookingComments(bool: boolean) {
        setShowBookingComments(bool)
    }

    function clearExcelErrors() {
        setExcelErrors(undefined)
    }

    function refresh() {
        setShouldRefresh(!shouldRefresh)
    }

    function toggleImportBookinfg(bool: boolean, refreshType?: boolean) {
        if (refreshType) {
            setShouldRefresh(refreshType)
        }
        setShowImportBooking(bool)
    }

    function toggleLocator(bool: boolean) {
        setIsRecordLocator(bool)
    }

    return (
        <BookingContext.Provider
            value={{
                getAnalytics,
                getFilters,
                getBookings,
                getSingleBooking,
                addBookingComment,
                editBooking,
                exportBookings,
                searchMultipleBookings,
                searchSingleBooking,
                handleFilterBookings,
                toggleBookingDetail,
                toggleImportBookinfg,
                toggleBookingComments,
                toggleLocator,
                clearExcelErrors,
                refresh,
                showBookingComments,
                singleBooking,
                shouldRefresh,
                showImportBooking,
                showBookingDetail,
                filterBookings,
                bookings,
                dashboardBookings,
                filters,
                booking,
                analytics,
                isRecordLocator,
                count,
                excelErrors,
            }}
        >
            {children}
        </BookingContext.Provider>
    )
}

export function useBooking(): BookingContext {
    const context = useContext(BookingContext)

    if (!context) {
        throw new Error('use auth must be within a FormatterProvider')
    }
    return context
}
