import styled, { css } from 'styled-components'
import { TooltipInput } from '../Tooltip'

interface Props {
    error: boolean
}

export const InitialInputContainer = styled.div<Props>`
    position: relative;
    input {
        font-size: 1.5rem;
        font-family: inherit;
        color: inherit;
        padding: 1.5rem 2rem;
        border-radius: 1.2rem;
        border: none;
        border-bottom: 3px solid transparent;
        width: 100%;
        display: block;
        transition: all 0.3s;
        background-color: rgba(0, 0, 0, 0.03);
        margin: 2rem 0;

        &:focus {
            outline: none;
            box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
            border-bottom: 3px solid var(--dark);
        }

        &:focus:invalid {
            border-bottom: 3px solid var(--tertiary);
        }

        &::-webkit-input-placeholder {
            color: #333;
        }
    }

    ${(props) =>
        props.error &&
        css`
            input {
                border-bottom: 3px solid var(--tertiary);
            }
        `}
`

export const Error = styled(TooltipInput)`
    position: absolute;
    top: 65%;
    right: 1rem;
    transform: translateY(-50%);
    z-index: 600;
    svg {
        margin: 0;
    }

    span {
        background: #c53030;
        color: #fff;

        &::before {
            border-color: #c53030 transparent;
        }
    }
`
