/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react'
import { IconBaseProps } from 'react-icons'
import { ClipLoader } from 'react-spinners'
import { PrimaryButtonContainer } from './styles'

interface Props {
    title: string
    isSubmit?: boolean
    color: string
    callback?(): void
    asyncCallback?(): Promise<void>
    busy?: boolean
    icon?: React.ComponentType<IconBaseProps>
}

export function PrimaryButton({
    title,
    isSubmit,
    color,
    callback,
    icon: Icon,
    busy = false,
    asyncCallback,
}: Props) {
    return (
        <PrimaryButtonContainer
            type={isSubmit ? 'submit' : 'button'}
            className="btn-font"
            color={color}
            onClick={() => {
                if (asyncCallback) asyncCallback()
                if (callback) callback()
            }}
        >
            {Icon && <Icon />}
            {busy ? (
                <ClipLoader
                    size={15}
                    color={`${color === 'red-white' ? ' #E74B70' : '#fff'}`}
                />
            ) : (
                title
            )}
        </PrimaryButtonContainer>
    )
}
