import styled from 'styled-components'

export const BookingDetailContainer = styled.div`
    padding: 2rem;
    width: 70rem;
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
            height: 6.5rem;
            margin-right: 1rem;
        }
        .title-header {
            .locator {
                display: flex;
                flex-direction: row;
                align-items: center;
                h1 {
                    font-size: 2.4rem;
                    font-weight: 600;
                    margin-right: 0.8rem;
                }
                p {
                    color: #999;
                    font-size: 1.8rem;
                }
            }
            .status {
                display: flex;
                align-items: center;
                margin-top: 0%.8rem;
                h2 {
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin-left: 1rem;
                }
                span {
                    padding: 0.3rem 0.7rem;
                    border-radius: 6px;
                }
            }
        }
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .font-title {
            font-weight: 600;
            font-size: 2rem;
            color: #000;
            margin: 4rem 0;
        }
        span {
            padding: 0.3rem 0.7rem;
            border-radius: 6px;
            font-size: 1.6rem;
        }
    }
    .booking-infos {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        .info {
            h2 {
                font-weight: 500;
                font-size: 1.8rem;
                color: #999;
            }
            h1 {
                margin-top: 0.7rem;
                font-size: 1.8rem;
            }
        }
    }
    .credit-infos {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        .credit-info {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 2rem 0.5rem;
            background-color: rgba(199, 211, 209, 0.2);
            border-radius: 1.2rem;
            height: 10rem;
            h2 {
                font-weight: 500;
                font-size: 1.6rem;
                color: #999;
                margin-bottom: 0.8rem;
            }
            h1 {
                font-weight: 500;
                font-size: 1.6rem;
            }
        }
    }
`
