import React, { ReactNode } from 'react'
import { AuthProvider } from './use-auth'
import { BookingProvider } from './use-booking'
import { CredentialProvider } from './use-credential'
import { ErrorProvider } from './use-error'
import { FormatterProvider } from './use-formatter'
import { ModalProvider } from './use-modal'
import { ToastProvider } from './use-toast'
import { UploadProvider } from './use-upload'
import { UserProvider } from './use-user'

interface AppProvideProps {
    children: ReactNode
}

export function AppProvider({ children }: AppProvideProps) {
    return (
        <ToastProvider>
            <ModalProvider>
                <ErrorProvider>
                    <FormatterProvider>
                        <AuthProvider>
                            <UserProvider>
                                <CredentialProvider>
                                    <BookingProvider>
                                        <UploadProvider>
                                            {/* <SocketProvider> */}
                                            {children}
                                            {/* </SocketProvider> */}
                                        </UploadProvider>
                                    </BookingProvider>
                                </CredentialProvider>
                            </UserProvider>
                        </AuthProvider>
                    </FormatterProvider>
                </ErrorProvider>
            </ModalProvider>
        </ToastProvider>
    )
}
