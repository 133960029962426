import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import React, { useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button } from '../../components/Button'
import { InitialInput } from '../../components/InitialInput'
import { InitialContainer } from '../../container/InitialContainer'
import { useAuth } from '../../hooks/use-auth'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { ForgotPaswordContainer } from './styles'

export function ForgotPasswordPage() {
    // refs
    const formRef = useRef<FormHandles>(null)

    // hooks
    const { sendResetPasswordEmail } = useAuth()

    // state
    const [busyBtn, setBusyBtn] = useState<boolean>(false)
    const [redirect, setRedirect] = useState<boolean>(false)

    async function handleForgotPassword(data: { email: string }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                email: Yup.string()
                    .email('Formato inválido')
                    .required('Insira um email'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            await sendResetPasswordEmail(data)
            setBusyBtn(false)
            setRedirect(true)
        } catch (error) {
            setBusyBtn(false)
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <InitialContainer>
            <ForgotPaswordContainer>
                <h1 className="heading-font">Esqueci minha senha.</h1>
                <Form ref={formRef} onSubmit={handleForgotPassword}>
                    <InitialInput name="email" labelTitle="E-mail" />
                    <div className="forgot-btn">
                        <Button
                            title="Enviar"
                            isSubmit
                            color="primary"
                            busy={busyBtn}
                        />
                    </div>
                </Form>
            </ForgotPaswordContainer>
            {redirect && <Redirect to={{ pathname: '/', state: '' }} />}
        </InitialContainer>
    )
}
