import React from 'react'
import { useTransition } from 'react-spring'
import { ToastMessage } from '../../hooks/use-toast'
import { Container } from './styles'
import { Toast } from './Toast'

interface Props {
    messages: ToastMessage[]
}

export function ToastContainer({ messages }: Props) {
    const messagesWithTransitions = useTransition(
        messages,
        (message: ToastMessage) => message.id,
        {
            from: { right: '-120%', opacity: 0 },
            enter: { right: '0%', opacity: 1 },
            leave: { right: '-120%', opacity: 0 },
        }
    )

    return (
        <Container>
            {messagesWithTransitions.map(({ item, key, props }) => (
                <Toast key={key} style={props} message={item} />
            ))}
        </Container>
    )
}

export default ToastContainer
