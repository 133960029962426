import React from 'react'
import ModalContainer from 'react-modal'
import { PrivacyPoliciesContainer } from './styles'

interface Props {
    isPrivacyPolicie: boolean
    isOpen: boolean
    onRequestClose: () => void
}

export function PrivacyPolicies({
    isPrivacyPolicie,
    onRequestClose,
    isOpen,
}: Props) {
    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <PrivacyPoliciesContainer>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <div className="content">
                    <h1>
                        {isPrivacyPolicie
                            ? 'Politicas de privacidade'
                            : 'TERMOS E CONDIÇÕES DE USO DA PLATAFORMA DE GESTÃO DE BILHETES ADA TRAVEL TECH'}
                    </h1>
                    {isPrivacyPolicie ? (
                        <div className="privacy">
                            <p>
                                Como parte integrante dos Termos e Condições de
                                Uso da plataforma ADA TRAVEL TECH, este
                                documento, denominado Política de Privacidade,
                                tem por finalidade estabelecer as regras sobre
                                obtenção, uso e armazenamento de dados e
                                informações coletadas dos visitantes e usuários,
                                além do registro de suas atividades.
                            </p>
                            <br />
                            <p>
                                Para fins desta Política de Privacidade,
                                devem-se considerar as seguintes definições e
                                descrições para seu melhor entendimento:
                            </p>
                            <br />
                            <p>
                                <span>Cookies:</span> Arquivos enviados pelo
                                servidor da plataforma para o computador dos
                                visitantes e usuários, com finalidade de
                                identificar o computador e obter dados de
                                acesso, como páginas navegadas ou links
                                clicados, permitindo, dessa forma, personalizar
                                a utilização da plataforma pelos visitantes e
                                usuários, de acordo com seu perfil.
                            </p>
                            <br />
                            <p>
                                <span>Dado pessoal:</span> é a informação
                                relacionada à pessoa natural identificada ou
                                identificável, ou seja, qualquer informação que
                                permita identificar, direta ou indiretamente, um
                                indivíduo é considerada um dado pessoal.
                            </p>
                            <br />
                            <p>
                                <span>Empresa:</span> Denominação utilizada
                                nestes Termos e Condições de Uso para
                                identificar a empresa TRIPSERVICE – AGÊNCIA DE
                                VIAGENS E TURISMO LTDA., que é a detentora e
                                proprietária da plataforma ADA TRAVEL TECH.
                            </p>
                            <br />
                            <p>
                                <span>IP:</span> Abreviatura para Internet
                                Protocol. É um conjunto de números que
                                identifica o computador dos visitantes e
                                usuários na Internet.
                            </p>
                            <br />
                            <p>
                                <span>Logs:</span> Registros de atividades dos
                                visitantes e usuários efetuadas na plataforma.
                            </p>
                            <br />
                            <p>
                                <span>Plataforma:</span> Designa o software de
                                gestão de bilhetes ADA TRAVEL TECH.
                            </p>
                            <br />
                            <p>
                                <span>Usuário:</span> Visitante que acessa a
                                área restrita da plataforma.
                            </p>
                            <br />
                            <p>
                                <span>Visitante:</span> Qualquer pessoa que
                                navegar pelo site da plataforma.
                            </p>
                            <br />
                            <ul className="titles">
                                <li className="title">
                                    1. Consentimento para o tratamento de dados
                                    e informações
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        1.1 Os visitantes e usuários consentem
                                        expressamente o tratamento de seus dados
                                        e informações pessoais pela Empresa
                                        TRIPSERVICE – AGÊNCIA DE VIAGENS E
                                        TURISMO LTDA., CNPJ nº
                                        01.261.714/0001-16, com sede na Rua
                                        Camboriú, nº 79, Centro, Itajaí – SC,
                                        CEP 88301-450.
                                    </li>
                                    <li>
                                        1.2 O visitante ou usuário, ao clicar no
                                        campo “aceito os termos e condições de
                                        uso e a política de privacidade”,
                                        manifesta seu consentimento para o
                                        tratamento de seus dados e informações
                                        pessoais.
                                    </li>
                                    <li>
                                        1.3 A aceitação do presente instrumento
                                        é imprescindível para o acesso e para a
                                        utilização de quaisquer serviços
                                        fornecidos pela Empresa. Caso não
                                        concorde com as disposições deste
                                        instrumento, o usuário não deve
                                        utilizá-los e não terá acesso à
                                        plataforma/sistema.
                                    </li>
                                </ul>
                                <li className="title">
                                    2. Obtenção de dados e informações
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        2.1 Os dados e informações serão obtidos
                                        quando os visitantes e usuários:
                                    </li>
                                    <ul className="subsubtitless">
                                        <li>
                                            2.1.1 Realizarem seus cadastros com
                                            o preenchimento das informações
                                            necessárias.
                                        </li>
                                        <li>
                                            2.1.2 Interagirem com as diversas
                                            funcionalidades existentes na
                                            plataforma, fornecendo as
                                            informações voluntariamente.
                                        </li>
                                        <li>
                                            2.1.3 Adquirirem alguns dos produtos
                                            ou serviços oferecidos.
                                        </li>
                                    </ul>
                                </ul>
                                <li className="title">
                                    3. Armazenamento dos dados e informações
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        3.1 Todos os dados e informações
                                        coletados dos visitantes e usuários
                                        serão incorporados ao banco de dados da
                                        plataforma, sendo seu responsável a
                                        TRIPSERVICE – AGÊNCIA DE VIAGENS E
                                        TURISMO LTDA., CNPJ nº
                                        01.261.714/0001-16, com sede na Rua
                                        Camboriú, nº 79, Centro, Itajaí – SC,
                                        CEP 88301450.
                                    </li>
                                    <li>
                                        3.2 Os dados e informações coletados
                                        estarão armazenados em ambiente seguro,
                                        observado o estado da técnica
                                        disponível, e somente poderão ser
                                        acessados por pessoas qualificadas e
                                        autorizadas pela Empresa.
                                    </li>
                                    <li>
                                        3.3 Considerando que nenhum sistema de
                                        segurança é absolutamente seguro, a
                                        Empresa se exime de quaisquer
                                        responsabilidades por eventuais danos
                                        e/prejuízos decorrentes de falhas, vírus
                                        ou invasões do banco de dados da
                                        plataforma, salvo nos casos de dolo e
                                        culpa da Empresa.
                                    </li>
                                </ul>
                                <li className="title">
                                    4. Uso dos dados e informações
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        4.1 Os dados coletados do visitante e
                                        usuário, por seu livre e expresso
                                        consentimento, poderão ser utilizados
                                        para as seguintes finalidades:
                                    </li>
                                    <ul className="subsubtitles">
                                        <li>
                                            4.1.1 Responder eventuais dúvidas e
                                            solicitações dos visitantes e
                                            usuários.
                                        </li>
                                        <li>
                                            4.1.2 Cumprimento de ordem legal ou
                                            judicial.{' '}
                                        </li>
                                        <li>
                                            4.1.3 Construir, defender ou exercer
                                            regularmente direitos em âmbito
                                            judicial ou administrativo.
                                        </li>
                                        <li>
                                            4.1.4 Elaborar estatísticas gerais,
                                            para identificação do perfil dos
                                            visitantes e usuários no
                                            desenvolvimento de campanhas da
                                            Empresa.
                                        </li>
                                        <li>
                                            4.1.5 Implementar melhorias na
                                            plataforma.{' '}
                                        </li>
                                        <li>
                                            4.1.6 Garantir a segurança dos
                                            visitantes e usuários.{' '}
                                        </li>
                                        <li>
                                            4.1.7 Manter atualizados os
                                            cadastros dos visitantes e usuários,
                                            para fins de contato por telefone,
                                            correio eletrônico, SMS,
                                            mala-direta, WhatsApp ou por outros
                                            meios de comunicação.
                                        </li>
                                    </ul>
                                    <li>
                                        4.2 A Empresa poderá divulgar e
                                        compartilhar os dados pessoais coletados
                                        a terceiros nas seguintes situações e
                                        nos limites exigidos e autorizados por
                                        lei:
                                    </li>
                                    <ul className="subsubtitles">
                                        <li>
                                            4.2.1 Com fornecedores e parceiros
                                            para consecução dos serviços
                                            contratados na plataforma.
                                        </li>
                                        <li>4.2.2 Com empresas do grupo.</li>
                                        <li>
                                            4.2.3 Com os seus clientes e
                                            parceiros quando necessário e/ou
                                            apropriado à prestação de serviços
                                            relacionados.
                                        </li>
                                        <li>
                                            4.2.4 Com as empresas e indivíduos
                                            contratados para a execução de
                                            determinadas atividades e serviços
                                            em nome da Empresa.
                                        </li>
                                        <li>
                                            4.2.5 Para propósitos
                                            administrativos como: pesquisa,
                                            planejamento, desenvolvimento de
                                            serviços, segurança e gerenciamento
                                            de risco.
                                        </li>
                                    </ul>
                                    <li>
                                        4.3 Nas hipóteses de compartilhamento de
                                        Dados Pessoais com terceiros, todos os
                                        sujeitos mencionados nos itens 4.2.1. a
                                        4.2.5. deverão utilizar os Dados
                                        Pessoais partilhados de maneira
                                        consistente e de acordo com os
                                        propósitos para os quais foram coletados
                                        (ou com os quais o Usuário consentiu
                                        previamente) e de acordo com o que foi
                                        determinado por esta Política de
                                        Privacidade, outras declarações de
                                        privacidade de website ou países, e
                                        todas as leis de privacidade e proteção
                                        de dados aplicáveis.
                                    </li>
                                    <li>
                                        4.4 A base de dados formada pela Empresa
                                        mediante a coleta dos dados da
                                        plataforma não será vendida, cedida,
                                        transferida, informada ou alugada a
                                        terceiros.
                                    </li>
                                    <li>
                                        4.5 Os dados adquiridos somente poderão
                                        ser acessados por profissionais
                                        devidamente autorizados pela Empresa,
                                        respeitando a necessidade a que serão
                                        submetidos, a relevância para os
                                        objetivos da plataforma e aos interesses
                                        dos visitantes e usuários, além de
                                        preservar a privacidade destes.
                                    </li>
                                    <li>
                                        4.6 A Empresa retém todos os dados
                                        fornecidos, inclusive os Dados Pessoais,
                                        enquanto o cadastro do usuário estiver
                                        ativo e conforme seja necessário para
                                        consecução de seus serviços.
                                    </li>
                                    <li>
                                        4.7 Caso o usuário deixar de utilizar os
                                        serviços oferecidos pela plataforma, a
                                        Empresa poderá, para fins de auditoria e
                                        preservação de direitos, permanecer com
                                        o registro de dados e informações do
                                        usuário, pelo período máximo de 5
                                        (cinco) anos, com a faculdade de
                                        excluí-los definitivamente segundo sua
                                        conveniência.
                                    </li>
                                    <li>
                                        4.8 A Empresa poderá vir a manter os
                                        Dados Pessoais após receber seu pedido
                                        de exclusão ou após o prazo do item 4.7.
                                        caso seja necessário para cumprimento de
                                        obrigações legais, resolver disputas,
                                        manter a segurança, evitar fraudes e
                                        abuso e garantir o cumprimento de
                                        contratos.
                                    </li>
                                </ul>
                                <li className="title">
                                    5. Registro de atividades
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        5.1 A Empresa registrará todas as
                                        atividades efetuadas pelos visitantes e
                                        usuários na plataforma, por meio de
                                        logs, incluindo:
                                    </li>
                                    <ul className="subsubtitles">
                                        <li>5.1.1 Endereço de IP.</li>
                                        <li>
                                            5.1.2 Endereço de IPAções efetuadas.
                                        </li>
                                        <li>5.1.3 Páginas acessadas. </li>
                                        <li>
                                            5.1.4 Datas e horários de cada ação
                                            ou acesso.{' '}
                                        </li>
                                        <li>5.1.5 Session ID.</li>
                                    </ul>
                                    <li>
                                        5.2 Os registros mencionados no item 5.1
                                        poderão ser utilizados pela Empresa em
                                        casos de investigação de fraudes ou de
                                        alterações indevidas em seus sistemas e
                                        cadastros.
                                    </li>
                                </ul>
                                <li className="title">6. Cookies</li>
                                <ul className="subtitle">
                                    <li>
                                        6.1 A plataforma poderá fazer uso de
                                        cookies, cabendo aos visitantes e
                                        usuários configurar os seus navegadores
                                        de Internet, caso desejem bloqueá-los.
                                        Nessa hipótese, algumas funcionalidades
                                        da plataforma poderão ser limitadas.
                                    </li>
                                    <li>
                                        6.2 O uso de cookies na plataforma tem
                                        as seguintes finalidades:
                                    </li>
                                    <ul className="subsubtitles">
                                        <li>
                                            6.2.1 Tratamento de problemas
                                            técnicos.
                                        </li>
                                        <li>
                                            6.2.2 Manutenção da proteção e
                                            segurança.
                                        </li>
                                        <li>
                                            Compreender como são utilizados a
                                            plataforma e seus serviços
                                            correspondentes.
                                        </li>
                                        <li>
                                            Adaptar o conteúdo conforme os dados
                                            de utilização.
                                        </li>
                                    </ul>
                                    <li>
                                        6.3 Para atingir essas finalidades, a
                                        plataforma usa os seguintes tipos de
                                        cookies:
                                    </li>
                                    <ul className="subsubtitles">
                                        <li>
                                            6.3.1 Cookies de sessão: são cookies
                                            de uso temporário, que são excluídos
                                            no momento em que o usuário fecha o
                                            seu navegador.
                                        </li>
                                        <li>
                                            6.3.2 Cookies persistentes: são
                                            aqueles cookies que permanecem no
                                            seu navegador até você deletá-los
                                            manualmente ou até o seu navegador
                                            deletá-los de acordo com o período
                                            de duração estabelecido pelo cookie.
                                        </li>
                                        <li>
                                            6.3.3 Cookies necessários: são
                                            cookies estritamente necessários
                                            para a operação de um site ou
                                            serviço de internet. Eles permitem
                                            que o usuário navegue pelo site e
                                            use todos os recursos necessários
                                            para atendimento da operação.
                                        </li>
                                    </ul>
                                </ul>
                                <li className="title">
                                    7. Direitos do visitante e usuário
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        7.1 São direitos do visitante e usuário:{' '}
                                    </li>
                                    <ul className="subsubtitles">
                                        <li>7.1.1 O acesso aos dados.</li>
                                        <li>
                                            7.1.2 A correção de dados
                                            incompletos, inexatos ou
                                            desatualizados.
                                        </li>
                                        <li>
                                            7.1.3 O bloqueio ou eliminação de
                                            dados desnecessários, excessivos ou
                                            tratados em desconformidade.
                                        </li>
                                        <li>
                                            7.1.4 A eliminação dos dados
                                            tratados com consentimento do
                                            Usuário.
                                        </li>
                                        <li>
                                            7.1.5 A obtenção de informações
                                            sobre as entidades públicas ou
                                            privadas com as quais a Empresa
                                            compartilhou seus dados.
                                        </li>
                                        <li>
                                            7.1.6 A informação sobre a
                                            possibilidade de não fornecer o seu
                                            consentimento, bem como de ser
                                            informado sobre as consequências, em
                                            caso de negativa.
                                        </li>
                                        <li>
                                            7.1.7 A revogação do consentimento.
                                        </li>
                                    </ul>
                                    <li>
                                        7.2 Parte desses direitos poderá ser
                                        exercida diretamente pelo Usuário, a
                                        partir da gestão de informações sobre
                                        sua conta, enquanto outros dependerão do
                                        envio de solicitação para posterior
                                        avaliação e adoção de demais
                                        providências pela Empresa.
                                    </li>
                                </ul>
                                <li className="title">8. Disposições gerais</li>
                                <ul className="subtitle">
                                    <li>
                                        8.1. As disposições constantes desta
                                        Política de Privacidade poderão ser
                                        atualizadas ou modificadas a qualquer
                                        momento, cabendo aos visitantes e
                                        usuários verificarem-nas sempre que
                                        efetuar o acesso à plataforma.
                                    </li>
                                    <li>
                                        8.2. O visitante deverá entrar em
                                        contato em caso de dúvida com relação às
                                        disposições constantes desta Política de
                                        Privacidade pelo e-mail
                                        support@adatravel.com.br ou telefone
                                        (47) 21038468.
                                    </li>
                                </ul>
                                <li className="title">
                                    9. Lei aplicável e jurisdição
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        9.1 A presente Política de Privacidade
                                        será interpretada segundo a legislação
                                        brasileira, em especial a Lei Geral de
                                        Proteção de Dados (Lei nº 13.709/2018),
                                        no idioma português, sendo eleito o foro
                                        da Comarca de Itajaí – SC, para dirimir
                                        qualquer litígio, questão ou dúvida
                                        superveniente, com expressa renúncia de
                                        qualquer outro, por mais privilegiado
                                        que seja.
                                    </li>
                                </ul>
                                <li className="title">10. Registro</li>
                                <ul className="subtitle">
                                    <li>
                                        10.1 Este documento encontra-se
                                        registrado no Ofício de Registro Civil
                                        das Pessoas Naturais, Interdições e
                                        Tutelas, das Pessoas Jurídicas e de
                                        Títulos e Documentos da Comarca de
                                        Itajaí – SC.
                                    </li>
                                </ul>
                            </ul>
                            <br />
                            <p>
                                25 de março de 2021 TRIPSERVICE – AGÊNCIA DE
                                VIAGENS E TURISMO LTDA.
                            </p>
                        </div>
                    ) : (
                        <div className="terms">
                            <p>
                                A TRIPSERVICE – AGÊNCIA DE VIAGENS E TURISMO
                                LTDA., inscrita no CNPJ sob o nº
                                01.261.714/0001-16, é titular da propriedade
                                intelectual sobre software, website,
                                aplicativos, conteúdos e demais ativos
                                relacionados à plataforma de gestão de bilhetes
                                da ADA TRAVEL TECH.
                            </p>
                            <br />
                            <p>
                                A Empresa acima identificada apresenta os Termos
                                e Condições de Uso da Plataforma ADA TRAVEL
                                TECH, que deve ser respeitado pelos visitantes e
                                usuários, sob pena de sofrer todas as
                                implicações da legislação em vigor.
                            </p>
                            <br />
                            <p>
                                Para fins destes Termos e Condições de Uso,
                                devem-se considerar as seguintes definições e
                                descrições para seu melhor entendimento:
                            </p>
                            <br />
                            <p>
                                <span>Antispam:</span> Sistema que evita
                                correspondências não desejadas, como publicidade
                                em massa, pelo bloqueio de mensagens ou
                                movendo-as para pasta específica.
                            </p>
                            <br />
                            <p>
                                <span>Aplicativo spider:</span> Programa
                                desenvolvido para obter informações de modo
                                automatizado na Internet, navegando pela web
                                (teia) como se fosse uma spider (aranha).
                            </p>
                            <br />
                            <p>
                                <span>Conta de acesso:</span> Credencial de um
                                usuário cadastrado que permite o acesso à área
                                restrita e às funcionalidades exclusivas, que é
                                definida por nome de usuário (login) e senha.
                            </p>
                            <br />
                            <p>
                                <span>Dados cadastrais:</span> Conjunto de
                                informações pessoais de um usuário que o
                                identifique, diferenciando-o dos demais, a
                                exemplo de documento de identidade, cadastro de
                                pessoa física (CPF) ou jurídica (CNPJ), endereço
                                residencial ou comercial, sede, nome completo,
                                entre outros.
                            </p>
                            <br />
                            <p>
                                <span>Empresa:</span> Denominação utilizada
                                nestes Termos e Condições de Uso para
                                identificar a empresa TRIPSERVICE – AGÊNCIA DE
                                VIAGENS E TURISMO LTDA., que é a detentora e
                                proprietária da Plataforma ADA TRAVEL TECH.
                            </p>
                            <br />
                            <p>
                                <span>Link:</span> Terminologia para endereço de
                                Internet.
                            </p>
                            <br />
                            <p>
                                <span>Login:</span> Nome de usuário escolhido
                                pelo visitante quando preenche o cadastro para
                                acesso a funcionalidades exclusivas da
                                plataforma.
                            </p>
                            <br />
                            <p>
                                <span>Plataforma:</span> Designa o software de
                                gestão de bilhetes ADA TRAVEL TECH.
                            </p>
                            <br />
                            <p>
                                <span>Senha:</span> Conjunto de caracteres que
                                serve como prova de identidade digital do
                                usuário, cujo conhecimento deve ser exclusivo e
                                único.
                            </p>
                            <br />
                            <p>
                                <span>Site:</span> Denominação para página de
                                Internet.
                            </p>
                            <br />
                            <p>
                                <span>Usuário:</span> Visitante que acessa a
                                área restrita da plataforma.
                            </p>
                            <br />
                            <p>
                                <span>Visitante:</span> Qualquer pessoa que
                                navegar pelo site da plataforma.
                            </p>
                            <br />
                            <ul className="titles">
                                <li className="title">1. Objeto</li>
                                <ul className="subtitle">
                                    <li>
                                        1.1 A plataforma visa licenciar o uso de
                                        seu software, website, aplicativos e
                                        demais ativos de propriedade
                                        intelectual, fornecendo ferramentas para
                                        auxiliar e dinamizar o dia a dia dos
                                        seus usuários.
                                    </li>
                                    <li>
                                        1.2 A plataforma caracteriza-se pela
                                        prestação do seguinte serviço: Consulta
                                        de informações pertencentes a bilhetes
                                        de passagens aéreas.
                                    </li>
                                </ul>
                                <li className="title">2. Aceitação</li>
                                <ul className="subtitle">
                                    <li>
                                        2.1 O presente Termo estabelece
                                        obrigações contratadas de livre e
                                        espontânea vontade, por tempo
                                        indeterminado, entre a plataforma e seus
                                        usuários, pessoas físicas ou jurídicas.
                                    </li>
                                    <li>
                                        2.2 Ao utilizar a plataforma, o usuário
                                        aceita integralmente as presentes normas
                                        e compromete-se a observá-las, sob o
                                        risco de aplicação das penalidades
                                        previstas nestes Termos e Condições de
                                        Uso.
                                    </li>
                                    <li>
                                        2.3 A aceitação do presente instrumento
                                        é imprescindível para o acesso e para a
                                        utilização de quaisquer serviços
                                        fornecidos pela Empresa. Caso não
                                        concorde com as disposições deste
                                        instrumento, o usuário não deve
                                        utilizá-los e não terá acesso à
                                        plataforma/sistema.
                                    </li>
                                    <li>
                                        2.4 O usuário, ao clicar no campo “Li e
                                        aceito os termos e condições de uso e a
                                        política de privacidade”, manifesta a
                                        sua vontade de contratar os serviços
                                        oferecidos pela plataforma, bem como
                                        declara que leu os presentes Termos e
                                        Condições de Uso e a política de
                                        privacidade e os aceitou integralmente.
                                        A alegação de desconhecimento não
                                        eximirá o usuário de eventuais
                                        responsabilidades durante a utilização
                                        da plataforma.
                                    </li>
                                    <li>
                                        2.5 O usuário, ao aceitar os Termos e
                                        Política de Privacidade, autoriza
                                        expressamente a plataforma a coletar,
                                        usar, armazenar, tratar, ceder ou
                                        utilizar as informações derivadas do uso
                                        dos serviços, do site e quaisquer
                                        plataformas, incluindo todas as
                                        informações preenchidas pelo usuário no
                                        momento em que realizar ou atualizar seu
                                        cadastro, além de outras expressamente
                                        descritas na Política de Privacidade que
                                        deverá ser autorizada pelo usuário.
                                    </li>
                                </ul>
                                <li className="title">
                                    3. Acesso e restrições de funcionalidade
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        3.1 Serão utilizadas todas as soluções
                                        técnicas à disposição do responsável
                                        pela plataforma para permitir o acesso
                                        ao serviço 24 (vinte e quatro) horas por
                                        dia, 7 (sete) dias por semana. No
                                        entanto, a navegação na plataforma ou em
                                        alguma de suas páginas poderá ser
                                        interrompida, limitada ou suspensa para
                                        atualizações, modificações ou qualquer
                                        ação necessária ao seu bom
                                        funcionamento, sem prévia comunicação
                                        aos usuários.
                                    </li>
                                    <li>
                                        3.2 Eventual interrupção, limitação ou
                                        suspensão, que venha a causar
                                        indisponibilidade da plataforma, não
                                        servirá de motivo justo para
                                        indenizações ou ressarcimentos de
                                        qualquer natureza aos visitantes e
                                        usuários.
                                    </li>
                                    <li>
                                        3.3 A Empresa não se responsabiliza por
                                        qualquer dano, prejuízo o u perda no
                                        equipamento dos visitantes e usuários
                                        causados por falhas no sistema, no
                                        servidor ou na conexão à Internet, ainda
                                        que decorrentes de condutas de
                                        terceiros, inclusive por ações de
                                        softwares maliciosos, tais como vírus,
                                        cavalos de tróia e outros que possam
                                        danificar o equipamento ou a conexão
                                        destes em decorrência do acesso,
                                        utilização ou navegação nesta
                                        plataforma, bem como a transferência de
                                        dados, arquivos, imagens, textos,
                                        áudios, ou vídeos contidos na
                                        plataforma.
                                    </li>
                                    <li>
                                        3.4 Os visitantes e usuários não possuem
                                        qualquer direito para exigir a
                                        disponibilidade da plataforma conforme
                                        melhor lhes convém.
                                    </li>
                                    <li>
                                        3.5 Não é permitido aos visitantes e
                                        usuários acessar as áreas restritas da
                                        plataforma, tais como áreas de
                                        administrador e de programador, e ao seu
                                        banco de dados, ficando sujeito quem o
                                        fizer à legislação penal brasileira e
                                        obrigado a reparar os danos que causa.
                                    </li>
                                    <li>
                                        3.6 Também não é autorizado a realizar
                                        ou permitir engenharia reversa, quebra
                                        da proteção do código fonte, nem
                                        traduzir, decompilar, copiar, modificar,
                                        reproduzir, alugar, sublicenciar,
                                        publicar, divulgar, transmitir,
                                        emprestar, distribuir ou de outra
                                        maneira, dispor das ferramentas da
                                        plataforma e de suas funcionalidades.
                                    </li>
                                    <li>
                                        3.7 É proibida a utilização de
                                        aplicativos spider ou de mineração de
                                        dados, de qualquer tipo ou espécie, além
                                        de outro aqui não tipificado, mas que
                                        atue como um robô, tanto para realizar
                                        operações massificadas ou para quaisquer
                                        outras finalidades, sob pena de
                                        aplicação da legislação penal brasileira
                                        e de reparar os danos que decorrerem
                                        dessa utilização.
                                    </li>
                                    <li>
                                        3.8 É ônus dos visitantes e usuários
                                        arcar com os meios necessários para
                                        utilizar desta plataforma.
                                    </li>
                                </ul>
                                <li className="title">
                                    4. Cadastro de visitantes e usuários
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        4.1 O acesso às funcionalidades da
                                        plataforma exigirá a realização de um
                                        cadastro prévio e, a depender dos
                                        serviços ou produtos escolhidos, do
                                        pagamento do valor apresentado na hora
                                        em que o usuário realizar a compra.
                                    </li>
                                    <li>
                                        4.2 Ao se cadastrar, o usuário deverá
                                        informar dados completos, recentes e
                                        válidos, comprometendo-se com a
                                        veracidade dos dados fornecidos, sendo
                                        de sua exclusiva responsabilidade manter
                                        referidos dados atualizados, sob pena de
                                        responder civil e criminalmente, além de
                                        arcar com os danos ocasionados pela
                                        utilização de dados incorretos, ou de
                                        terceiros, independentemente da
                                        existência de culpa.
                                    </li>
                                    <li>
                                        4.3 O usuário se compromete a não
                                        informar seus dados cadastrais e/ou de
                                        acesso à plataforma a terceiros,
                                        responsabilizando-se integralmente pelo
                                        uso que deles seja feito.
                                    </li>
                                    <li>
                                        4.4 Menores de 18 anos e aqueles que não
                                        possuírem plena capacidade civil deverão
                                        obter previamente o consentimento
                                        expresso de seus responsáveis legais
                                        para utilização da plataforma e dos
                                        serviços ou produtos, sendo de
                                        responsabilidade exclusiva dos
                                        responsáveis legais o eventual acesso
                                        por menores de idade e por aqueles que
                                        não possuem plena capacidade civil sem a
                                        prévia autorização.
                                    </li>
                                    <li>
                                        4.5 Mediante a realização do cadastro, o
                                        usuário declara e garante expressamente
                                        ser plenamente capaz, podendo exercer e
                                        usufruir livremente dos serviços e
                                        produtos.
                                    </li>
                                    <li>
                                        4.6 O usuário deverá fornecer um
                                        endereço de e-mail válido, através do
                                        qual o site realizará todas as
                                        comunicações necessárias.
                                    </li>
                                    <li>
                                        4.7 Após a confirmação do cadastro, o
                                        usuário possuirá um login e uma senha
                                        pessoal e intransferível, a qual
                                        assegura ao usuário o acesso individual
                                        à plataforma. Dessa forma, compete ao
                                        usuário exclusivamente a manutenção de
                                        referida senha de maneira confidencial e
                                        segura, evitando o acesso indevido às
                                        informações pessoais.
                                    </li>
                                    <li>
                                        4.8 Toda e qualquer atividade realizada
                                        com o uso da senha será de
                                        responsabilidade do usuário, que deverá
                                        informar prontamente a plataforma em
                                        caso de uso indevido da respectiva
                                        senha.
                                    </li>
                                    <li>
                                        4.9 Não será permitido ceder, vender,
                                        alugar ou transferir, de qualquer forma,
                                        a conta, que é pessoal e intransferível.
                                    </li>
                                    <li>
                                        4.10 O usuário poderá, a qualquer tempo,
                                        requerer o cancelamento de seu cadastro
                                        junto ao site. O descadastramento será
                                        realizado o mais rapidamente possível,
                                        desde que não sejam verificados débitos
                                        em aberto.
                                    </li>
                                    <li>
                                        4.11 Sempre que entender conveniente, o
                                        usuário poderá alterar sua senha e
                                        alguns dos dados de seu cadastro por
                                        meio de recurso próprio da plataforma.
                                    </li>
                                </ul>
                                <li className="title">
                                    5. Serviços e produtos
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        5.1 A plataforma poderá disponibilizar
                                        para o usuário um conjunto específico de
                                        funcionalidades e ferramentas para
                                        otimizar o uso dos serviços e produtos.
                                    </li>
                                    <li>
                                        5.2 Antes de finalizar a compra sobre
                                        determinado produto ou serviço, o
                                        usuário deverá se informar sobre as suas
                                        especificações e sobre a sua destinação.
                                    </li>
                                    <li>
                                        5.3 A entrega de serviços ou produtos
                                        adquiridos na plataforma será realizada
                                        mediante confirmação da compra por meio
                                        da própria plataforma.
                                    </li>
                                </ul>
                                <li className="title">6. Preços</li>
                                <ul className="subtitle">
                                    <li>
                                        6.1 A plataforma se reserva no direito
                                        de reajustar unilateralmente, a qualquer
                                        tempo, os valores dos serviços ou
                                        produtos sem consulta ou anuência prévia
                                        do usuário.
                                    </li>
                                    <li>
                                        6.2 Os valores aplicados são aqueles que
                                        em vigor no momento do pedido.
                                    </li>
                                    <li>
                                        6.3 Na contratação de determinado
                                        serviço ou produto, a plataforma poderá
                                        solicitar as informações financeiras do
                                        usuário, como CPF/CNPJ, endereço de
                                        cobrança e dados de cartões. Ao inserir
                                        referidos dados, o usuário concorda que
                                        sejam cobrados, de acordo com a forma de
                                        pagamento que escolher, os preços então
                                        vigentes e informados quando da
                                        contratação. Referidos dados financeiros
                                        poderão ser armazenados para facilitar
                                        acessos e contratações futuras.
                                    </li>
                                </ul>
                                <li className="title">
                                    7. Comunicação entre a Empresa e seus
                                    visitantes e usuários
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        7.1 Em caso de qualquer dúvida, sugestão
                                        ou problema com a utilização da
                                        plataforma, o usuário poderá entrar em
                                        contato com o suporte, através do e-mail
                                        support@adatravel.com.br ou telefone
                                        (47) 21038468.
                                    </li>
                                    <li>
                                        7.2 Estes serviços de atendimento ao
                                        usuário estarão disponíveis nos
                                        seguintes dias e horários: Seg-Sex:
                                        14:00-18:00.
                                    </li>
                                    <li>
                                        7.3 A Empresa utilizará como canal de
                                        comunicação com seus visitantes e
                                        usuários o e-mail informado no cadastro.
                                    </li>
                                    <li>
                                        7.4 A responsabilidade pelo recebimento
                                        dos comunicados é exclusiva dos
                                        visitantes e usuários, por isso é
                                        indispensável que sempre forneçam os
                                        dados corretos e precisos à Empresa, e
                                        os mantenha atualizados.
                                    </li>
                                    <li>
                                        7.5 Também é responsabilidade dos
                                        visitantes e usuários deixar seus
                                        sistemas de antispam configurados de
                                        modo que não interfiram no recebimento
                                        dos comunicados e materiais da Empresa,
                                        não sendo aceitável nenhuma escusa caso
                                        não tenha tido acesso a algum e-mail em
                                        virtude desse bloqueio ou filtro
                                        similar.{' '}
                                    </li>
                                    <li>
                                        7.6 Será considerada válida toda e
                                        qualquer comunicação enviada pela
                                        Empresa ao endereço de e-mail cadastrado
                                        pelo usuário.
                                    </li>
                                </ul>
                                <li className="title">
                                    8. Obrigações e responsabilidades dos
                                    visitantes e usuários
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        8.1. Os usuários deverão cuidar do
                                        sigilo e segurança de seu login e senha,
                                        pois estas informações da conta de
                                        acesso determinam a sua identidade
                                        digital, imputando-lhes a autoria de
                                        todos os atos praticados em seus nomes,
                                        ainda que seja por terceiro que tenha
                                        conhecimento desses dados.
                                    </li>
                                    <li>
                                        8.2. Caso ocorra algum incidente com os
                                        dados de login e senha, como furto,
                                        extravio, perda ou suspeita de quebra de
                                        sigilo, o usuário deve alterar sua senha
                                        e comunicar de imediato à Empresa, para
                                        evitar a ocorrência de danos que podem
                                        ser de difícil reparação.
                                    </li>
                                    <li>
                                        8.3. Todo usuário que possuir conta de
                                        acesso a esta plataforma se obriga a
                                        manter seus dados cadastrais sempre
                                        atualizados, sob pena de responder civil
                                        e criminalmente pelos danos decorrentes
                                        da imprecisão e inexatidão das
                                        informações armazenadas.
                                    </li>
                                    <li>
                                        8.4. Ao fornecer dados e informações à
                                        Empresa, os visitantes e usuários se
                                        obrigam a fazê-lo sempre com compromisso
                                        de veracidade e autenticidade, sob pena
                                        da aplicação das penas da lei, de
                                        indenizar a quem causar dano e de ter a
                                        conta de acesso à esta plataforma
                                        excluída ou suspensa.
                                    </li>
                                    <li>
                                        8.5. Na incidência de danos à plataforma
                                        ou a terceiros, o responsável se
                                        compromete a arcar com todas as
                                        obrigações de indenizar o sujeito
                                        lesado, não devendo a Empresa responder
                                        por tais prejuízos.
                                    </li>
                                    <li>
                                        8.6. Os visitantes e usuários devem
                                        utilizar os recursos desta plataforma
                                        para as finalidades às quais foram
                                        constituídas, sob pena da aplicação das
                                        penas da lei, de indenizar a quem causar
                                        dano e de ter a conta de acesso excluída
                                        ou suspensa.
                                    </li>
                                    <li>
                                        8.7. Os visitantes e usuários são
                                        responsáveis pelos defeitos ou vícios
                                        técnicos originados no próprio sistema
                                        do usuário.
                                    </li>
                                    <li>
                                        8.8. Os visitantes e usuários devem
                                        prezar pela correta utilização da
                                        plataforma, dos serviços e/ou produtos
                                        oferecidos.
                                    </li>
                                    <li>
                                        8.9. Não poderão ser incluídos links
                                        externos ou páginas que sirvam para fins
                                        comerciais ou publicitários ou quaisquer
                                        informações ilícitas, violentas,
                                        polêmicas, pornográficas, xenofóbicas,
                                        discriminatórias ou ofensivas.
                                    </li>
                                    <li>
                                        8.10. Os visitantes e usuários se
                                        obrigam a cumprir e respeitar o presente
                                        Termo e Condições Uso, a Política de
                                        Privacidade e a legislação nacional e
                                        internacional.
                                    </li>
                                </ul>
                                <li className="title">
                                    9. Obrigações e responsabilidades da Empresa
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        9.1 A Empresa se obriga com os
                                        visitantes e usuários a:
                                    </li>
                                    <ul className="subsubtitles">
                                        <li>
                                            9.1.1 Manter o ambiente virtual
                                            seguro, salvo por ato destrutivo de
                                            terceiro que vá além dos esforços
                                            empenhados, hipótese que não se
                                            responsabilizará por danos oriundos
                                            dessa prática danosa.
                                        </li>
                                        <li>
                                            9.1.2 Preservar a funcionalidade da
                                            plataforma.
                                        </li>
                                        <li>
                                            9.1.3 Exibir as funcionalidades de
                                            maneira clara, completa, precisa e
                                            suficiente, de modo que exista a
                                            exata percepção das operações
                                            realizadas.
                                        </li>
                                    </ul>
                                    <li>
                                        9.2 A Empresa não se responsabiliza por
                                        links externos contidos em seu sistema
                                        que possam redirecionar o usuário a
                                        ambientes externos a sua rede. Caso
                                        sobreviva algum dano ou prejuízo, os
                                        visitantes e usuários serão os únicos
                                        responsáveis.
                                    </li>
                                    <li>
                                        9.3 A Empresa não se responsabiliza por
                                        eventual indisponibilidade do software
                                        decorrente de casos fortuitos ou de
                                        força maior, bem como danos ou prejuízos
                                        causados no equipamento dos usuários por
                                        ações de terceiros, softwares maliciosos
                                        ou uso indevido da plataforma.
                                    </li>
                                    <li>
                                        9.4 A Empresa não se responsabiliza
                                        pelos atos praticados por visitantes e
                                        usuários no ambiente oferecido, devendo
                                        cada um ser imputado de acordo com a
                                        qualidade do uso da plataforma.
                                    </li>
                                    <li>
                                        9.5 A Empresa poderá, a seu exclusivo
                                        critério e conveniência, excluir ou
                                        suspender a conta de acesso de
                                        determinado usuário de sua plataforma,
                                        em virtude de suspeita de inexatidão de
                                        informações e prática criminosa de algum
                                        ato. Essa faculdade não exime os
                                        visitantes e usuários de fornecer sempre
                                        dados verdadeiros e praticar condutas
                                        que coadunem com o propósito de
                                        existência da plataforma e de seu
                                        cadastro nela.
                                    </li>
                                </ul>
                                <li className="title">
                                    10. Direitos autorais e propriedade
                                    intelectual da Empresa
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        10.1 Os presentes Termos e Condições de
                                        Uso concede aos usuários uma licença não
                                        exclusiva, não transferível e não
                                        sublicenciável, para acessar e fazer uso
                                        da plataforma e dos serviços e produtos
                                        por ela disponibilizados.
                                    </li>
                                    <li>
                                        10.2 Os presentes Termos e Condições de
                                        Uso não cedem nem transferem aos
                                        visitantes e usuários qualquer direito,
                                        de modo que o acesso não gera qualquer
                                        direito de propriedade intelectual aos
                                        visitantes e usuários, exceto pela
                                        licença limitada ora concedida.
                                    </li>
                                    <li>
                                        10.3 O software de gestão de bilhetes
                                        aéreos denominado ADA TRAVEL TECH, a
                                        estrutura do site, a marca ADA TRAVEL
                                        TECH, os logotipos, os nomes comerciais,
                                        os layouts, os gráficos e design de
                                        interface, as imagens, as ilustrações,
                                        as fotografias, as apresentações, os
                                        vídeos, os conteúdos escritos e de som e
                                        áudio, os programas de computador, o
                                        banco de dados, os arquivos de
                                        transmissão e quaisquer outras
                                        informações e direitos são de
                                        propriedade intelectual da Empresa
                                        (Tripservice – Agência de Viagens e
                                        Turismo Ltda., CNPJ 01.261.714/0001-16)
                                        e estão protegidos pela Lei da
                                        Propriedade Industrial (Lei nº
                                        9.279/96), Lei de Direitos Autorais (Lei
                                        nº 9.610/98), Lei do Software (Lei nº
                                        9.609/98), tratados internacionais de
                                        direito autoral, marcas, patentes,
                                        modelos e design industrial.
                                    </li>
                                    <li>
                                        10.4 Ao acessar a plataforma, os
                                        visitantes e usuários declaram que irão
                                        respeitar todos os direitos de
                                        propriedade intelectual, autorais e de
                                        software e os decorrentes da proteção de
                                        marcas, patentes e/ou desenhos
                                        industriais, depositados ou registrados
                                        em nome da empresa, bem como de todos os
                                        direitos referentes a terceiros que
                                        porventura estejam ou estiveram de
                                        alguma forma disponíveis na plataforma.
                                        O simples acesso à plataforma não
                                        confere aos visitantes e usuários nenhum
                                        direito ao uso dos nomes, títulos,
                                        palavras, frases, marcas, patentes,
                                        imagens, dados e informações, dentre
                                        outras, que nela estejam ou estiveram
                                        disponíveis.
                                    </li>
                                    <li>
                                        10.5 A reprodução dos conteúdos
                                        descritos anteriormente está proibida,
                                        salvo com prévia autorização por escrito
                                        da Empresa ou caso se destinem ao uso
                                        exclusivamente pessoal e sem que em
                                        nenhuma circunstância os visitantes e
                                        usuários adquiram qualquer direito sobre
                                        eles.
                                    </li>
                                    <li>
                                        10.6 É vedada a utilização para
                                        finalidades comerciais, publicitárias ou
                                        qualquer outra que contrarie a realidade
                                        para a qual foi concebida, conforme
                                        definido neste documento. Restam
                                        igualmente proibidas a reprodução,
                                        distribuição e divulgação, total ou
                                        parcial, dos textos, figuras, gráficos
                                        que compõem a presente plataforma, sem
                                        prévia e expressa autorização da
                                        Empresa, sendo permitida somente a
                                        impressão de cópias para uso e arquivo
                                        pessoal, sem que sejam separadas as
                                        partes que permitam dar o fiel e real
                                        entendimento de seu conteúdo e objetivo.
                                    </li>
                                    <li>
                                        10.7 Os visitantes e usuários assumem
                                        toda e qualquer responsabilidade, de
                                        caráter civil e/ou criminal, pela
                                        utilização indevida das informações,
                                        textos, gráficos, marcas, imagens,
                                        enfim, de todo e qualquer direito de
                                        propriedade intelectual ou industrial
                                        desta plataforma.
                                    </li>
                                    <li>
                                        10.8 Qualquer tipo de utilização de
                                        material autorizado, inclusive para fins
                                        comerciais e publicitários, só poderá
                                        ser feito mediante prévio e expresso
                                        consentimento da Empresa. Os visitantes
                                        e usuários estão cientes, por meio deste
                                        item, que o uso comercial não autorizado
                                        poderá incorrer em infrações cíveis e
                                        criminais, por estar infringindo as leis
                                        descritas no item 10.2. deste documento.
                                    </li>
                                    <li>
                                        10.9 Qualquer reutilização do material
                                        autorizado deverá ser objeto de uma nova
                                        autorização da Empresa.
                                    </li>
                                    <li>
                                        10.10 A autorização para utilização do
                                        material solicitado não poderá ser
                                        transferida a terceiros, mesmo que
                                        vinculados ao sujeito autorizado por
                                        alguma razão.
                                    </li>
                                    <li>
                                        10.11 Qualquer utilização não
                                        contemplada na mencionada autorização
                                        será considerada como violação dos
                                        direitos do autor e titular e sujeita às
                                        sanções legais cabíveis.
                                    </li>
                                    <li>
                                        10.12 O uso da plataforma pelos
                                        visitantes e usuários é pessoal,
                                        individual e intransferível, sendo
                                        vedado qualquer uso não autorizado,
                                        comercial ou não-comercial. Tais usos
                                        consistirão em violação dos direitos de
                                        propriedade intelectual da Empresa,
                                        puníveis nos termos da legislação
                                        aplicável.
                                    </li>
                                </ul>
                                <li className="title">
                                    11. Modificações destes Termos e Condições
                                    de Uso
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        11.1 Os itens descritos no presente
                                        instrumento poderão sofrer alterações,
                                        unilateralmente e a qualquer tempo, por
                                        parte da Empresa, para adequar ou
                                        modificar os serviços, bem como para
                                        atender novas exigências legais.
                                    </li>
                                    <li>
                                        11.2 Os serviços oferecidos podem, a
                                        qualquer tempo e unilateralmente, e sem
                                        qualquer aviso prévio, ser deixados de
                                        fornecer, alterados em suas
                                        características, bem como restringido
                                        para o uso ou acesso.
                                    </li>
                                </ul>
                                <li className="title">
                                    12. Disposições gerais
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        12.1 A tolerância do eventual
                                        descumprimento de quaisquer das
                                        cláusulas e condições do presente
                                        instrumento não constituirá novação das
                                        obrigações aqui estipuladas e tampouco
                                        impedirá ou inibirá a exigibilidade a
                                        qualquer tempo.
                                    </li>
                                    <li>
                                        12.2 Sem prejuízo das demais medidas
                                        legais cabíveis, a Empresa poderá, a
                                        qualquer momento, de forma unilateral e
                                        sem prévio aviso, não sendo devida
                                        indenização, suspender e/ou cancelar a
                                        conta do usuário que:
                                    </li>
                                    <ul className="subsubtitles">
                                        <li>
                                            12.1.1 Violar qualquer dispositivo
                                            dos presentes Termos e Condições de
                                            Uso e da Política de Privacidade.
                                        </li>
                                        <li>
                                            12.1.2 Descumprir as obrigações e
                                            responsabilidades de visitante e
                                            usuário.
                                        </li>
                                        <li>
                                            12.1.3 Praticar qualquer
                                            comportamento fraudulento, doloso ou
                                            que ofenda a terceiros.
                                        </li>
                                    </ul>
                                </ul>
                                <li className="title">
                                    13. Política de privacidade
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        13.1 A Empresa possui documento próprio,
                                        denominado Política de Privacidade, que
                                        regula o tratamento dado às informações
                                        de cadastro e demais informações e dados
                                        coletados nesta plataforma.
                                    </li>
                                    <li>
                                        13.2 A Política de Privacidade é parte
                                        integrante e inseparável dos Termos e
                                        Condições de Uso desta plataforma, e
                                        pode ser acessada no link
                                        https://drive.google.com/file/d/1W-ZoZc0Zv1rTXWHTXWg1DSfPIq5Aqgo9/view?usp=sharing.
                                    </li>
                                    <li>
                                        13.3 Caso alguma disposição da Política
                                        de Privacidade conflite com qualquer
                                        outra do presente documento, deverá
                                        prevalecer o descrito na norma mais
                                        específica.
                                    </li>
                                </ul>
                                <li className="title">
                                    14. Lei aplicável e jurisdição
                                </li>
                                <ul className="subtitle">
                                    <li>
                                        14.1 Os Termos e Condições de Uso aqui
                                        descritos são interpretados segunda a
                                        legislação brasileira, no idioma
                                        português, sendo eleito o foro da
                                        Comarca de Itajaí – SC, para dirimir
                                        qualquer litígio, questão ou dúvida
                                        superveniente, com expressa renúncia a
                                        qualquer outro, por mais privilegiado
                                        que seja.
                                    </li>
                                </ul>
                                <li className="title">15. Registro</li>
                                <ul className="subtitle">
                                    <li>
                                        15.1 Este documento encontra-se
                                        registrado no Ofício de Registro Civil
                                        das Pessoas Naturais, Interdições e
                                        Tutelas, das Pessoas Jurídicas e de
                                        Títulos e Documentos da Comarca de
                                        Itajaí – SC.
                                    </li>
                                </ul>
                            </ul>
                            <br />
                            <p>
                                25 de março de 2021 TRIPSERVISE – AGÊNCIA DE
                                VIAGENS E TURISMO LTDA.
                            </p>
                        </div>
                    )}
                </div>
            </PrivacyPoliciesContainer>
        </ModalContainer>
    )
}
