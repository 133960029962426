import styled from 'styled-components'

export const ResetPasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    h1 {
        margin-bottom: 5rem;
        font-weight: 600;
    }
    form {
        width: 100%;
        .reset-btn {
            button {
                width: 100%;
            }
            margin-top: 2rem;
        }
    }
    @media screen and (max-width: 56.25em) {
        width: 90%;
    }
`
