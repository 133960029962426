import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import React, { useRef, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Form } from '@unform/web'
import { InitialContainer } from '../../container/InitialContainer'
import { CompanyInfo } from '../../entitie/company-info'
import { useUser } from '../../hooks/use-user'
import { UserInfo } from '../../entitie/user-info'
import { api } from '../../service/api'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { SignupSecondStepContainer } from './styles'
import { Button } from '../../components/Button'
import { InitialInput } from '../../components/InitialInput'
import { BigLoader } from '../../components/BigLoader'
import { cnpjFormatter, phoneFormatter } from '../../utils/formatter'
import { useToast } from '../../hooks/use-toast'

interface LocationProps {
    userInfo: UserInfo
    ref?: string
}

export function SignupSecondStepPage() {
    // loation
    const { userInfo, ref } = useLocation().state as LocationProps

    // refs
    const formRefCompany = useRef<FormHandles>(null)

    // hooks
    const { signUp } = useUser()
    const { addToast } = useToast()

    // state
    const [loadingCnpjData, setLoadingCnpjData] = useState(false)
    const [signUpBusyBtn, setSignUpBusyBtn] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [phoneFormatted, setPhoneFormatted] = useState('')
    const [cnpjFormatted, setCnpjFormatted] = useState('')

    async function getCompanyInfo(cnpjInput: string) {
        setLoadingCnpjData(true)
        try {
            const response = await api.get(
                `cnpj-data?cnpj=${cnpjFormatter(cnpjInput, 'TO_API')}`
            )
            if (response) {
                if (response.data) {
                    const {
                        legalName,
                        zipCode,
                        city,
                        neighborhood,
                        state,
                        street,
                        number,
                    } = response.data
                    formRefCompany.current?.setData({
                        legalName,
                        zipCode,
                        city,
                        neighborhood,
                        state,
                        street,
                        number,
                    })
                }
                if (Object.keys(response.data).length === 0) {
                    formRefCompany.current?.setData({
                        legalName: '',
                        zipCode: '',
                        city: '',
                        neighborhood: '',
                        state: '',
                        street: '',
                        number: '',
                    })
                }
            } else {
                formRefCompany.current?.setData({
                    legalName: '',
                    zipCode: '',
                    city: '',
                    neighborhood: '',
                    state: '',
                    street: '',
                    number: '',
                })
            }
            setLoadingCnpjData(false)
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            formRefCompany.current?.setData({
                legalName: '',
                zipCode: '',
                city: '',
                neighborhood: '',
                state: '',
                street: '',
                number: '',
            })
            setLoadingCnpjData(false)
        }
    }

    async function handleCompanyInfoForm(data: CompanyInfo) {
        setSignUpBusyBtn(true)
        try {
            formRefCompany.current?.setErrors({})

            const schema = Yup.object().shape({
                cnpj: Yup.string()
                    .required('Insira um CNPJ válido')
                    .min(18, 'Insira um CNPJ válido'),
                phone: Yup.string()
                    .required('Insira um telefone válido')
                    .min(14, 'Insira um telefone válido'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            const companyData: CompanyInfo = {
                ...data,
                phone: data.phone,
                cnpj: cnpjFormatter(data.cnpj, 'TO_API'),
                ref,
            }
            const response = await signUp(userInfo as UserInfo, {
                ...companyData,
            })
            if (!response) {
                setSignUpBusyBtn(false)
                return
            }
            addToast({ title: 'Conta criada co sucesso.', type: 'info' })
            setSignUpBusyBtn(false)
            setRedirect(true)
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRefCompany.current?.setErrors(errors)
                setSignUpBusyBtn(false)
            }
        }
    }

    return (
        <InitialContainer>
            <SignupSecondStepContainer>
                <Form ref={formRefCompany} onSubmit={handleCompanyInfoForm}>
                    <InitialInput
                        name="cnpj"
                        labelTitle="CNPJ"
                        value={cnpjFormatted}
                        onChange={(e) => {
                            setCnpjFormatted(
                                cnpjFormatter(e.target.value, 'INPUT')
                            )
                            if (e.target.value.length === 18) {
                                getCompanyInfo(e.target.value)
                            }
                        }}
                    />
                    <InitialInput
                        name="legalName"
                        labelTitle="Razão social"
                        disabled
                    />
                    <InitialInput name="zipCode" labelTitle="CEP" disabled />
                    <InitialInput name="state" labelTitle="Estado" disabled />
                    <InitialInput name="city" labelTitle="Cidade" disabled />
                    <InitialInput
                        name="street"
                        labelTitle="Logradouro"
                        disabled
                    />
                    <InitialInput name="neighborhood" labelTitle="Bairro" />
                    <InitialInput name="number" labelTitle="Número" disabled />
                    <InitialInput name="complement" labelTitle="Complemento" />
                    <InitialInput
                        name="phone"
                        labelTitle="Telefone"
                        value={phoneFormatted}
                        onChange={(e) =>
                            setPhoneFormatted(
                                phoneFormatter(
                                    e.target.value as string,
                                    'INPUT'
                                )
                            )
                        }
                    />
                    <div className="signup-btn">
                        <Button
                            isSubmit
                            color="primary"
                            title="Cadastrar"
                            busy={signUpBusyBtn}
                        />
                    </div>
                </Form>
                {loadingCnpjData ? <BigLoader /> : null}
                {redirect ? (
                    <Redirect
                        to={{ pathname: '/check-email', state: userInfo?.name }}
                    />
                ) : null}
            </SignupSecondStepContainer>
        </InitialContainer>
    )
}
