/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select'
import { Redirect } from 'react-router-dom'
import { InitialInput } from '../../components/InitialInput'
import { InitialContainer } from '../../container/InitialContainer'
import { UserInfo } from '../../entitie/user-info'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { SignupFirstStepContainer } from './styles'
import { useToast } from '../../hooks/use-toast'
import { Button } from '../../components/Button'
import { useUser } from '../../hooks/use-user'
import { useAuth } from '../../hooks/use-auth'
import { PrivacyPolicies } from '../PrivacyPlicies'

export function SignupFirstStepPage() {
    // refs
    const formRef = useRef<FormHandles>(null)

    // hooks
    const { addToast } = useToast()
    const { validateEmail } = useUser()
    const { togglePrivacy, showPolicies } = useAuth()

    // state
    const [userType, setUserType] = useState<{ label: string; value: string }>()
    const [userInfo, setUserInfo] = useState<UserInfo>()
    const [ref, setRef] = useState<string | undefined>()
    const [busyBtn, setBusyBtn] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [isPrivacyPolicie, setIsPrivacyPolicie] = useState(false)
    const [userNotSelected, setUserNotSelected] = useState(false)
    const [acceptTerms, setAcceptTerms] = useState(false)

    async function HandleFirstStep(data: UserInfo) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                name: Yup.string().required('Insira um nome'),
                email: Yup.string().required('Insira um email'),
                password: Yup.string()
                    .required('Insira uma senha valida ')
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/,
                        'Senha deve conter: letra maiúscula; letra minúscula; número, algum dos caracteres especiais @$!%?&#. Mínimo de 6 dígitos.'
                    )
                    .max(32, 'A senha pode conter no máximo 32 dígitos'),
                confirmPassword: Yup.string()
                    .required('Confirme sua senha')
                    .oneOf([Yup.ref('password')], 'Senhas não coincidem'),
            })
            if (userType === undefined) {
                addToast({
                    title: 'Atenção',
                    description:
                        'Para continuar o seu cadastro é necessário selecionar o tipo de empresa',
                    type: 'info',
                })
                setUserNotSelected(true)
                await schema.validate(data, {
                    abortEarly: false,
                })
                setBusyBtn(false)
                return
            }
            if (!acceptTerms) {
                addToast({
                    title: 'Atenção',
                    description:
                        'Para continuar é necessário concordar com os termos de uso e com a política de privacidade',
                    type: 'info',
                })
                await schema.validate(data, {
                    abortEarly: false,
                })
                setBusyBtn(false)
                return
            }
            setUserNotSelected(false)
            await schema.validate(data, {
                abortEarly: false,
            })
            const response = await validateEmail(data.email)
            if (!response) {
                setBusyBtn(false)
                return
            }
            setUserInfo({
                userType: userType.value as
                    | 'COMPANY'
                    | 'AGENCY'
                    | 'CONSOLIDATOR',
                email: data.email,
                password: data.password,
                name: data.name,
            })
            setRedirect(true)
            setBusyBtn(false)
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
            setBusyBtn(false)
        }
    }

    useEffect(() => {
        const { search } = window.location
        const params = new URLSearchParams(search)
        const refParams = params.get('ref')
        if (refParams) {
            setRef(refParams)
        }
    }, [])

    return (
        <InitialContainer>
            <SignupFirstStepContainer>
                <h1 className="heading-font">Criar uma conta.</h1>
                <Form ref={formRef} onSubmit={HandleFirstStep}>
                    <div className="select">
                        <ReactSelect
                            value={userType}
                            placeholder="Selecione o tipo da sua empresa"
                            options={[
                                {
                                    label: 'Empresa corporativa',
                                    value: 'COMPANY',
                                },
                                {
                                    label: 'Agência de viagens',
                                    value: 'AGENCY',
                                },
                            ]}
                            onChange={(e: any) => {
                                if (e) {
                                    setUserType({
                                        value: e.value,
                                        label: e.label,
                                    })
                                }
                            }}
                        />
                        {userNotSelected && (
                            <p style={{ color: 'red', marginTop: '0.5rem' }}>
                                É necessário selecionar o tipo da sua empresa.
                            </p>
                        )}
                    </div>
                    <InitialInput name="name" labelTitle="Nome da empresa" />
                    <InitialInput name="email" labelTitle="E-mail" />
                    <InitialInput
                        name="password"
                        labelTitle="Senha"
                        type="password"
                    />
                    <InitialInput
                        name="confirmPassword"
                        labelTitle="Confirmar senha"
                        type="password"
                    />
                    <div className="policies">
                        <label htmlFor="policies" className="policies-checkbox">
                            <input
                                type="checkbox"
                                id="policies"
                                onChange={(e) =>
                                    setAcceptTerms(e.target.checked)
                                }
                            />
                            Declaro que:
                        </label>
                        <p>
                            Li e concordo com os{' '}
                            <button
                                type="button"
                                onClick={() => {
                                    setIsPrivacyPolicie(false)
                                    togglePrivacy(true)
                                }}
                            >
                                Termos de Uso
                            </button>{' '}
                            e{' '}
                            <button
                                type="button"
                                onClick={() => {
                                    setIsPrivacyPolicie(true)
                                    togglePrivacy(true)
                                }}
                            >
                                Política de privacidade
                            </button>{' '}
                            Também concordo que a ADA Travel e seus
                            representantes podem entrar em contato comigo por
                            e-mail, telefone ou SMS (inclusive por meios
                            automatizados) no endereço de e-mail ou número que
                            eu forneci, inclusive para fins de marketing.
                        </p>
                        <br />
                    </div>
                    <div className="next-step">
                        <Button
                            isSubmit
                            color="primary"
                            title="Continuar"
                            busy={busyBtn}
                        />
                    </div>
                </Form>
            </SignupFirstStepContainer>
            {redirect ? (
                <Redirect
                    to={{
                        pathname: '/signup-second-step',
                        state: { userInfo, ref },
                    }}
                />
            ) : null}
            {showPolicies && !isPrivacyPolicie && (
                <PrivacyPolicies
                    isOpen={showPolicies}
                    isPrivacyPolicie={false}
                    onRequestClose={() => {
                        togglePrivacy(false)
                    }}
                />
            )}
            {showPolicies && isPrivacyPolicie && (
                <PrivacyPolicies
                    isOpen={showPolicies}
                    isPrivacyPolicie
                    onRequestClose={() => {
                        togglePrivacy(false)
                    }}
                />
            )}
        </InitialContainer>
    )
}
