import React, { useContext, createContext, useState, ReactNode } from 'react'

interface IFormatterContext {
    formatCnpj(value: string): string
    formatPhone(value: string, fromApi: boolean): string
    formatCep(value: string): string
    unformatCnpj(value: string): string
    unformatPhone(value: string): string
    unformatCep(value: string): string
    formatMoney(value: string): string
    convertToKPI1(value: string): string
    refundCredit(value: number): string
    phoneFormatted?: string
    cnpjFormatted?: string
}
interface AppProvideProps {
    children: ReactNode
}

const FormatterContext = createContext({} as IFormatterContext)

export function FormatterProvider({ children }: AppProvideProps) {
    // state
    const [cnpjFormatted, setCnpjFormatted] = useState<string>()
    const [phoneFormatted, setPhoneFormatted] = useState<string>()

    function formatCnpj(value: string) {
        const formattedString = value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{2}).(\d{3})(\d)/, '$1.$2.$3')
            .replace(/(\d{2}).(\d{3}).(\d{3})(\d)/, '$1.$2.$3/$4')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')

        setCnpjFormatted(formattedString)
        return formattedString
    }

    function formatPhone(value: string, fromApi: boolean) {
        if (!fromApi) {
            const formattedString = value
                .replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '($1) $2')
                .replace(/(\d{4})(\d)/, '$1-$2')
                .replace(/(\d{4})(-)(\d)(\d{3})(\d)/, '$1$3-$4$5')
                .replace(/(-\d{4})\d+?$/, '$1')

            setPhoneFormatted(formattedString)

            return formattedString
        }
        const formattedString = value
            .substring(3)
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(\d{4})(-)(\d)(\d{3})(\d)/, '$1$3-$4$5')
            .replace(/(-\d{4})\d+?$/, '$1')
        setPhoneFormatted(formattedString)
        return formattedString
    }

    function formatCep(value: string) {
        const formattedString = value.replace(/(\d{5})(\d)/, '$1-$2')
        return formattedString
    }

    function unformatCnpj(value: string) {
        const cnpj = value
            .split('.')
            .join('')
            .split('/')
            .join('')
            .split('-')
            .join('')

        return cnpj
    }

    function unformatPhone(value: string) {
        const phone = `+55${value
            .split('(')
            .join('')
            .split(')')
            .join('')
            .split('-')
            .join('')
            .split(' ')
            .join('')}`

        return phone
    }

    function unformatCep(value: string) {
        const cep = value.split('.').join('').split('-').join('')
        return cep
    }

    function formatMoney(value: string) {
        const result = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        return result
    }

    function convertToKPI1(value: string) {
        const beforeComma = value.split('.')[0]
        const dividedByThousand = parseFloat(beforeComma) / 1000
        if (beforeComma.length < 4) {
            return beforeComma
        }
        if (beforeComma.length === 4) {
            return `${Math.round(dividedByThousand * 10) / 10}K`
        }
        return `${Math.round(dividedByThousand * 10) / 10}K`
    }

    // function bordingStatusFormatter(value: IBoardingStatus | undefined) {
    //     if (value === 'NOT_FLOWN') {
    //         return 'Não voado'
    //     }
    //     if (value === 'COMPLETELY_FLOWN') {
    //         return 'Voado'
    //     }
    //     if (value === 'PARTIALLY_FLOWN') {
    //         return 'Parcialmente voado'
    //     }
    //     return 'Dados insuficientes'
    // }

    // function creditStatusFormatter(value: ICreditStatus | undefined) {
    //     if (value === 'PENDING') {
    //         return 'Em aberto'
    //     }
    //     if (value === 'REUSED' || value === 'REQUESTED') {
    //         return 'Remarcado/ Reembolsado'
    //     }
    //     if (value === 'NO_CREDIT') {
    //         return 'Sem crédito'
    //     }
    //     return 'Dados insuficientes'
    // }

    function refundCredit(value: number) {
        if (value < 0) {
            return 'Taxa(s) de embarque'
        }
        if (value === null) {
            return 'Dados insuficientes'
        }
        return `R$ ${value.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}`
    }

    // function boardingStatusColor(status: IBoardingStatus | undefined) {
    //     if (status === 'PARTIALLY_FLOWN') {
    //         return '#F1C523'
    //     }
    //     if (status === 'NOT_FLOWN') {
    //         return '#E74B70'
    //     }
    //     if (status === 'COMPLETELY_FLOWN') {
    //         return '#08205E'
    //     }
    //     if (status === '') {
    //         return 'transparent'
    //     }
    //     return '#E74B70'
    // }

    return (
        <FormatterContext.Provider
            value={{
                formatCnpj,
                formatPhone,
                formatCep,
                unformatCnpj,
                unformatPhone,
                unformatCep,
                formatMoney,
                // bordingStatusFormatter,
                // creditStatusFormatter,
                convertToKPI1,
                refundCredit,
                // boardingStatusColor,
                cnpjFormatted,
                phoneFormatted,
            }}
        >
            {children}
        </FormatterContext.Provider>
    )
}

export function useFormatter(): IFormatterContext {
    const context = useContext(FormatterContext)

    if (!context) {
        throw new Error('use auth must be within a FormatterProvider')
    }
    return context
}
