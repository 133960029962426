import React from 'react'

import {
    IgrDoughnutChartModule,
    IgrDoughnutChart,
    IgrRingSeriesModule,
    IgrRingSeries,
} from 'igniteui-react-charts'
import { DoughnutStatusChartContainer } from './styles'

IgrDoughnutChartModule.register()
IgrRingSeriesModule.register()

interface Props {
    data: { Value?: number; Label: string; color: string }[]
}

export function DoughnutStatusChart({ data }: Props) {
    return (
        <DoughnutStatusChartContainer>
            <IgrDoughnutChart height="100%" width="100%" innerExtent={70}>
                <IgrRingSeries
                    name="ring1"
                    dataSource={data}
                    valueMemberPath="Value"
                    brushes={data.map((d) => d.color)}
                />
            </IgrDoughnutChart>
        </DoughnutStatusChartContainer>
    )
}
