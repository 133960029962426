/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import crypto from 'crypto'
import React, { useEffect, useRef, useState } from 'react'
import { RiRecordCircleFill, RiCheckboxBlankCircleLine } from 'react-icons/ri'
import ModalContainer from 'react-modal'
import { CreateCredentialDto } from '../../dtos/create-credential'
import { useUser } from '../../hooks/use-user'
import { Input } from '../Input'
import { PrimaryButton } from '../PrimaryButton'
import { CiaSelector, ModalContent } from './styles'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { PersonalCredential } from '../../entitie/personal-credential'
import { useCredential } from '../../hooks/use-credential'
import { EditCredentialDto } from '../../dtos/edit-credential'

interface ModalProps {
    isOpen: boolean
    initialData?: PersonalCredential
    onRequestClose: () => void
}

export function HandleCredential({
    isOpen,
    initialData,
    onRequestClose,
}: ModalProps) {
    // hooks
    const { getAirlines, airlines } = useUser()
    const {
        createCredential,
        editCredential,
        toggleEditCredential,
        toggleCreateCredential,
        showCreateCredential,
    } = useCredential()

    // refs
    const formRef = useRef<FormHandles>(null)

    // state
    const [busy, setBusy] = useState(false)
    const [selectedCia, setSelectedCia] = useState<
        'AD' | 'G3' | 'LA' | '2Z' | 'LAS' | 'LAN' | 'ADR' | undefined
    >(undefined)
    const [busyBtn, setBusyBtn] = useState<boolean>(false)
    const [changePassword, setChangePassword] = useState(false)
    const [editCredentialData, setEditCredentialData] =
        useState<EditCredentialDto>({} as EditCredentialDto)

    function findDomain(
        cia: 'AD' | 'G3' | 'LA' | '2Z' | 'LAS' | 'LAN' | 'ADR'
    ) {
        if (cia === 'G3') {
            return 'G3'
        }
        if (cia === 'AD') {
            return 'EXT'
        }
        if (cia === 'LA') {
            return 'LA'
        }
        return ''
    }

    async function handleCredential(data: CreateCredentialDto) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                username: Yup.string().required('Usuário obrigatório'),
                password: initialData
                    ? Yup.string()
                    : Yup.string().required('Senha obrigatória'),
                label: Yup.string().required('Identificador obrigatório'),
                organization:
                    selectedCia === 'LA' ||
                    selectedCia === 'G3' ||
                    selectedCia === 'LAS'
                        ? Yup.string().required(
                              'Organização obrigatória para esta cia'
                          )
                        : Yup.string().notRequired(),
                domain:
                    selectedCia !== '2Z' &&
                    selectedCia !== 'LAN' &&
                    selectedCia !== 'ADR'
                        ? Yup.string().required(
                              'Domínio obrigatório para esta cia'
                          )
                        : Yup.string().notRequired(),
                agencyIata:
                    selectedCia === 'LAN'
                        ? Yup.string().required(
                              'agencyIata obrigatório para esta cia'
                          )
                        : Yup.string().notRequired(),
                agencyId:
                    selectedCia === 'LAN'
                        ? Yup.string().required(
                              'agencyId obrigatório para esta cia'
                          )
                        : Yup.string().notRequired(),
                travelAgentId:
                    selectedCia === 'LAN'
                        ? Yup.string().required(
                              'travelAgentId obrigatório para esta cia'
                          )
                        : Yup.string().notRequired(),
                apiKey:
                    selectedCia === 'ADR'
                        ? Yup.string().required(
                              'apiKey obrigatório para esta cia'
                          )
                        : Yup.string().notRequired(),
                userAgent:
                    selectedCia === 'ADR'
                        ? Yup.string().required(
                              'userAgent obrigatório para esta cia'
                          )
                        : Yup.string().notRequired(),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            if (!initialData) {
                const key = `-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzIltTidMfXSuvNRH8zzO\nud8m/Wa3VK3hT2jLm5RAA//aXCJg3Jhtfy9nhj74WkaDoDEhLejd3t7fIQfly/OM\nmfuPZr3lH0VKHryfsK7b8rfD79+cSRjUdU/1WCJgFhZR/tJGR7aY16wa7KkBXhUO\ngoMOb0yyLET+8OR4qLK1DdfS9NDH+8aWfgxaqg0kIVtKV3Tg77CVvdEUG12eCbO0\n6c9Im5LA5sUf9zu/OrYcdTyP6vQeN+0GW+6hctrrC7iv9MeQc/OvTmzOQgqhlBYW\nfOVdOfQSoj3y+/ex/yC/Ce4YLCX/Pud3hssatWjScQHj6FeWq8lqKBOMxZlXMCMg\n1QIDAQAB\n-----END PUBLIC KEY-----`

                const hash = crypto
                    .publicEncrypt(
                        {
                            key,
                            padding: crypto.constants.RSA_PKCS1_PADDING,
                            passphrase: '',
                        },
                        Buffer.from(
                            JSON.stringify({
                                username: data.username,
                                domain:
                                    data.domain === ''
                                        ? undefined
                                        : data.domain,
                                organization:
                                    data.organization === ''
                                        ? undefined
                                        : data.organization,
                                password: data.password,
                                agencyIata: data.agencyIata,
                                agencyId: data.agencyId,
                                travelAgentId: data.travelAgentId,
                                apiKey: data.apiKey,
                                userAgent: data.userAgent,
                            })
                        )
                    )
                    .toString('base64')
                if (selectedCia) {
                    const createCredentialData: CreateCredentialDto = {
                        airlineIataCode: selectedCia,
                        label: data.label,
                        hash,
                    }
                    await createCredential(createCredentialData)
                    setBusyBtn(false)
                    toggleCreateCredential(false)
                }
            } else {
                await editCredential(editCredentialData, initialData.id)
                setBusyBtn(false)
                toggleEditCredential(undefined)
            }
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    useEffect(() => {
        if (!initialData) {
            setBusy(true)
            if (showCreateCredential) {
                ;(async () => {
                    await getAirlines()
                    setBusy(false)
                })()
                setSelectedCia(undefined)
            }
        } else {
            setSelectedCia(initialData.airline.iataCode)
        }
    }, [showCreateCredential])

    useEffect(() => {
        if (selectedCia && !initialData) {
            formRef.current?.setData({
                domain: findDomain(selectedCia),
                label: '',
                organization:
                    selectedCia === 'LA'
                        ? 'AOT'
                        : selectedCia === 'G3'
                        ? 'AQA'
                        : '',
                password: '',
                username: '',
                agencyIata: '',
                agencyId: '',
                travelAgentId: '',
                apiKey: '',
                userAgent: '',
            })
        }
        if (initialData) {
            formRef.current?.setData({
                domain: initialData.domain,
                label: initialData.label,
                organization: initialData.organization,
                username: initialData.username,
                agencyIata: initialData.agencyIata,
                agencyId: initialData.agencyId,
                travelAgentId: initialData.travelAgentId,
                apiKey: initialData.apiKey,
            })
        }
    }, [selectedCia])

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <>
                {busy ? null : (
                    <ModalContent>
                        <button
                            className="close"
                            type="button"
                            onClick={() => onRequestClose()}
                        >
                            X
                        </button>
                        <div className="title">
                            <h1 className="heading-font-configuration">
                                {initialData
                                    ? 'Editar Credencial'
                                    : 'Criar credencial'}
                            </h1>
                            {!initialData && <p>Selecione a companhia aérea</p>}
                        </div>
                        {airlines && !initialData && (
                            <div className="selectors">
                                <CiaSelector
                                    type="button"
                                    className={
                                        selectedCia === 'G3' ? 'colored' : ''
                                    }
                                    onClick={() => setSelectedCia('G3')}
                                >
                                    {selectedCia === 'G3' ? (
                                        <RiRecordCircleFill />
                                    ) : (
                                        <RiCheckboxBlankCircleLine />
                                    )}
                                    <h2>
                                        {
                                            airlines.find(
                                                (airline) =>
                                                    airline.iataCode === 'G3'
                                            )!.label
                                        }
                                    </h2>
                                </CiaSelector>
                                <CiaSelector
                                    type="button"
                                    onClick={() => setSelectedCia('AD')}
                                    className={
                                        selectedCia === 'AD' ? 'colored' : ''
                                    }
                                >
                                    {selectedCia === 'AD' ? (
                                        <RiRecordCircleFill />
                                    ) : (
                                        <RiCheckboxBlankCircleLine />
                                    )}
                                    <h2>
                                        {
                                            airlines.find(
                                                (airline) =>
                                                    airline.iataCode === 'AD'
                                            )!.label
                                        }
                                    </h2>
                                </CiaSelector>
                                <CiaSelector
                                    type="button"
                                    className={
                                        selectedCia === 'LA' ? 'colored' : ''
                                    }
                                    onClick={() => setSelectedCia('LA')}
                                >
                                    {selectedCia === 'LA' ? (
                                        <RiRecordCircleFill />
                                    ) : (
                                        <RiCheckboxBlankCircleLine />
                                    )}
                                    <h2>
                                        {
                                            airlines.find(
                                                (airline) =>
                                                    airline.iataCode === 'LA'
                                            )!.label
                                        }
                                    </h2>
                                </CiaSelector>
                                <CiaSelector
                                    type="button"
                                    className={
                                        selectedCia === '2Z' ? 'colored' : ''
                                    }
                                    onClick={() => setSelectedCia('2Z')}
                                >
                                    {selectedCia === '2Z' ? (
                                        <RiRecordCircleFill />
                                    ) : (
                                        <RiCheckboxBlankCircleLine />
                                    )}
                                    <h2>
                                        {
                                            airlines.find(
                                                (airline) =>
                                                    airline.iataCode === '2Z'
                                            )!.label
                                        }
                                    </h2>
                                </CiaSelector>
                                <CiaSelector
                                    type="button"
                                    className={
                                        selectedCia === 'LAS' ? 'colored' : ''
                                    }
                                    onClick={() => setSelectedCia('LAS')}
                                >
                                    {selectedCia === 'LAS' ? (
                                        <RiRecordCircleFill />
                                    ) : (
                                        <RiCheckboxBlankCircleLine />
                                    )}
                                    <h2>
                                        {
                                            airlines.find(
                                                (airline) =>
                                                    airline.iataCode === 'LAS'
                                            )!.label
                                        }
                                    </h2>
                                </CiaSelector>
                                <CiaSelector
                                    type="button"
                                    className={
                                        selectedCia === 'LAN' ? 'colored' : ''
                                    }
                                    onClick={() => setSelectedCia('LAN')}
                                >
                                    {selectedCia === 'LAN' ? (
                                        <RiRecordCircleFill />
                                    ) : (
                                        <RiCheckboxBlankCircleLine />
                                    )}
                                    <h2>
                                        {
                                            airlines.find(
                                                (airline) =>
                                                    airline.iataCode === 'LAN'
                                            )!.label
                                        }
                                    </h2>
                                </CiaSelector>
                                <CiaSelector
                                    type="button"
                                    className={
                                        selectedCia === 'ADR' ? 'colored' : ''
                                    }
                                    onClick={() => setSelectedCia('ADR')}
                                >
                                    {selectedCia === 'ADR' ? (
                                        <RiRecordCircleFill />
                                    ) : (
                                        <RiCheckboxBlankCircleLine />
                                    )}
                                    <h2>
                                        {
                                            airlines.find(
                                                (airline) =>
                                                    airline.iataCode === 'ADR'
                                            )!.label
                                        }
                                    </h2>
                                </CiaSelector>
                            </div>
                        )}
                        <Form ref={formRef} onSubmit={handleCredential}>
                            {selectedCia !== undefined && (
                                <>
                                    <div className="grid">
                                        <div className="username">
                                            <Input
                                                name="username"
                                                labelTitle={
                                                    selectedCia === 'G3' ||
                                                    selectedCia === 'LA'
                                                        ? 'EPR'
                                                        : 'Usuário'
                                                }
                                                disabled={!!initialData}
                                            />
                                        </div>
                                        <div className="password">
                                            {initialData && (
                                                <button
                                                    className="edit-password"
                                                    type="button"
                                                    onClick={() =>
                                                        setChangePassword(
                                                            !changePassword
                                                        )
                                                    }
                                                >
                                                    {changePassword
                                                        ? 'Cancelar'
                                                        : 'Tocar a senha'}
                                                </button>
                                            )}
                                            {changePassword ? (
                                                <Input
                                                    name="password"
                                                    type="password"
                                                    labelTitle="Nova senha"
                                                    onChange={(e) =>
                                                        setEditCredentialData({
                                                            ...editCredentialData,
                                                            password:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            ) : (
                                                <Input
                                                    name="password"
                                                    type="password"
                                                    labelTitle="Senha"
                                                    placeholder="*******"
                                                    disabled={!!initialData}
                                                />
                                            )}
                                        </div>
                                        <div className="label">
                                            <Input
                                                name="label"
                                                labelTitle="Identificador"
                                                toolTip="Campo interno gerencial, apelido da credêncial."
                                                onChange={(e) => {
                                                    if (initialData) {
                                                        setEditCredentialData({
                                                            ...editCredentialData,
                                                            label: e.target
                                                                .value,
                                                        })
                                                    }
                                                }}
                                            />
                                            <p>Utilizado como controle </p>
                                        </div>
                                        {selectedCia !== 'LAN' &&
                                            selectedCia !== 'ADR' && (
                                                <>
                                                    <div className="domain-code">
                                                        <Input
                                                            name="domain"
                                                            labelTitle="Domínio"
                                                            placeholder={
                                                                selectedCia ===
                                                                'LAS'
                                                                    ? 'AA'
                                                                    : ''
                                                            }
                                                            disabled={
                                                                selectedCia ===
                                                                    '2Z' ||
                                                                !!initialData
                                                            }
                                                        />
                                                    </div>
                                                    <div className="organization">
                                                        <Input
                                                            name="organization"
                                                            labelTitle={`Organização ${
                                                                selectedCia ===
                                                                'LAS'
                                                                    ? '(PCC)'
                                                                    : ''
                                                            }`}
                                                            placeholder={
                                                                selectedCia ===
                                                                'LAS'
                                                                    ? 'AAAA'
                                                                    : ''
                                                            }
                                                            disabled={
                                                                selectedCia ===
                                                                    '2Z' ||
                                                                selectedCia ===
                                                                    'AD' ||
                                                                !!initialData
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        {selectedCia === 'LAN' && (
                                            <>
                                                <div className="domain-code">
                                                    <Input
                                                        name="agencyIata"
                                                        labelTitle="agencyIata"
                                                        placeholder="agencyIata"
                                                    />
                                                </div>
                                                <div className="organization">
                                                    <Input
                                                        name="agencyId"
                                                        labelTitle="agencyId"
                                                        placeholder="agencyId"
                                                    />
                                                </div>
                                                <div className="label">
                                                    <Input
                                                        name="travelAgentId"
                                                        labelTitle="travelAgentId"
                                                        placeholder="travelAgentId"
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {selectedCia === 'ADR' && (
                                            <>
                                                <div className="domain-code">
                                                    <Input
                                                        name="apiKey"
                                                        labelTitle="apiKey"
                                                        placeholder="apiKey"
                                                    />
                                                </div>
                                                <div className="organization">
                                                    <Input
                                                        name="userAgent"
                                                        labelTitle="userAgent"
                                                        placeholder="userAgent"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="button">
                                        <PrimaryButton
                                            color="primary"
                                            title={
                                                initialData
                                                    ? 'Salvar'
                                                    : 'Cadastrar'
                                            }
                                            busy={busyBtn}
                                            isSubmit
                                        />
                                    </div>
                                </>
                            )}
                        </Form>
                    </ModalContent>
                )}
            </>
        </ModalContainer>
    )
}
