/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import React, { useEffect, useRef, useState } from 'react'
import ModalContainer from 'react-modal'
import * as Yup from 'yup'
import ReactSelect from 'react-select'
import { ClipLoader } from 'react-spinners'
import { Airline } from '../../entitie/airline'
import { User } from '../../entitie/user'
import { useBooking } from '../../hooks/use-booking'
import { useToast } from '../../hooks/use-toast'
import { useUpload } from '../../hooks/use-upload'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { Button } from '../Button'
import { Input } from '../Input'
import { ImportBookingContainer, MultipleBookingsBtn } from './styles'
import { useUser } from '../../hooks/use-user'
import { TutorialSteps } from '../../entitie/tutorial-steps'

interface Props {
    isOpen: boolean
    user?: User
    onRequestClose: () => void
    airlines?: Airline[]
}

export function ImportBooking({ isOpen, onRequestClose, airlines }: Props) {
    // hooks
    const {
        searchSingleBooking,
        toggleBookingDetail,
        searchMultipleBookings,
        refresh,
        toggleImportBookinfg,
    } = useBooking()
    const { getUpload } = useUpload()
    const { addToast } = useToast()
    const { user, editProfile, subscription, getSubscription } = useUser()

    // ref
    const formRef = useRef<FormHandles>(null)

    // state
    const [busyImportSingleBtn, setBusyImportSingleBtn] = useState(false)
    const [busyImportMultipleBtn, setBusyImportMultipleBtn] = useState(false)
    const [busy, setBusy] = useState(false)

    const [csv, setCsv] = useState<any>()
    const [selectedCia, setSelectedCia] = useState<{
        value: string
        label: string
    }>()

    useEffect(() => {
        setBusy(true)
        ;(async () => {
            await getSubscription()
            setBusy(false)
        })()
    }, [])

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <ImportBookingContainer>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <div className="header">
                    <h1>Importar bilhetes</h1>
                    <p>
                        Importe múltiplos bilhetes com uma planilha ou os
                        consulte individualmente.
                    </p>
                    <>
                        {!busy ? (
                            <>
                                {(subscription?.subscription.plan.type ===
                                    'FREE' ||
                                    subscription?.subscription.plan.type ===
                                        'AGENCY' ||
                                    subscription?.subscription.plan.type ===
                                        'BUSINESS') && (
                                    <p>
                                        Bilhetes restantes esse mês:
                                        <span> {user.availableCredits}</span>
                                    </p>
                                )}
                            </>
                        ) : null}
                    </>
                </div>
                <div className="multiple-bookings">
                    <div className="title">
                        <h1>Importar planilha</h1>
                        <p>
                            Baixe nosso modelo de planilha{' '}
                            <a
                                href="https://ada-benv-bucket.s3.sa-east-1.amazonaws.com/PlanilhaModelo/Planilha+modelo+importa%C3%A7%C3%A3o.xlsx"
                                about="planilha modelo"
                            >
                                aqui
                            </a>
                            .
                        </p>
                    </div>
                    <div className="row">
                        <MultipleBookingsBtn>
                            <label htmlFor="multiple-bookings">
                                <div className="container">
                                    {csv ? (
                                        <p>{csv.name}</p>
                                    ) : (
                                        <p>
                                            Clique aqui para selecionar o
                                            arquivo
                                        </p>
                                    )}
                                </div>
                                <input
                                    type="file"
                                    id="multiple-bookings"
                                    className="multiple-bookings"
                                    onChange={(e: any) => {
                                        setCsv(e.target.files[0])
                                    }}
                                />
                            </label>
                        </MultipleBookingsBtn>
                        <button
                            type="button"
                            className="import-btn"
                            onClick={async () => {
                                setBusyImportMultipleBtn(true)
                                if (!csv) {
                                    addToast({
                                        title: 'Atenção',
                                        description:
                                            'Você precisa selecionar um arquivo.',
                                        type: 'info',
                                    })
                                    setBusyImportMultipleBtn(false)
                                    return
                                }
                                const response = await searchMultipleBookings(
                                    csv
                                )
                                if (response) {
                                    if (
                                        user.tutorialStep ===
                                        TutorialSteps.importBooking
                                    ) {
                                        await editProfile({
                                            tutorialStep:
                                                TutorialSteps.finished,
                                        })
                                        window.location.reload()
                                    }
                                    const responseUpload = await getUpload()
                                    if (responseUpload) {
                                        setBusyImportMultipleBtn(false)
                                        toggleImportBookinfg(false)
                                    }
                                }
                                setBusyImportMultipleBtn(false)
                            }}
                        >
                            {busyImportMultipleBtn ? (
                                <ClipLoader size={16} color="#fff" />
                            ) : (
                                'Importar'
                            )}
                        </button>
                    </div>
                </div>
                <div className="single-booking">
                    <div className="title">
                        <h1>Importar bilhetes individualmente</h1>
                    </div>
                    <div className="import-single-booking">
                        <Form
                            ref={formRef}
                            onSubmit={async (data) => {
                                setBusyImportSingleBtn(true)
                                try {
                                    formRef.current?.setErrors({})
                                    if (selectedCia?.value === 'LA') {
                                        const schema = Yup.object().shape({
                                            identifier:
                                                Yup.string().required(
                                                    'Campo obrigatório'
                                                ),
                                        })
                                        await schema.validate(data, {
                                            abortEarly: false,
                                        })
                                    }
                                    if (!selectedCia?.value) {
                                        addToast({
                                            title: 'Atenção',
                                            description:
                                                'Você precisa selecionar uma cia.',
                                            type: 'info',
                                        })
                                        setBusyImportSingleBtn(false)
                                        return
                                    }
                                    const response = await searchSingleBooking({
                                        airlineIataCode:
                                            selectedCia?.value || '',
                                        identifier:
                                            data.identifier.toUpperCase(),
                                        client: data.client,
                                        costCenter: data.costCenter,
                                        serviceOrderNumber:
                                            data.serviceOrderNumber,
                                        cpf: data.cpf,
                                    })

                                    setBusyImportSingleBtn(false)
                                    if (response) {
                                        if (
                                            user.tutorialStep ===
                                            TutorialSteps.importBooking
                                        ) {
                                            await editProfile({
                                                tutorialStep:
                                                    TutorialSteps.finished,
                                            })
                                            window.location.reload()
                                        }
                                        refresh()
                                        toggleBookingDetail(response.id || '23')
                                        onRequestClose()
                                    }
                                } catch (error) {
                                    setBusyImportSingleBtn(false)
                                    if (error instanceof Yup.ValidationError) {
                                        const errors =
                                            getValidationErrors(error)
                                        formRef.current?.setErrors(errors)
                                    }
                                }
                            }}
                        >
                            <ReactSelect
                                name="pointOfSaleId"
                                placeholder="Companhia aérea*"
                                options={airlines?.map((airline) => {
                                    return {
                                        value: airline.iataCode,
                                        label: airline.label,
                                    }
                                })}
                                onChange={(e: any) => {
                                    setSelectedCia(e)
                                }}
                            />
                            {selectedCia?.value === 'LA' ||
                            selectedCia?.value === 'G3' ? (
                                <Input
                                    className="locator-input"
                                    placeholder="Número do bilhete*"
                                    name="identifier"
                                />
                            ) : (
                                <Input
                                    className="locator-input"
                                    placeholder="Localizador*"
                                    name="identifier"
                                />
                            )}
                            <Input
                                className="locator-input"
                                placeholder="Cliente"
                                name="client"
                            />
                            <Input
                                className="locator-input"
                                placeholder="Centro de custo"
                                name="costCenter"
                            />
                            <Input
                                className="locator-input"
                                placeholder="Ordem de serviço"
                                name="serviceOrderNumber"
                            />
                            <Input
                                className="locator-input"
                                placeholder="CPF"
                                name="cpf"
                            />
                            <Button
                                color="primary"
                                title="Importar"
                                isSubmit
                                busy={busyImportSingleBtn}
                            />
                        </Form>
                    </div>
                </div>
            </ImportBookingContainer>
        </ModalContainer>
    )
}
