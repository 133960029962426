/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/require-default-props */
import React from 'react'
import { addYears } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { utcToZonedTime, format } from 'date-fns-tz'
import { FiAlertTriangle, FiPlus } from 'react-icons/fi'
import { Booking } from '../../entitie/booking'
import { SinglePendingBookingContainer } from './styles'
import { BoardingStatus } from '../../entitie/boarding-status'
import { useBooking } from '../../hooks/use-booking'

interface Props {
    booking?: Booking
}

export function SinglePendingBooking({ booking }: Props) {
    // hooks
    const { toggleBookingDetail, isRecordLocator } = useBooking()

    function refundCredit(value?: number) {
        if (value === undefined || value === null) {
            return 'Dados insuficientes'
        }

        if (value < 0) {
            return 'Taxa(s) de embarque'
        }
        return `R$ ${value.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}`
    }

    function boardingStatusLabel(status?: BoardingStatus) {
        if (status === BoardingStatus.COMPLETELY_FLOWN) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(23, 158, 233, 0.2)',
                        color: '#179EE9',
                    }}
                >
                    Voado
                </span>
            )
        }
        if (status === BoardingStatus.NOT_FLOWN) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(231, 75, 112, 0.2)',
                        color: '#E74B70',
                    }}
                >
                    Não voado
                </span>
            )
        }
        if (status === BoardingStatus.PARTIALLY_FLOWN) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(241, 197, 35, 0.2)',
                        color: '#F1C523',
                    }}
                >
                    Parcialmente voado
                </span>
            )
        }
        return 'Informações insuficientes'
    }

    return (
        <SinglePendingBookingContainer
            names={`${booking?.passengers.map((name) => `${name}, `)}`}
        >
            <div className="start passanger-name">
                <div className="row">
                    <h1 className="start">{booking?.passengers[0]}</h1>
                    {booking && booking.passengers.length > 1 && (
                        <div className="more-passangers">
                            <FiPlus />
                        </div>
                    )}
                </div>
            </div>
            <h1 className="start">
                {isRecordLocator
                    ? booking?.recordLocator
                    : booking?.ticketNumber || '-'}
            </h1>
            <h1 className="center">{booking?.airline.label}</h1>
            <div className="center status">
                {boardingStatusLabel(booking?.boardingStatus)}
            </div>
            <div className="center relative">
                <h1>
                    {booking?.reissueCredit !== null &&
                    booking?.reissueCredit !== undefined
                        ? `R$ ${
                              booking?.reissueCredit.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                              }) || 0
                          }`
                        : 'Dados insuficientes'}
                </h1>
                {booking?.isCovid && <FiAlertTriangle />}
            </div>
            <h1 className="center">{refundCredit(booking?.refundCredit)}</h1>
            <h1 className="center">
                {booking && booking.refundExpirationDate
                    ? format(
                          utcToZonedTime(
                              new Date(booking.refundExpirationDate),
                              'Europe/Berlin'
                          ),
                          `dd'/'MM'/'Y`
                      )
                    : `${format(
                          addYears(
                              utcToZonedTime(
                                  new Date(booking?.issueDate || ''),
                                  'Europe/Berlin'
                              ) || 0,
                              1
                          ),
                          `dd'/'MM'/'Y`
                      )}`}
            </h1>
            <button
                className="end info"
                type="button"
                onClick={() => toggleBookingDetail(booking?.id)}
            >
                Ver mais
            </button>
            {/* {showBookingDetail === booking?.id && (
                <BookingDetail
                    isOpen={!!showBookingDetail}
                    onRequestClose={() => toggleBookingDetail(undefined)}
                    bookingId={booking?.id}
                />
            )} */}
        </SinglePendingBookingContainer>
    )
}
