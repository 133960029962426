import styled from 'styled-components'

export const FiltersContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 3rem;
`

export const AnalyticalPageContainer = styled.div`
    width: 100%;
    background-color: #fff;
    border: 1px solid #c7d3d1;
    border-radius: 1.6rem;
    padding: 4rem;
    .first-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .nav {
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                position: relative;
                height: 5.7rem;
                background-color: inherit;
                outline: none;
                border: none;
                border-bottom: 1px solid #c2c2c2;
                display: flex;
                align-items: center;
                padding: 0 1.5rem;

                h1 {
                    font-size: 1.8rem;
                }
                svg {
                    margin-right: 1rem;
                    height: 1.8rem;
                    width: 1.8rem;
                }
                div {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 0.5rem;
                    width: 100%;
                    background-color: transparent;
                    visibility: none;
                    border-top-right-radius: 0.5rem;
                    border-top-left-radius: 0.5rem;
                    transition: all 0.4s ease;
                }
                .bordered {
                    background-color: #333;
                }
                &:hover {
                    div {
                        background-color: #333;
                        visibility: initial;
                    }
                }
            }
        }
    }
    .legend {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        p {
            color: rgba(51, 51, 51, 0.62);
            font-size: 1.6rem;
            font-weight: 400;
        }
        svg {
            color: #f1c523;
            height: 2rem;
            width: 2rem;
            margin-right: 1rem;
        }
    }
`

export const Table = styled.div`
    .primary-row {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 2fr 1fr 1fr;
        align-items: center;

        grid-template-rows: min-content;
        padding: 4.5rem 0;
        .center {
            text-align: center;
        }
        .start {
            text-align: start;
        }
        .end {
            text-align: end;
        }
        .switch-btn {
            border: none;
            background-color: inherit;
            font-size: 1.6rem;
            font-weight: 500;
            color: #929292;
            outline: none;
            svg {
                height: 2rem;
                width: 2rem;
                margin-left: 1rem;
            }
        }
        h1 {
            font-size: 1.6rem;
            font-weight: 500;
            color: #929292;
        }
    }
`

export const TablePending = styled.div`
    .primary-row {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr;
        align-items: center;

        grid-template-rows: min-content;
        padding: 4.5rem 0;
        .center {
            text-align: center;
        }
        .start {
            text-align: start;
        }
        .end {
            text-align: end;
        }
        .switch-btn {
            border: none;
            background-color: inherit;
            font-size: 1.6rem;
            font-weight: 500;
            color: #929292;
            outline: none;
            svg {
                height: 2rem;
                width: 2rem;
                margin-left: 1rem;
            }
        }
        h1 {
            font-size: 1.6rem;
            font-weight: 500;
            color: #929292;
        }
    }
`

export const PaginationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
    .MuiPagination-root {
        ul {
            li {
                button {
                    font-size: 1.3rem;
                }
            }
        }
    }
`
