import styled from 'styled-components'

export const PrivacyPoliciesContainer = styled.div`
    margin-top: 2rem;
    padding: 2rem;
    width: 70rem;
    max-height: 80vh;
    overflow-y: scroll;
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background-color: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        padding: 1rem;
        outline: none;
        transition: all 0.3s;
        &:hover {
            transform: rotate(180deg) scale(1.2);
        }
    }
    .content {
        font-size: 14px;
        h1 {
            font-size: 20px;
            margin-bottom: 20px;
        }
        p {
            font-size: 14px;

            span {
                font-weight: bold;
            }
        }
        ul {
            list-style: none;
        }
        .titles {
            .title {
                font-weight: bold;
            }
        }
        .subtitle {
            margin: 5px 0 5px 10px;
        }
        .subsubtitles {
            margin: 5px 0 5px 10px;
        }
    }
`
