import styled from 'styled-components'

export const BigLoaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 0;
    left: 0;
    width: 100%;
    height: 100%;
`
