/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { IconBaseProps } from 'react-icons'
import { FiAlertCircle } from 'react-icons/fi'
import { useField } from '@unform/core'

import { Error, InitialInputContainer } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    labelTitle: string
    icon?: React.ComponentType<IconBaseProps>
}

export function InitialInput({ name, labelTitle, icon: Icon, ...rest }: Props) {
    // Refs
    const inputRef = useRef<HTMLInputElement>(null)
    const { fieldName, defaultValue, error, registerField } = useField(name)

    // state
    const [disableError, setDisableError] = useState(false)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        })
        setDisableError(false)
    }, [fieldName, registerField, error])

    return (
        <InitialInputContainer error={!!error && !disableError}>
            <input
                defaultValue={defaultValue}
                placeholder={labelTitle}
                onChange={() => setDisableError(true)}
                id={name}
                ref={inputRef}
                {...rest}
            />
            {error && !disableError ? (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            ) : null}
        </InitialInputContainer>
    )
}
