/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import { FiDollarSign } from 'react-icons/fi'
import { AiOutlinePercentage, AiOutlineFieldNumber } from 'react-icons/ai'
import { SwitchButtonContainer } from './styles'

interface Props {
    callback?(): void
    isNumber: boolean
}

export function SwitchButton({ callback, isNumber }: Props) {
    const [isPercentage, setIsPercentage] = useState(true)
    return (
        <SwitchButtonContainer
            type="button"
            onClick={() => {
                if (callback) callback()
                setIsPercentage(!isPercentage)
            }}
        >
            {isNumber ? <AiOutlineFieldNumber /> : <FiDollarSign />}
            <AiOutlinePercentage />
            <div className={`selector ${!isPercentage && 'left'}`} />
        </SwitchButtonContainer>
    )
}
