/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import {
    AiFillTag,
    AiFillCloseCircle,
    AiOutlineCheck,
    AiOutlineFieldNumber,
} from 'react-icons/ai'
import ptLocale from 'date-fns/locale/pt-BR'
import { FaUser, FaArrowDown } from 'react-icons/fa'
import {
    format,
    startOfMonth,
    startOfQuarter,
    subMonths,
    endOfMonth,
    subQuarters,
    endOfQuarter,
    startOfYear,
    subYears,
    endOfYear,
    startOfDay,
} from 'date-fns'
import { v4 } from 'uuid'
import { FiSearch } from 'react-icons/fi'
import { Airline } from '../../entitie/airline'
import { BoardingStatus } from '../../entitie/boarding-status'
import { Filters } from '../../entitie/filters'
import { useBooking } from '../../hooks/use-booking'
import {
    DropdownFilter,
    FilterContainer,
    PeriodDropDown,
    SelectButtons,
    Selector,
    TextFilter,
} from './styles'
import { DatePicker } from '../DatePicker'
import { SearchInput } from '../SearchInput'

interface Props {
    airlines?: Airline[]
    filters?: Filters
    type?: 'ANALYTICAL' | 'SYNTHETIC'
}

export function FiltersComponent({ airlines, filters, type }: Props) {
    // hooks
    const { filterBookings, handleFilterBookings } = useBooking()

    // state
    const [selectedCia, setSelectedCia] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(false)
    const [selectedClient, setSelectedClient] = useState(false)
    const [selectedCostCenter, setSelectedCostCenter] = useState(false)
    const [selectedPeriod, setSelectedPeriod] = useState(false)
    const [selectedAgency, setSelectedAgency] = useState(false)
    const [openSelecteCustomData, setOpenSelectCustomData] = useState(false)
    const [filterCostCenter, setFilterCostCenter] = useState('')
    const [filterClients, setFilterClients] = useState('')
    const [filterAgency, setFilterAgency] = useState('')

    const boardingStatus: BoardingStatus[] = [
        BoardingStatus.COMPLETELY_FLOWN,
        BoardingStatus.NOT_FLOWN,
        BoardingStatus.PARTIALLY_FLOWN,
    ]

    function periodLabel(
        period:
            | 'ALL_TIME'
            | 'THIS_MONTH'
            | 'THIS_TRIMESTER'
            | 'THIS_YEAR'
            | 'LAST_MONTH'
            | 'LAST_TRIMESTER'
            | 'LAST_YEAR'
            | 'SELECT_DATE'
            | 'YEAR'
            | 'CUSTOM'
            | undefined
    ) {
        if (period === 'ALL_TIME') {
            return 'Todo o tempo'
        }
        if (period === 'THIS_MONTH') {
            return 'Este mês'
        }
        if (period === 'THIS_TRIMESTER') {
            return 'Este trimestre'
        }
        if (period === 'THIS_YEAR') {
            return 'Este ano'
        }
        if (period === 'LAST_MONTH') {
            return 'Último mês'
        }
        if (period === 'LAST_TRIMESTER') {
            return 'Último trimestre'
        }
        if (period === 'LAST_YEAR') {
            return 'Último ano'
        }
        if (period === 'YEAR') {
            return 'Últimos 12 meses'
        }
        return 'Período customizado'
    }

    function boardingStatusLabel(s: BoardingStatus) {
        if (s === BoardingStatus.COMPLETELY_FLOWN) {
            return 'Voado '
        }
        if (s === BoardingStatus.PARTIALLY_FLOWN) {
            return 'Parcialmente voado '
        }
        return 'Não voado '
    }

    return (
        <FilterContainer>
            <div className="second-row">
                <PeriodDropDown>
                    <label
                        htmlFor="period"
                        className={`selector-label ${
                            filterBookings?.typeOfPeriod !== 'YEAR'
                                ? 'bordered'
                                : ''
                        }`}
                    >
                        <input
                            type="checkbox"
                            name="period"
                            checked={selectedPeriod}
                            id="period"
                            className="selector-input"
                            onChange={(e) =>
                                setSelectedPeriod(e.target.checked)
                            }
                        />
                        {filterBookings?.typeOfPeriod !== 'YEAR' ? (
                            <h4>{periodLabel(filterBookings?.typeOfPeriod)}</h4>
                        ) : (
                            <p>Últimos 12 meses</p>
                        )}
                        {filterBookings?.typeOfPeriod !== 'YEAR' ? (
                            <AiFillCloseCircle
                                style={{ height: '1.6rem', width: '1.6rem' }}
                                onClick={() => {
                                    handleFilterBookings({
                                        ...filterBookings,
                                        showExpired: false,
                                        period: {
                                            startDate: startOfDay(
                                                subYears(new Date(), 1)
                                            ),
                                            endDate: new Date(),
                                        },
                                        typeOfPeriod: 'YEAR',
                                    })
                                }}
                            />
                        ) : (
                            <FaArrowDown />
                        )}
                        <div className="selectors">
                            <div className="title">
                                <h1>Selecione o periodo</h1>
                                <div className="btns">
                                    <button
                                        className="clear-all"
                                        type="button"
                                        onClick={() => {
                                            handleFilterBookings({
                                                ...filterBookings,
                                                period: {
                                                    startDate: startOfDay(
                                                        subYears(new Date(), 1)
                                                    ),
                                                    endDate: new Date(),
                                                },
                                                typeOfPeriod: 'YEAR',
                                            })
                                        }}
                                    >
                                        Limpar
                                    </button>
                                </div>
                            </div>
                            <SelectButtons>
                                {type === 'ANALYTICAL' && (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleFilterBookings({
                                                ...filterBookings,
                                                period: undefined,
                                                typeOfPeriod: 'ALL_TIME',
                                            })
                                        }}
                                        className={`${
                                            filterBookings?.typeOfPeriod ===
                                            'ALL_TIME'
                                                ? 'selected'
                                                : ''
                                        } `}
                                    >
                                        <p>Todo o tempo</p>
                                        <AiOutlineCheck />
                                    </button>
                                )}
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            typeOfPeriod: 'THIS_MONTH',
                                            showExpired: false,
                                            period: {
                                                startDate: startOfMonth(
                                                    new Date()
                                                ),
                                                endDate: new Date(),
                                            },
                                        })
                                    }}
                                    className={`${
                                        filterBookings?.typeOfPeriod ===
                                        'THIS_MONTH'
                                            ? 'selected'
                                            : ''
                                    } `}
                                >
                                    Este mês
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            typeOfPeriod: 'THIS_TRIMESTER',
                                            showExpired: false,
                                            period: {
                                                startDate: startOfQuarter(
                                                    new Date()
                                                ),
                                                endDate: new Date(),
                                            },
                                        })
                                    }}
                                    className={`${
                                        filterBookings?.typeOfPeriod ===
                                        'THIS_TRIMESTER'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Este trimestre
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            typeOfPeriod: 'THIS_YEAR',
                                            showExpired: false,
                                            period: {
                                                startDate: startOfYear(
                                                    new Date()
                                                ),
                                                endDate: new Date(),
                                            },
                                        })
                                    }}
                                    className={`${
                                        filterBookings?.typeOfPeriod ===
                                        'THIS_YEAR'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Este ano
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            showExpired: false,
                                            period: {
                                                startDate: startOfDay(
                                                    subYears(new Date(), 1)
                                                ),
                                                endDate: new Date(),
                                            },
                                            typeOfPeriod: 'YEAR',
                                        })
                                    }}
                                    className={`${
                                        filterBookings?.typeOfPeriod === 'YEAR'
                                            ? 'selected'
                                            : ''
                                    } `}
                                >
                                    Últimos 12 meses
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            showExpired: false,
                                            typeOfPeriod: 'LAST_MONTH',
                                            period: {
                                                startDate: startOfMonth(
                                                    subMonths(new Date(), 1)
                                                ),
                                                endDate: endOfMonth(
                                                    subMonths(new Date(), 1)
                                                ),
                                            },
                                        })
                                    }}
                                    className={`${
                                        filterBookings?.typeOfPeriod ===
                                        'LAST_MONTH'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Último mês
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            showExpired: false,
                                            typeOfPeriod: 'LAST_TRIMESTER',
                                            period: {
                                                startDate: startOfQuarter(
                                                    subQuarters(new Date(), 1)
                                                ),
                                                endDate: endOfQuarter(
                                                    subQuarters(new Date(), 1)
                                                ),
                                            },
                                        })
                                    }}
                                    className={`${
                                        filterBookings?.typeOfPeriod ===
                                        'LAST_TRIMESTER'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Último trimestre
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            showExpired: false,
                                            typeOfPeriod: 'LAST_YEAR',
                                            period: {
                                                startDate: startOfYear(
                                                    subYears(new Date(), 1)
                                                ),
                                                endDate: endOfYear(
                                                    subYears(new Date(), 1)
                                                ),
                                            },
                                        })
                                    }}
                                    className={`${
                                        filterBookings?.typeOfPeriod ===
                                        'LAST_YEAR'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Último ano
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setOpenSelectCustomData(true)
                                        setSelectedPeriod(false)
                                    }}
                                    className={`${
                                        filterBookings?.typeOfPeriod ===
                                        'CUSTOM'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Período customizado
                                    <AiOutlineCheck />
                                </button>
                            </SelectButtons>
                        </div>
                    </label>
                </PeriodDropDown>
                {filterBookings?.period && (
                    <p>{`Exibindo bilhetes emitidos de ${format(
                        new Date(filterBookings?.period?.startDate || 0),
                        `dd'/'MM'/'yyyy`,
                        {
                            locale: ptLocale,
                        }
                    )} - ${format(
                        new Date(filterBookings?.period?.endDate || 0),
                        `dd'/'MM'/'yyyy`,
                        {
                            locale: ptLocale,
                        }
                    )}`}</p>
                )}
                {!filterBookings?.period && (
                    <>
                        {!filterBookings?.typeOfPeriod ? (
                            <p>{` Exibindo bilhetes emitidos de ${format(
                                subYears(new Date(), 1),
                                `dd'/'MM'/'Y`,
                                {
                                    locale: ptLocale,
                                }
                            )} - ${format(new Date(), `dd'/'MM'/'Y`, {
                                locale: ptLocale,
                            })}`}</p>
                        ) : (
                            <p>Todos os bilhetes</p>
                        )}
                    </>
                )}
            </div>
            <div
                className={`first-row ${
                    type === 'ANALYTICAL' ? 'margin-bottom' : ''
                }`}
            >
                {/* ----------- CENTRO DE CUSTO ------------- */}
                <DropdownFilter>
                    <label
                        htmlFor="costCenter"
                        className={`selector-label ${
                            filterBookings?.costsCenters &&
                            filterBookings.costsCenters.length > 0
                                ? 'bordered'
                                : ''
                        }`}
                    >
                        <input
                            type="checkbox"
                            name="costCenter"
                            checked={selectedCostCenter}
                            id="costCenter"
                            className="selector-input"
                            onChange={(e) =>
                                setSelectedCostCenter(e.target.checked)
                            }
                        />
                        {filterBookings?.costsCenters &&
                        filterBookings.costsCenters.length > 0 ? (
                            <h4>
                                {filterBookings.costsCenters.map(
                                    (center) => `${center} `
                                )}
                            </h4>
                        ) : (
                            <p>Centro de custo</p>
                        )}
                        {filterBookings?.costsCenters &&
                        filterBookings.costsCenters.length > 0 ? (
                            <AiFillCloseCircle
                                style={{ height: '1.6rem', width: '1.6rem' }}
                                onClick={() =>
                                    handleFilterBookings({
                                        ...filterBookings,
                                        costsCenters: undefined,
                                    })
                                }
                            />
                        ) : (
                            <FaArrowDown />
                        )}
                        <div className="selectors">
                            <div className="title">
                                <h1>Centro de custo</h1>
                                <div className="btns">
                                    <button
                                        className="select-all"
                                        type="button"
                                        onClick={() => {
                                            handleFilterBookings({
                                                ...filterBookings,
                                                costsCenters:
                                                    filters?.costCenters?.map(
                                                        (center) => center
                                                    ),
                                            })
                                        }}
                                    >
                                        Selecionar todos
                                    </button>
                                    <button
                                        className="clear-all"
                                        type="button"
                                        onClick={() => {
                                            handleFilterBookings({
                                                ...filterBookings,
                                                costsCenters: undefined,
                                            })
                                        }}
                                    >
                                        Limpar
                                    </button>
                                </div>
                            </div>
                            <div className="filter-input">
                                <SearchInput
                                    name="constCenterSearch"
                                    label="Pesquisar"
                                    icon={FiSearch}
                                    onChange={(e) => {
                                        setFilterCostCenter(
                                            e.target.value.toLowerCase()
                                        )
                                    }}
                                />
                            </div>
                            {filters?.costCenters &&
                                filters?.costCenters
                                    .filter((c) =>
                                        c
                                            .toLowerCase()
                                            .includes(filterCostCenter)
                                    )
                                    .map((center) => {
                                        return (
                                            <Selector key={v4()}>
                                                <label
                                                    htmlFor={`center-${center}`}
                                                    className="select-label"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={`center-${center}`}
                                                        className="center"
                                                        checked={
                                                            !!filterBookings?.costsCenters?.find(
                                                                (c) =>
                                                                    c === center
                                                            )
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                handleFilterBookings(
                                                                    {
                                                                        ...filterBookings,
                                                                        costsCenters:
                                                                            filterBookings?.costsCenters
                                                                                ? [
                                                                                      ...filterBookings.costsCenters,
                                                                                      center,
                                                                                  ]
                                                                                : [
                                                                                      center,
                                                                                  ],
                                                                    }
                                                                )
                                                            } else if (
                                                                filterBookings?.costsCenters
                                                            ) {
                                                                const temp: string[] =
                                                                    filterBookings.costsCenters

                                                                const index =
                                                                    temp.findIndex(
                                                                        (t) =>
                                                                            t ===
                                                                            center
                                                                    )
                                                                temp.splice(
                                                                    index,
                                                                    1
                                                                )
                                                                handleFilterBookings(
                                                                    {
                                                                        ...filterBookings,
                                                                        costsCenters:
                                                                            temp,
                                                                    }
                                                                )
                                                            }
                                                        }}
                                                    />
                                                    <p>{center}</p>
                                                </label>
                                            </Selector>
                                        )
                                    })}
                        </div>
                    </label>
                </DropdownFilter>
                {/* ----------- CLIENTES -------------------- */}
                <DropdownFilter>
                    <label
                        htmlFor="client"
                        className={`selector-label ${
                            filterBookings?.client &&
                            filterBookings.client.length > 0
                                ? 'bordered'
                                : ''
                        }`}
                    >
                        <input
                            type="checkbox"
                            name="client"
                            checked={selectedClient}
                            id="client"
                            className="selector-input"
                            onChange={(e) =>
                                setSelectedClient(e.target.checked)
                            }
                        />
                        {filterBookings?.client &&
                        filterBookings.client.length > 0 ? (
                            <h4>
                                {filterBookings.client.map(
                                    (client) => `${client} `
                                )}
                            </h4>
                        ) : (
                            <p>Clientes</p>
                        )}
                        {filterBookings?.client &&
                        filterBookings.client.length > 0 ? (
                            <AiFillCloseCircle
                                style={{ height: '1.6rem', width: '1.6rem' }}
                                onClick={() =>
                                    handleFilterBookings({
                                        ...filterBookings,
                                        client: undefined,
                                    })
                                }
                            />
                        ) : (
                            <FaArrowDown />
                        )}
                        <div className="selectors">
                            <div className="title">
                                <h1>Clientes</h1>
                                <div className="btns">
                                    <button
                                        className="select-all"
                                        type="button"
                                        onClick={() => {
                                            handleFilterBookings({
                                                ...filterBookings,
                                                client: filters?.clients?.map(
                                                    (client) => client
                                                ),
                                            })
                                        }}
                                    >
                                        Selecionar todos
                                    </button>
                                    <button
                                        className="clear-all"
                                        type="button"
                                        onClick={() => {
                                            handleFilterBookings({
                                                ...filterBookings,
                                                client: undefined,
                                            })
                                        }}
                                    >
                                        Limpar
                                    </button>
                                </div>
                            </div>
                            <div className="filter-input">
                                <SearchInput
                                    name="constCenterSearch"
                                    label="Pesquisar"
                                    icon={FiSearch}
                                    onChange={(e) => {
                                        setFilterClients(
                                            e.target.value.toLowerCase()
                                        )
                                    }}
                                />
                            </div>
                            {filters?.clients &&
                                filters?.clients
                                    ?.filter((c) =>
                                        c.toLowerCase().includes(filterClients)
                                    )
                                    .map((client) => {
                                        return (
                                            <Selector key={v4()}>
                                                <label
                                                    htmlFor={`client-${client}`}
                                                    className="select-label"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={`client-${client}`}
                                                        className={`client-${client}`}
                                                        checked={
                                                            !!filterBookings?.client?.find(
                                                                (c) =>
                                                                    c === client
                                                            )
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                handleFilterBookings(
                                                                    {
                                                                        ...filterBookings,
                                                                        client: filterBookings?.client
                                                                            ? [
                                                                                  ...filterBookings.client,
                                                                                  client,
                                                                              ]
                                                                            : [
                                                                                  client,
                                                                              ],
                                                                    }
                                                                )
                                                            } else if (
                                                                filterBookings?.client
                                                            ) {
                                                                const temp: string[] =
                                                                    filterBookings.client

                                                                const index =
                                                                    temp.findIndex(
                                                                        (t) =>
                                                                            t ===
                                                                            client
                                                                    )
                                                                temp.splice(
                                                                    index,
                                                                    1
                                                                )
                                                                handleFilterBookings(
                                                                    {
                                                                        ...filterBookings,
                                                                        client: temp,
                                                                    }
                                                                )
                                                            }
                                                        }}
                                                    />
                                                    <p>{client}</p>
                                                </label>
                                            </Selector>
                                        )
                                    })}
                        </div>
                    </label>
                </DropdownFilter>
                {/* ----------- AGÊNCIA  -------------------- */}
                <DropdownFilter>
                    <label
                        htmlFor="agency"
                        className={`selector-label ${
                            filterBookings?.agencies &&
                            filterBookings.agencies.length > 0
                                ? 'bordered'
                                : ''
                        }`}
                    >
                        <input
                            type="checkbox"
                            name="agency"
                            checked={selectedAgency}
                            id="agency"
                            className="selector-input"
                            onChange={(e) =>
                                setSelectedAgency(e.target.checked)
                            }
                        />
                        {filterBookings?.agencies &&
                        filterBookings.agencies.length > 0 ? (
                            <h4>
                                {filterBookings.agencies.map(
                                    (agency) =>
                                        `${
                                            filters?.agencies.find(
                                                (a) => a.id === agency
                                            )?.name
                                        } `
                                )}
                            </h4>
                        ) : (
                            <p>Agências</p>
                        )}
                        {filterBookings?.agencies &&
                        filterBookings.agencies.length > 0 ? (
                            <AiFillCloseCircle
                                style={{ height: '1.6rem', width: '1.6rem' }}
                                onClick={() =>
                                    handleFilterBookings({
                                        ...filterBookings,
                                        agencies: undefined,
                                    })
                                }
                            />
                        ) : (
                            <FaArrowDown />
                        )}
                        <div className="selectors">
                            <div className="title">
                                <h1>Agências</h1>
                                <div className="btns">
                                    <button
                                        className="select-all"
                                        type="button"
                                        onClick={() => {
                                            handleFilterBookings({
                                                ...filterBookings,
                                                agencies:
                                                    filters?.agencies?.map(
                                                        (agency) => agency.id
                                                    ),
                                            })
                                        }}
                                    >
                                        Selecionar todos
                                    </button>
                                    <button
                                        className="clear-all"
                                        type="button"
                                        onClick={() => {
                                            handleFilterBookings({
                                                ...filterBookings,
                                                agencies: undefined,
                                            })
                                        }}
                                    >
                                        Limpar
                                    </button>
                                </div>
                            </div>
                            <div className="filter-input">
                                <SearchInput
                                    name="constCenterSearch"
                                    label="Pesquisar"
                                    icon={FiSearch}
                                    onChange={(e) => {
                                        setFilterAgency(
                                            e.target.value.toLowerCase()
                                        )
                                    }}
                                />
                            </div>
                            {filters?.agencies &&
                                filters?.agencies
                                    ?.filter((a) =>
                                        a.name
                                            .toLowerCase()
                                            .includes(filterAgency)
                                    )
                                    .map((agency) => {
                                        return (
                                            <Selector key={v4()}>
                                                <label
                                                    htmlFor={agency.id}
                                                    className="select-label"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={agency.id}
                                                        className={agency.id}
                                                        checked={
                                                            !!filterBookings?.agencies?.find(
                                                                (a) =>
                                                                    a ===
                                                                    agency.id
                                                            )
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                handleFilterBookings(
                                                                    {
                                                                        ...filterBookings,
                                                                        agencies:
                                                                            filterBookings?.agencies
                                                                                ? [
                                                                                      ...filterBookings.agencies,
                                                                                      agency.id,
                                                                                  ]
                                                                                : [
                                                                                      agency.id,
                                                                                  ],
                                                                    }
                                                                )
                                                            } else if (
                                                                filterBookings?.agencies
                                                            ) {
                                                                const temp: string[] =
                                                                    filterBookings.agencies

                                                                const index =
                                                                    temp.findIndex(
                                                                        (t) =>
                                                                            t ===
                                                                            agency.id
                                                                    )
                                                                temp.splice(
                                                                    index,
                                                                    1
                                                                )
                                                                handleFilterBookings(
                                                                    {
                                                                        ...filterBookings,
                                                                        agencies:
                                                                            temp,
                                                                    }
                                                                )
                                                            }
                                                        }}
                                                    />
                                                    <p>{agency.name}</p>
                                                </label>
                                            </Selector>
                                        )
                                    })}
                        </div>
                    </label>
                </DropdownFilter>
                {/* ----------- STATUS DO VOO --------------- */}
            </div>
            {type === 'ANALYTICAL' && (
                <div className="first-row">
                    <DropdownFilter>
                        <label
                            htmlFor="cia"
                            className={`selector-label ${
                                filterBookings?.airline &&
                                filterBookings.airline.length > 0
                                    ? 'bordered'
                                    : ''
                            }`}
                        >
                            <input
                                type="checkbox"
                                name="cia"
                                checked={selectedCia}
                                id="cia"
                                className="selector-input"
                                onChange={(e) =>
                                    setSelectedCia(e.target.checked)
                                }
                            />
                            {filterBookings?.airline &&
                            filterBookings.airline.length > 0 ? (
                                <h4>
                                    {`${filterBookings.airline.map((id) => {
                                        if (
                                            airlines?.find(
                                                (airline) => airline.id === id
                                            )?.iataCode === 'LAS'
                                        ) {
                                            return null
                                        }
                                        return airlines?.find(
                                            (airline) => airline.id === id
                                        )?.label
                                    })} `}
                                </h4>
                            ) : (
                                <p>Companhias aéreas</p>
                            )}
                            {filterBookings?.airline &&
                            filterBookings.airline.length > 0 ? (
                                <AiFillCloseCircle
                                    style={{
                                        height: '1.6rem',
                                        width: '1.6rem',
                                    }}
                                    onClick={() =>
                                        handleFilterBookings({
                                            ...filterBookings,
                                            airline: undefined,
                                        })
                                    }
                                />
                            ) : (
                                <FaArrowDown />
                            )}
                            <div className="selectors">
                                <div className="title">
                                    <h1>Companhias</h1>
                                    <div className="btns">
                                        <button
                                            className="select-all"
                                            type="button"
                                            onClick={() => {
                                                handleFilterBookings({
                                                    ...filterBookings,
                                                    airline: airlines?.map(
                                                        (airline) => airline.id
                                                    ),
                                                })
                                            }}
                                        >
                                            Selecionar todos
                                        </button>
                                        <button
                                            className="clear-all"
                                            type="button"
                                            onClick={() => {
                                                handleFilterBookings({
                                                    ...filterBookings,
                                                    airline: undefined,
                                                })
                                            }}
                                        >
                                            Limpar
                                        </button>
                                    </div>
                                </div>
                                {airlines?.map((airline) => {
                                    if (airline.iataCode === 'LAS') {
                                        return null
                                    }
                                    return (
                                        <Selector key={v4()}>
                                            <label
                                                htmlFor={airline.id}
                                                className="select-label"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={airline.id}
                                                    className={airline.id}
                                                    checked={
                                                        !!filterBookings?.airline?.find(
                                                            (id) =>
                                                                id ===
                                                                airline.id
                                                        )
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            if (
                                                                airline.iataCode ===
                                                                'LA'
                                                            ) {
                                                                const airlineLatam =
                                                                    airline.id
                                                                const airlineSabre =
                                                                    airlines.find(
                                                                        (a) =>
                                                                            a.iataCode ===
                                                                            'LAS'
                                                                    )!.id

                                                                handleFilterBookings(
                                                                    {
                                                                        ...filterBookings,
                                                                        airline:
                                                                            filterBookings?.airline
                                                                                ? [
                                                                                      ...filterBookings.airline,
                                                                                      airlineSabre,
                                                                                      airlineLatam,
                                                                                  ]
                                                                                : [
                                                                                      airlineSabre,
                                                                                      airlineLatam,
                                                                                  ],
                                                                    }
                                                                )
                                                            } else {
                                                                handleFilterBookings(
                                                                    {
                                                                        ...filterBookings,
                                                                        airline:
                                                                            filterBookings?.airline
                                                                                ? [
                                                                                      ...filterBookings.airline,
                                                                                      airline.id,
                                                                                  ]
                                                                                : [
                                                                                      airline.id,
                                                                                  ],
                                                                    }
                                                                )
                                                            }
                                                        } else if (
                                                            filterBookings?.airline
                                                        ) {
                                                            let temp: string[] =
                                                                filterBookings.airline

                                                            if (
                                                                airline.iataCode ===
                                                                'LA'
                                                            ) {
                                                                const airlineLatam =
                                                                    airline.id
                                                                const airlineSabre =
                                                                    airlines.find(
                                                                        (a) =>
                                                                            a.iataCode ===
                                                                            'LAS'
                                                                    )!.id
                                                                temp =
                                                                    temp.filter(
                                                                        (t) =>
                                                                            t !==
                                                                                airlineLatam &&
                                                                            t !==
                                                                                airlineSabre
                                                                    )
                                                            } else {
                                                                const index =
                                                                    temp.findIndex(
                                                                        (t) =>
                                                                            t ===
                                                                            airline.id
                                                                    )
                                                                temp.splice(
                                                                    index,
                                                                    1
                                                                )
                                                            }
                                                            handleFilterBookings(
                                                                {
                                                                    ...filterBookings,
                                                                    airline:
                                                                        temp,
                                                                }
                                                            )
                                                        }
                                                    }}
                                                />
                                                <p>{airline.label}</p>
                                            </label>
                                        </Selector>
                                    )
                                })}
                            </div>
                        </label>
                    </DropdownFilter>
                    {/* ----------- CIA AÉREA ------------------- */}
                    <DropdownFilter>
                        <label
                            htmlFor="boardingStatus"
                            className={`selector-label ${
                                filterBookings?.boardingStatus &&
                                filterBookings.boardingStatus.length > 0
                                    ? 'bordered'
                                    : ''
                            }`}
                        >
                            <input
                                type="checkbox"
                                checked={selectedStatus}
                                name="boardingStatus"
                                id="boardingStatus"
                                className="selector-input"
                                onChange={(e) =>
                                    setSelectedStatus(e.target.checked)
                                }
                            />
                            {filterBookings?.boardingStatus &&
                            filterBookings.boardingStatus.length > 0 ? (
                                <h4>
                                    {filterBookings.boardingStatus.map(
                                        (status) => boardingStatusLabel(status)
                                    )}
                                </h4>
                            ) : (
                                <p>Status do voo</p>
                            )}
                            {filterBookings?.boardingStatus &&
                            filterBookings.boardingStatus.length > 0 ? (
                                <AiFillCloseCircle
                                    style={{
                                        height: '1.6rem',
                                        width: '1.6rem',
                                    }}
                                    onClick={() =>
                                        handleFilterBookings({
                                            ...filterBookings,
                                            boardingStatus: undefined,
                                        })
                                    }
                                />
                            ) : (
                                <FaArrowDown />
                            )}
                            <div className="selectors">
                                <div className="title">
                                    <h1>Status do vôo</h1>
                                    <div className="btns">
                                        <button
                                            className="select-all"
                                            type="button"
                                            onClick={() => {
                                                handleFilterBookings({
                                                    ...filterBookings,
                                                    boardingStatus:
                                                        boardingStatus?.map(
                                                            (status) => status
                                                        ),
                                                })
                                            }}
                                        >
                                            Selecionar todos
                                        </button>
                                        <button
                                            className="clear-all"
                                            type="button"
                                            onClick={() => {
                                                handleFilterBookings({
                                                    ...filterBookings,
                                                    boardingStatus: undefined,
                                                })
                                            }}
                                        >
                                            Limpar
                                        </button>
                                    </div>
                                </div>
                                {boardingStatus?.map((status) => {
                                    return (
                                        <Selector key={v4()}>
                                            <label
                                                htmlFor={status}
                                                className="select-label"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={status}
                                                    className={status}
                                                    checked={
                                                        !!filterBookings?.boardingStatus?.find(
                                                            (s) => s === status
                                                        )
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            handleFilterBookings(
                                                                {
                                                                    ...filterBookings,
                                                                    boardingStatus:
                                                                        filterBookings?.boardingStatus
                                                                            ? [
                                                                                  ...filterBookings.boardingStatus,
                                                                                  status,
                                                                              ]
                                                                            : [
                                                                                  status,
                                                                              ],
                                                                }
                                                            )
                                                        } else if (
                                                            filterBookings?.boardingStatus
                                                        ) {
                                                            const tempBoarding: BoardingStatus[] =
                                                                filterBookings.boardingStatus

                                                            const index =
                                                                tempBoarding.findIndex(
                                                                    (t) =>
                                                                        t ===
                                                                        status
                                                                )
                                                            tempBoarding.splice(
                                                                index,
                                                                1
                                                            )
                                                            handleFilterBookings(
                                                                {
                                                                    ...filterBookings,
                                                                    boardingStatus:
                                                                        tempBoarding,
                                                                }
                                                            )
                                                        }
                                                    }}
                                                />
                                                <p>
                                                    {boardingStatusLabel(
                                                        status
                                                    )}
                                                </p>
                                            </label>
                                        </Selector>
                                    )
                                })}
                            </div>
                        </label>
                    </DropdownFilter>
                    {/* ----------- PASSAGEIRO ------------------ */}
                    <TextFilter>
                        <label htmlFor="passanger">
                            <FaUser />
                            <input
                                type="text"
                                name="passanger"
                                id="passanger"
                                placeholder="Passageiro"
                                onChange={(e) =>
                                    handleFilterBookings({
                                        ...filterBookings,
                                        passenger: e.target.value.toUpperCase(),
                                    })
                                }
                            />
                        </label>
                    </TextFilter>
                    {/* ----------- LOCALIZADOR ----------------- */}
                    <TextFilter>
                        <label htmlFor="recordLocator">
                            <AiFillTag />
                            <input
                                type="text"
                                name="recordLocator"
                                id="recordLocator"
                                placeholder="Localizador"
                                onChange={(e) => {
                                    if (e.target.value.length === 0) {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            recordLocator: undefined,
                                        })
                                    }
                                    if (e.target.value.length === 6) {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            recordLocator:
                                                e.target.value.toUpperCase(),
                                        })
                                    }
                                }}
                            />
                        </label>
                    </TextFilter>
                    {/* ----------- LOCALIZADOR ----------------- */}
                    <TextFilter>
                        <label htmlFor="ticketNumber">
                            <AiOutlineFieldNumber />
                            <input
                                type="text"
                                name="ticketNumber"
                                id="ticketNumber"
                                placeholder="Bilhete"
                                onChange={(e) => {
                                    if (e.target.value.length === 0) {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            ticketNumber: undefined,
                                        })
                                    }
                                    if (e.target.value.length === 13) {
                                        handleFilterBookings({
                                            ...filterBookings,
                                            ticketNumber:
                                                e.target.value.toUpperCase(),
                                        })
                                    }
                                }}
                            />
                        </label>
                    </TextFilter>
                </div>
            )}
            {(selectedCia ||
                selectedStatus ||
                selectedCostCenter ||
                selectedClient ||
                selectedPeriod ||
                selectedAgency) && (
                <div
                    className="disabled"
                    onClick={() => {
                        setSelectedCia(false)
                        setSelectedStatus(false)
                        setSelectedCostCenter(false)
                        setSelectedClient(false)
                        setSelectedPeriod(false)
                        setSelectedAgency(false)
                    }}
                />
            )}
            {openSelecteCustomData && (
                <DatePicker
                    hasRange
                    closeDatePicker={() => setOpenSelectCustomData(false)}
                />
            )}
        </FilterContainer>
    )
}
