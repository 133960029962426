import styled from 'styled-components'

export const PersonalCredentialContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, minmax(100px, 1fr));
    grid-template-rows: min-content;
    font-size: 2.3rem 0 1.6rem 0;
    border-bottom: 1px solid rgba(194, 194, 194, 0.31);
    padding: 2.3rem 0 1.6rem 0;

    .center {
        text-align: center;
    }
    .start {
        text-align: start;
    }
    .end {
        text-align: end;
    }
    h1 {
        font-size: 1.6rem;
        font-weight: 500;
        color: rgba(51, 51, 51, 0.75);
        line-height: 24px;
    }
    .share-credential {
        border: none;
        background-color: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        svg {
            width: 2rem;
            height: 2rem;
            margin-left: 1rem;
            margin-bottom: 4px;
        }
        p {
            font-size: 1.6rem;
            font-weight: 500;
            color: rgba(51, 51, 51, 0.75);
        }
    }
    .edit-remove {
        button {
            border: none;
            background-color: inherit;
            outline: none;
            svg {
                height: 2rem;
                width: 2rem;
                color: #323232;
            }
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
`
