/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { InitialContainer } from '../../container/InitialContainer'
import { useAuth } from '../../hooks/use-auth'
import { ConfirmEmailContainer } from './styles'

export function ConfirmEmailPage() {
    // state
    const [timer, setTimer] = useState<number>(5)
    const [redirect, setRedirect] = useState<boolean>(false)

    // hooks
    const { confirmEmail } = useAuth()

    const [, token] = window.location.search.split('=')

    setTimeout(() => {
        if (timer > 0) {
            setTimer(timer - 1)
        }
    }, 1000)

    useEffect(() => {
        if (token && timer === 1) {
            confirmEmail(token)
            setRedirect(true)
        }
    }, [timer])

    return (
        <InitialContainer>
            <ConfirmEmailContainer>
                <h1 className="heading-font">{`Seu e-mail está sendo verificado ${timer}`}</h1>
            </ConfirmEmailContainer>
            {redirect && <Redirect to="/" />}
        </InitialContainer>
    )
}
