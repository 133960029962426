import React, { ReactNode } from 'react'
import { SideMenu } from '../SideMenu'
import { ConfigurationContentContainer } from './styles'

interface Props {
    children: ReactNode
    active: string
}

export function ConfigurationContent({ children, active }: Props) {
    return (
        <ConfigurationContentContainer>
            <div className="side-menu">
                <SideMenu active={active} />
            </div>
            <div className="content">{children}</div>
        </ConfigurationContentContainer>
    )
}
