import styled from 'styled-components'

export const PlansContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 5rem;

    .plans {
        position: relative;
        display: flex;
        flex-direction: row;
        height: 100%;
    }
`
