import styled from 'styled-components'

export const SingleSharedUserContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: min-content;
    font-size: 2.3rem 0 1.6rem 0;
    border-bottom: 1px solid rgba(194, 194, 194, 0.31);
    padding: 2.3rem 0 1.6rem 0;
    .center {
        text-align: center;
    }
    .start {
        text-align: start;
    }
    .end {
        text-align: end;
    }
    h1 {
        font-size: 1.6rem;
        font-weight: 500;
        color: rgba(51, 51, 51, 0.75);
        line-height: 24px;
        overflow: hidden;
    }
    .remove {
        border: none;
        background-color: inherit;
        outline: none;
        svg {
            width: 2rem;
            height: 2rem;
        }
    }
`
