import styled from 'styled-components'

export const ContainerStyled = styled.div`
    min-height: 100vh;
    width: 100%;
    background-color: var(--background);
    padding-bottom: 5rem;
    .switch {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 6rem auto;
        a {
            text-decoration: none;
        }
    }
    .main {
        z-index: 1;
        margin: 0 auto;
        width: 135rem;
        margin-top: 5rem;
        .is-loading {
            height: 89vh;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`
