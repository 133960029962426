/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/require-default-props */
import React from 'react'
// eslint-disable-next-line import/no-duplicates
import { addYears } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import { utcToZonedTime, format } from 'date-fns-tz'
import { FiAlertTriangle, FiPlus } from 'react-icons/fi'
import { Booking } from '../../entitie/booking'
import { SingleBookingContainer } from './styles'
import { CreditStatus } from '../../entitie/credit-status'
import { useBooking } from '../../hooks/use-booking'

interface Props {
    booking?: Booking
}

export function SingleBooking({ booking }: Props) {
    // hooks
    const { toggleBookingDetail, isRecordLocator } = useBooking()

    function creditStatusLabel(status?: CreditStatus) {
        if (status === CreditStatus.FUTURE) {
            return (
                <span
                    style={{
                        backgroundColor: '#D6DDDC',
                        color: '#333333',
                    }}
                >
                    Futuro
                </span>
            )
        }
        if (status === CreditStatus.NO_CREDIT) {
            return (
                <span
                    style={{
                        backgroundColor: '#D6DDDC',
                        color: '#333333',
                    }}
                >
                    Sem crédito
                </span>
            )
        }
        if (status === CreditStatus.PENDING) {
            return (
                <span
                    style={{
                        backgroundColor: 'rgba(231, 75, 112, 0.2)',
                        color: '#E74B70',
                    }}
                >
                    Em aberto
                </span>
            )
        }
        return (
            <span
                style={{
                    backgroundColor: 'rgba(23, 158, 233, 0.2)',
                    color: 'rgba(23, 158, 233)',
                }}
            >
                Crédito utilizado
            </span>
        )
    }

    return (
        <SingleBookingContainer
            names={`${booking?.passengers.map((name) => `${name}, `)}`}
        >
            <div className="start passanger-name">
                <div className="row">
                    <h1 className="start">{booking?.passengers[0]}</h1>
                    {booking && booking.passengers.length > 1 && (
                        <div className="more-passangers">
                            <FiPlus />
                        </div>
                    )}
                </div>
            </div>
            <h1 className="start">
                {isRecordLocator
                    ? booking?.recordLocator
                    : booking?.ticketNumber || '-'}
            </h1>
            <h1 className="center">
                {booking?.airline && booking.airline.label}
            </h1>
            <div className="center relative">
                <h1>
                    {booking?.paidAmount !== null &&
                    booking?.paidAmount !== undefined
                        ? `R$ ${
                              booking?.paidAmount.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                              }) || 0
                          }`
                        : 'Dados insuficientes'}
                </h1>
                {booking?.isCovid && <FiAlertTriangle />}
            </div>
            <div className="center status">
                {creditStatusLabel(booking?.creditStatus)}
            </div>

            {/* <h1 className="center">
                {booking?.refundExpirationDate
                    ? format(
                          new Date(booking?.refundExpirationDate || ''),
                          `dd'/'MM'/'Y`,
                          {
                              locale: ptLocale,
                          }
                      )
                    : `${format(
                          addYears(new Date(booking?.issueDate || ''), 1),
                          `dd'/'MM'/'Y`,
                          {
                              locale: ptLocale,
                          }
                      )}`}
            </h1> */}
            <h1 className="center">
                {booking && booking.refundExpirationDate
                    ? format(
                          utcToZonedTime(
                              new Date(booking.refundExpirationDate),
                              'Europe/Berlin'
                          ),
                          `dd'/'MM'/'Y`
                      )
                    : `${format(
                          addYears(
                              utcToZonedTime(
                                  new Date(booking?.issueDate || ''),
                                  'Europe/Berlin'
                              ) || 0,
                              1
                          ),
                          `dd'/'MM'/'Y`,
                          {
                              locale: ptLocale,
                          }
                      )}`}
            </h1>
            <button
                className="end info"
                type="button"
                onClick={() => toggleBookingDetail(booking?.id)}
            >
                Ver mais
            </button>
            {/* {showBookingDetail === booking?.id && (
                <BookingDetail
                    isOpen={!!showBookingDetail}
                    onRequestClose={() => toggleBookingDetail(undefined)}
                    booking={booking}
                />
            )} */}
        </SingleBookingContainer>
    )
}
