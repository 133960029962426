import React, { ReactNode } from 'react'
import { InitialContainerStyled } from './styles'
import Logo from '../../assets/adalogo.png'
import background from '../../assets/background.png'

interface AppProvideProps {
    children: ReactNode
}

export function InitialContainer({ children }: AppProvideProps) {
    return (
        <InitialContainerStyled>
            <div className="content">
                <div className="logo">
                    <img src={Logo} alt="logo-ada" />
                </div>
                <div className="form">{children}</div>
            </div>
            <div className="background">
                <h1>Vamos lá!</h1>
                <img src={background} alt="background" />
            </div>
        </InitialContainerStyled>
    )
}
