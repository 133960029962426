/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react'
import { TooltipContainer } from './styles'

interface TooltipProps {
    title: string
    className?: string
    children: ReactNode
}

export function TooltipInput({
    title,
    className = '',
    children,
}: TooltipProps) {
    return (
        <TooltipContainer className={className}>
            {children}
            <span>{title}</span>
        </TooltipContainer>
    )
}
