import React from 'react'
import Loader from 'react-loader-spinner'
import { BigLoaderContainer } from './styles'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

export function BigLoader() {
    return (
        <BigLoaderContainer>
            <div>
                <Loader
                    type="Plane"
                    color="#179EE9"
                    height={100}
                    width={100}
                    visible
                    radius={50}
                />
            </div>
        </BigLoaderContainer>
    )
}
