import styled from 'styled-components'

export const SwitchContainer = styled.button`
    position: relative;
    width: 50rem;
    height: 5rem;
    border-radius: 5rem;
    background-color: #d6dddc;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    outline: none;
    h1 {
        font-size: 2rem;
        color: #333;
        z-index: 100;
    }
    .selector {
        position: absolute;
        right: 24.9rem;
        height: 4.8rem;
        border-radius: 5rem;
        width: 50%;
        background-color: #fff;
        transition: all 0.5s ease;
    }
    .right {
        right: 1px;
    }
`
