/* eslint-disable react-hooks/exhaustive-deps */
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import React, { useEffect, useRef, useState } from 'react'
import { ConfigurationContent } from '../../components/ConfigirationContent'
import { Input } from '../../components/Input'
import { PrimaryButton } from '../../components/PrimaryButton'
import { Container } from '../../container/Container'
import { EditProfileDto } from '../../dtos/edit-profile'
import { useUser } from '../../hooks/use-user'
import { cepFormatter, phoneFormatter } from '../../utils/formatter'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { ProfileContent } from './styles'

export function ProfilePage() {
    // ref
    const formRef = useRef<FormHandles>(null)

    // hooks
    const { getUser, user, editProfile } = useUser()

    // state
    const [changePassword, setChangePassword] = useState(false)
    const [busyBtn, setBusyBtn] = useState<boolean>(false)
    const [editProfileData, setEditProfileData] = useState<EditProfileDto>({})
    const [phoneFormatted, setPhoneFormatted] = useState('')

    useEffect(() => {
        ;(async () => {
            await getUser()
        })()
        formRef.current?.setData({
            name: user.name,
            legalName: user.legalName,
            zipCode: cepFormatter(user.address.zipCode),
            street: user.address.street,
            state: user.address.state,
            complement: user.address.complement,
            number: user.address.number,
            email: user.email,
        })
        setPhoneFormatted(phoneFormatter(user.phone, 'FROM_API'))
    }, [])

    async function handleEditProfile(data: EditProfileDto) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                oldPassword: Yup.string()
                    .when('changePassword', {
                        is: 'true',
                        then: Yup.string().required('Senha obrigatória'),
                    })
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                        'Senha deve conter: letra maiúscula; letra minúscula; número, caracter especial. Mínimo de 6 dígitos.'
                    )
                    .max(32, 'A senha pode conter no máximo 32 dígitos'),
                password: Yup.string().when('changePassword', {
                    is: 'true',
                    then: Yup.string()
                        .required('Confirme sua senha')
                        .oneOf([Yup.ref('password')], 'Senhas não coincidem')
                        .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                            'Senha deve conter: letra maiúscula; letra minúscula; número, caracter especial. Mínimo de 6 dígitos.'
                        )
                        .max(32, 'A senha pode conter no máximo 32 dígitos'),
                }),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            await editProfile(editProfileData)
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <Container active="CONFIGURATIONS" switchType={undefined}>
            <ConfigurationContent active="profile">
                <Form ref={formRef} onSubmit={handleEditProfile}>
                    <ProfileContent>
                        <div className="title">
                            <h1 className="heading-font-configuration">
                                Dados Gerais
                            </h1>
                            <PrimaryButton
                                busy={busyBtn}
                                color="primary"
                                title="Salvar"
                                isSubmit
                            />
                        </div>
                        <div className="grid">
                            <div className="name">
                                <Input
                                    name="name"
                                    labelTitle="Nome"
                                    onChange={(e) =>
                                        setEditProfileData({
                                            ...editProfileData,
                                            name: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="legal-name">
                                <Input
                                    name="legalName"
                                    labelTitle="Razão social"
                                    disabled
                                />
                            </div>
                            <div className="cep">
                                <Input
                                    name="zipCode"
                                    labelTitle="CEP"
                                    disabled
                                />
                            </div>
                            <div className="street">
                                <Input
                                    name="street"
                                    labelTitle="Rua"
                                    disabled
                                />
                            </div>
                            <div className="state">
                                <Input
                                    name="state"
                                    labelTitle="Estado"
                                    disabled
                                />
                            </div>
                            <div className="complement">
                                <Input
                                    disabled
                                    name="complement"
                                    labelTitle="Complemento"
                                    onChange={(e) =>
                                        setEditProfileData({
                                            ...editProfileData,
                                            complement: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="number">
                                <Input
                                    name="number"
                                    labelTitle="Número"
                                    type="number"
                                    disabled
                                />
                            </div>
                            <div className="phone">
                                <Input
                                    name="phone"
                                    value={phoneFormatted}
                                    labelTitle="Telefone"
                                    onChange={(e) => {
                                        setPhoneFormatted(
                                            phoneFormatter(
                                                e.target.value as string,
                                                'INPUT'
                                            )
                                        )
                                        setEditProfileData({
                                            ...editProfileData,
                                            phone: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="title">
                            <h1 className="heading-font-configuration">
                                Dados da conta
                            </h1>
                        </div>
                        <div className="grid">
                            <div className="email">
                                <Input
                                    name="email"
                                    labelTitle="E-mail"
                                    disabled
                                />
                            </div>
                            <div className="fake-password">
                                <Input
                                    name="fakePassword"
                                    labelTitle="Senha"
                                    type="password"
                                    value="********"
                                    disabled
                                />
                            </div>
                            {changePassword && (
                                <>
                                    <div className="old-password">
                                        <Input
                                            name="oldPassword"
                                            labelTitle="Senha antiga"
                                            type="password"
                                            onChange={(e) => {
                                                setEditProfileData({
                                                    ...editProfileData,
                                                    password: {
                                                        ...editProfileData.password,
                                                        current: e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="new-password">
                                        <Input
                                            name="password"
                                            labelTitle="Nova senha"
                                            type="password"
                                            onChange={(e) =>
                                                setEditProfileData({
                                                    ...editProfileData,
                                                    password: {
                                                        ...editProfileData.password,
                                                        new: e.target.value,
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="change-password">
                            <button
                                type="button"
                                onClick={() =>
                                    setChangePassword(!changePassword)
                                }
                            >
                                {!changePassword ? 'Trocar senha' : 'Cancelar'}
                            </button>
                        </div>
                        <div className="change-password">
                            <Input
                                type="hidden"
                                value={`${changePassword}`}
                                name="changePassword"
                            />
                        </div>
                    </ProfileContent>
                </Form>
            </ConfigurationContent>
        </Container>
    )
}
