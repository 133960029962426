/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import { FiCheckCircle, FiInfo } from 'react-icons/fi'
import ModalContainer from 'react-modal'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import XLSX from 'xlsx'
import { ClipLoader } from 'react-spinners'
import { BookingUploadStatus, Upload } from '../../entitie/upload'
import { useBooking } from '../../hooks/use-booking'
import { useUpload } from '../../hooks/use-upload'
import { PrimaryButton } from '../PrimaryButton'
import { InfoContainer, ReportContainer } from './styles'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    currentUpload?: Upload
}

export function Report({ isOpen, onRequestClose, currentUpload }: Props) {
    // hooks
    const { deleteUpload, upload } = useUpload()
    const { refresh } = useBooking()

    // state
    const [busyFinishBtn, setBusyFinishBtn] = useState(false)
    const [showInfo, setShowInfo] = useState(false)

    function closeInfo() {
        setShowInfo(false)
    }

    function download() {
        const data =
            upload?.items
                .filter((item) => item.status === BookingUploadStatus.NOT_FOUND)
                .map((i) => {
                    return {
                        'IATA Code': i.iataCode,
                        'Localizador/bilhete': i.identifier,
                    }
                }) || []
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'sheet')
        XLSX.writeFile(
            wb,
            `Bilhetes não encontrados - ${format(new Date(), `dd'-'MM'`, {
                locale: ptLocale,
            })}.xlsx`
        )
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <ReportContainer>
                <div className="header">
                    <h1>Sua planilha foi importada</h1>
                    <p>{`Sua planilha tinha ${
                        upload?.items.length || 0
                    } bilhetes`}</p>
                </div>
                <div className="infos">
                    <div className="info">
                        <FiCheckCircle color="green" />
                        <p>
                            <span>
                                {upload?.items.filter(
                                    (item) =>
                                        item.status ===
                                        BookingUploadStatus.REPEATED
                                ).length || 0}
                            </span>{' '}
                            bilhetes já estavam na plataforma.
                        </p>
                    </div>
                    <div className="info">
                        <FiCheckCircle color="green" />
                        <p>
                            <span>
                                {upload?.items.filter(
                                    (item) =>
                                        item.status ===
                                        BookingUploadStatus.FOUND
                                ).length || 0}
                            </span>{' '}
                            bilhetes novos adicionados.
                        </p>
                    </div>
                    <div className="info">
                        <FiCheckCircle color="green" />
                        <p>
                            {/* <span>
                                {upload?.items.filter(
                                    (item) =>
                                        item.status === UploadItemStatus.FUTURE
                                ).length || 0}
                            </span>{' '}
                            {(upload?.items.filter(
                                (item) =>
                                    item.status === UploadItemStatus.FUTURE
                            ).length || 0) === 1
                                ? 'voo futuro sendo monitorado.'
                                : 'voos futuros sendo monitorados.'} */}
                            <span>0</span> voos futuros sendo monitorados
                        </p>
                    </div>
                    <div className="info not-found">
                        <FiCheckCircle color="red" />
                        <p>
                            <span>
                                {upload?.items.filter(
                                    (item) =>
                                        item.status ===
                                        BookingUploadStatus.NOT_FOUND
                                ).length || 0}
                            </span>{' '}
                            {(upload?.items.filter(
                                (item) =>
                                    item.status ===
                                    BookingUploadStatus.NOT_FOUND
                            ).length || 0) === 1
                                ? 'bilhete não encontrado'
                                : 'bilhetes não foram encontrados'}
                        </p>
                        <button
                            type="button"
                            className="info-btn"
                            onClick={() => setShowInfo(true)}
                        >
                            <FiInfo />
                        </button>
                    </div>
                </div>
                <div className="btns">
                    <PrimaryButton
                        title="Ver bilhetes não encontrados"
                        color="bordered"
                        callback={() => download()}
                    />
                    <button
                        className="submit"
                        type="button"
                        onClick={async () => {
                            setBusyFinishBtn(true)
                            if (upload) {
                                await deleteUpload()
                            }
                            refresh()
                            setBusyFinishBtn(false)
                            onRequestClose()
                        }}
                    >
                        {busyFinishBtn ? (
                            <ClipLoader size={16} color="#fff" />
                        ) : (
                            'Concluir'
                        )}
                    </button>
                </div>
            </ReportContainer>
            <ModalContainer
                isOpen={showInfo}
                onRequestClose={() => closeInfo()}
                overlayClassName="react-modal-overlay"
                className="react-modal-content"
            >
                <InfoContainer>
                    <button
                        className="close"
                        type="button"
                        onClick={() => closeInfo()}
                    >
                        X
                    </button>
                    <h1>Bilhetes não encontrados</h1>
                    <p>
                        Alguns motivos podem fazer com que seus bilhetes não
                        sejam encontrados.
                    </p>
                    <ul>
                        <li>Bilhete já expirou.</li>
                        <li>Sem credêncial com acesso ao bilhete.</li>
                        <li>Dados insuficientes fornecidos pela cia.</li>
                        <li>Foi emitido através de um GDS.</li>
                    </ul>
                </InfoContainer>
            </ModalContainer>
        </ModalContainer>
    )
}
