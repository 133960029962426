import styled from 'styled-components'
import { TooltipInput } from '../Tooltip'

interface Props {
    error: boolean
}

export const InputContainer = styled.div<Props>`
    position: relative;
    .label-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .button-tooltip {
            position: relative;
            background-color: inherit;
            border: none;
            outline: none;
            svg {
                margin-left: 0.5rem;
                color: #757575;
            }
            .tooltip-message {
                width: 20rem;
                border-radius: 0.8rem;
                padding: 0.5rem;
                position: absolute;
                background-color: #4e5150;
                display: none;
                p {
                    color: #fff;
                    font-size: 1.3rem;
                }
            }
            &:hover {
                cursor: pointer;
                .tooltip-message {
                    display: block;
                }
            }
        }
    }
    input {
        font-size: 1.5rem;
        font-family: inherit;
        color: #4e5150;
        padding: 1.2rem 2rem;
        border-radius: 1.2rem;
        border: none;
        width: 100%;
        display: block;
        transition: all 0.3s;
        background-color: #fff;
        margin: 1rem 0;
        border: 1px solid #c7d3d1;

        &:focus {
            outline: none;
        }
        &:disabled {
            background-color: rgba(214, 221, 220, 0.39);
            color: #7e8483;
        }
    }
`

export const Error = styled(TooltipInput)`
    position: absolute;
    top: 65%;
    right: 1rem;
    transform: translateY(-50%);
    z-index: 600;
    svg {
        margin: 0;
    }

    span {
        background: #c53030;
        color: #fff;

        &::before {
            border-color: #c53030 transparent;
        }
    }
`
