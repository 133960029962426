/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { IconBaseProps } from 'react-icons'
import { FiAlertCircle } from 'react-icons/fi'
import { useField } from '@unform/core'

import { AiFillInfoCircle } from 'react-icons/ai'
import { Error, InputContainer } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    labelTitle?: string
    icon?: React.ComponentType<IconBaseProps>
    toolTip?: string
}

export function Input({
    name,
    labelTitle,
    icon: Icon,
    toolTip,
    ...rest
}: Props) {
    // Refs
    const inputRef = useRef<HTMLInputElement>(null)
    const { fieldName, defaultValue, error, registerField } = useField(name)

    // state
    const [disableError, setDisableError] = useState(false)
    const [showToolTip, setShowToolTip] = useState(false)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        })
    }, [fieldName, registerField])

    return (
        <InputContainer error={!!error && !disableError}>
            <label htmlFor="name" className="label-input">
                <p>{labelTitle}</p>
                {toolTip && (
                    <button type="button" className="button-tooltip">
                        <AiFillInfoCircle />
                        <div className="tooltip-message">
                            <p>{toolTip}</p>
                        </div>
                    </button>
                )}
            </label>
            <input
                defaultValue={defaultValue}
                onChange={() => setDisableError(true)}
                id={name}
                ref={inputRef}
                {...rest}
            />
            {error && !disableError ? (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            ) : null}
        </InputContainer>
    )
}
