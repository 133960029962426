import styled from 'styled-components'

export const ConfigurationContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 3rem;
    .content {
        background-color: var(--light);
        border: 1px solid #c7d3d1;
        border-radius: 1.6rem;
        background-color: var(--light);
    }
    margin-bottom: 3rem;
`
