/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState, ReactNode } from 'react'
import { ChangePasswordDto } from '../dtos/change-password'
import { ForgotPasswordDto } from '../dtos/forgot-password'
import { LoginDto } from '../dtos/login'

import { api } from '../service/api'
import { useToast } from './use-toast'

interface IResponse {
    token: string
}

interface IAuthContext {
    token?: string
    resendEmail: boolean
    login(data: LoginDto): Promise<void>
    logout(): void
    resendEmailApi(data: string): Promise<void>
    confirmEmail(data: string): Promise<boolean>
    sendResetPasswordEmail(data: ForgotPasswordDto): Promise<void>
    changePassword(data: ChangePasswordDto): Promise<void>
    togglePrivacy(bool: boolean): void
    showPolicies: boolean
}

interface AppProvideProps {
    children: ReactNode
}

const AuthContext = createContext({} as IAuthContext)

export function AuthProvider({ children }: AppProvideProps) {
    // hook
    const { addToast } = useToast()

    // State
    const [token, setToken] = useState<string | undefined>(() => {
        return localStorage.getItem('@benv:token') || undefined
    })
    const [resendEmail, setResendEmail] = useState(false)
    const [showPolicies, setShowPolicies] = useState(false)

    async function login(data: LoginDto) {
        try {
            const signupData: LoginDto = {
                email: data.email.toLowerCase(),
                password: data.password,
            }
            const response = await api.post<IResponse>('users/auth', signupData)
            localStorage.setItem('@benv:token', response.data.token)
            setToken(response.data.token)
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            if (error.response.data.errorCode === 'EMAIL_NOT_CONFIRMED') {
                setResendEmail(true)
            }
        }
    }

    function logout() {
        setToken(undefined)
        window.location.reload()
        localStorage.removeItem('@benv:token')
    }

    async function confirmEmail(data: string) {
        try {
            await api.post('users/confirm-email', { token: data })
            return true
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
            return false
        }
    }

    async function resendEmailApi(data: string) {
        try {
            await api.post('users/resend-email-confirmation', { email: data })
            addToast({ title: 'E-mail reenviado', type: 'success' })
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function sendResetPasswordEmail(data: ForgotPasswordDto) {
        try {
            await api.post(`users/reset-password-request`, {
                email: data.email,
            })
            addToast({
                title: `Email enviado para ${data.email}`,
                type: 'success',
            })
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    async function changePassword(data: ChangePasswordDto) {
        try {
            await api.post('users/reset-password', data)
            addToast({
                title: 'Senha alterada com sucesso',
                type: 'success',
            })
        } catch (error) {
            addToast({
                description:
                    error.response && error.response.data.details
                        ? error.response && error.response.data.details.pt
                        : `${error.response.config.url} ${error.response.status}`,
                type: 'error',
                title: 'Ops!',
            })
        }
    }

    function togglePrivacy(bool: boolean) {
        setShowPolicies(bool)
    }

    return (
        <AuthContext.Provider
            value={{
                login,
                token,
                logout,
                resendEmailApi,
                sendResetPasswordEmail,
                confirmEmail,
                changePassword,
                resendEmail,
                showPolicies,
                togglePrivacy,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth(): IAuthContext {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within a ToastProvider')
    }
    return context
}
