import styled from 'styled-components'

export const ConfirmEmailContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    h1 {
        margin-bottom: 2rem;
        color: var(--tertiary);
    }
`
