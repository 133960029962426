import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import React, { useRef, useState } from 'react'
import ModalContainer from 'react-modal'
import { RequestCredentialDto } from '../../dtos/request-credential'
import { useCredential } from '../../hooks/use-credential'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { Input } from '../Input'
import { PrimaryButton } from '../PrimaryButton'
import { RequestCredentialContainer } from './styles'

interface ModalProps {
    isOpen: boolean
    onRequestClose: () => void
}

export function RequestCredential({ isOpen, onRequestClose }: ModalProps) {
    // refs
    const formRef = useRef<FormHandles>(null)

    // hooks
    const { sendRequestCredential } = useCredential()

    // state
    const [busyBtn, setBusyBtn] = useState(false)

    async function handleRequestCredential(data: RequestCredentialDto) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                targetUserEmail: Yup.string().required(
                    'Insira o e-mail para contato'
                ),
                targetUserName: Yup.string().required(
                    'Insira o nome do contato'
                ),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            const response = await sendRequestCredential(data)
            if (response) {
                onRequestClose()
            }
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <RequestCredentialContainer>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <div className="title">
                    <h1 className="heading-font-configuration">
                        Solicitar credencial
                    </h1>
                </div>
                <div className="description">
                    <p>
                        Insira abaixo o e-mail e o nome da sua agência e
                        enviaremos um e-mail com todas as instruções para o
                        compartilhamento.
                    </p>
                </div>
                <Form ref={formRef} onSubmit={handleRequestCredential}>
                    <div className="row">
                        <Input name="targetUserEmail" labelTitle="E-mail" />
                        <Input
                            name="targetUserName"
                            labelTitle="Nome do contato"
                        />
                    </div>
                    <div className="btn">
                        <PrimaryButton
                            color="primary"
                            title="Enviar solicitação"
                            isSubmit
                            busy={busyBtn}
                        />
                    </div>
                </Form>
            </RequestCredentialContainer>
        </ModalContainer>
    )
}
