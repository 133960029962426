/* eslint-disable react/require-default-props */
import React from 'react'
import ModalContainer from 'react-modal'
import { PrimaryButton } from '../PrimaryButton'
import { BookingDetailContainer, SingleError, Table } from './style'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    excelErrors?: { line: number; type: 'IATA' | 'EMPTY' | 'LENGTH' }[]
}

export function ExcelErrors({ isOpen, onRequestClose, excelErrors }: Props) {
    function labelError(type: 'IATA' | 'EMPTY' | 'LENGTH') {
        if (type === 'IATA') {
            return 'Iata da companhia'
        }
        if (type === 'EMPTY') {
            return 'Cédula vazia'
        }
        return 'Localizador/bilhete'
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <BookingDetailContainer>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <div className="header">
                    <h1>Planilha com estrutura inválida.</h1>
                    <p>
                        Encontramos alguns campos com estrutura inválida.
                        Verifique os campos descritos abaixo, atualize sua
                        planilha e importe novamente no sistema.
                    </p>
                </div>
                <Table>
                    <div className="primary-row">
                        <h1 className="center">Linha</h1>
                        <h1 className="center">Erro no campo</h1>
                    </div>
                    {excelErrors?.map((error) => (
                        <SingleError>
                            <h1 className="center">{error.line}</h1>
                            <h1 className="center">{labelError(error.type)}</h1>
                        </SingleError>
                    ))}
                </Table>
                <div className="center">
                    <PrimaryButton
                        title="Encerrar"
                        color="primary"
                        callback={() => onRequestClose()}
                    />
                </div>
            </BookingDetailContainer>
        </ModalContainer>
    )
}
