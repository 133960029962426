/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
    FiAlertCircle,
    FiAlertTriangle,
    FiAlignJustify,
    FiBarChart2,
} from 'react-icons/fi'
import { v4 } from 'uuid'
import { Link } from 'react-router-dom'
import { startOfDay, subYears } from 'date-fns'
import { ClipLoader } from 'react-spinners'
import { PrimaryButton } from '../../components/PrimaryButton'
import { Container } from '../../container/Container'
import {
    BoardingStatusContainer,
    FreeContainer,
    HomeAnalyticsContainer,
    HomeBookingsContainer,
    PenaltiesStatusContainer,
    PenaltyType,
    Table,
} from './styles'
import { SwitchButton } from '../../components/SwitchButton'
import { DoughnutStatusChart } from '../../components/DoughnutStatusChart'
import { DoughnutPenaltyChart } from '../../components/DoughnutPenaltyChart'
import { useBooking } from '../../hooks/use-booking'
import { CreditStatus } from '../../entitie/credit-status'
import { SigleDashboardBooking } from '../../components/SingleDashboardBooking'
import { BigLoader } from '../../components/BigLoader'
import { Steps } from '../StepsPage'
import { useUser } from '../../hooks/use-user'
import { TutorialSteps } from '../../entitie/tutorial-steps'
import freePlan from '../../assets/free-plan.png'

export function HomePage() {
    // hooks
    const {
        getAnalytics,
        getBookings,
        analytics,
        shouldRefresh,
        filterBookings,
        dashboardBookings,
    } = useBooking()
    const { user, subscription } = useUser()

    // state
    const [statusInPercentage, setStatusInPercentage] = useState(true)
    const [penaltiesInPercentage, setPenaltiesInPercentage] = useState(true)
    const [busyTable, setBusyTable] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            setBusyTable(true)

            await getAnalytics(filterBookings || {})
            await getBookings(
                0,
                {
                    creditStatus: [CreditStatus.PENDING],
                    period: {
                        startDate: startOfDay(subYears(new Date(), 1)),
                        endDate: new Date(),
                    },
                },
                false
            )
            setBusyTable(false)
        })()
    }, [shouldRefresh])

    useEffect(() => {
        setBusyTable(true)
        ;(async () => {
            if (filterBookings) {
                await getAnalytics(filterBookings || {})
            }
            setBusyTable(false)
        })()
    }, [filterBookings, shouldRefresh])

    return (
        <Container active="DASHBOARD" switchType="SYNTHETIC">
            {user?.tutorialStep ? (
                <>
                    {user?.tutorialStep !== TutorialSteps.finished ? (
                        <Steps />
                    ) : (
                        <>
                            {busyTable ? (
                                <BigLoader />
                            ) : (
                                <>
                                    <HomeAnalyticsContainer>
                                        <div className="title-row">
                                            <div className="title">
                                                <FiBarChart2 />
                                                <h1>
                                                    Bilhetes emitidos e não
                                                    voados
                                                </h1>
                                            </div>
                                            {/* <div className="title-btns">
                                <PrimaryButton
                                    icon={AiFillPieChart}
                                    title="Analisar"
                                    color="red-white"
                                />
                            </div> */}
                                        </div>
                                        {analytics && (
                                            <div className="values-grid">
                                                <div className="value">
                                                    <p>Total pago</p>
                                                    <h2>{`R$ ${
                                                        analytics.paidAmount.toLocaleString(
                                                            'pt-br',
                                                            {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            }
                                                        ) || 0
                                                    }`}</h2>
                                                </div>
                                                <div className="value">
                                                    <p>
                                                        Crédito para reemissão
                                                    </p>
                                                    <h2>{`R$ ${
                                                        analytics.availableCredit.toLocaleString(
                                                            'pt-br',
                                                            {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            }
                                                        ) || 0
                                                    }`}</h2>
                                                </div>
                                                <div className="value">
                                                    <p>
                                                        Crédito para reembolso
                                                    </p>
                                                    <h2>{`R$ ${
                                                        analytics.availableRefund.toLocaleString(
                                                            'pt-br',
                                                            {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            }
                                                        ) || 0
                                                    }`}</h2>
                                                </div>
                                                <div className="value">
                                                    <p>Bilhetes com crédito</p>
                                                    <h2>{`${
                                                        analytics.numberOfBookingsWithCredit
                                                            ? analytics.numberOfBookingsWithCredit
                                                            : 0
                                                    }`}</h2>
                                                </div>
                                            </div>
                                        )}
                                        <div className="analytics-grid">
                                            <BoardingStatusContainer>
                                                <div className="title-status-row">
                                                    <div className="title">
                                                        <h1 className="heading-font-configuration">
                                                            Status
                                                        </h1>
                                                        <p>
                                                            Situação de embarque
                                                            dos vôos.
                                                        </p>
                                                    </div>
                                                    <div className="toggle">
                                                        <SwitchButton
                                                            isNumber
                                                            callback={() => {
                                                                setStatusInPercentage(
                                                                    !statusInPercentage
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="chart-label">
                                                    <DoughnutStatusChart
                                                        data={[
                                                            {
                                                                Value: analytics?.completelyFlownCount,
                                                                Label: ' ',
                                                                color: '#08205E',
                                                            },
                                                            {
                                                                Value: analytics?.partiallyFlownCount,
                                                                Label: ' ',
                                                                color: '#08205e99',
                                                            },
                                                            {
                                                                Value: analytics?.notFlownCount,
                                                                Label: ' ',
                                                                color: '#939DB6',
                                                            },
                                                        ]}
                                                    />
                                                    <div className="labels">
                                                        <div className="label">
                                                            <div className="title">
                                                                <div
                                                                    className="circle"
                                                                    style={{
                                                                        backgroundColor:
                                                                            '#08205E',
                                                                    }}
                                                                />
                                                                <p className="name">
                                                                    Voados
                                                                </p>
                                                            </div>
                                                            <div className="value">
                                                                {analytics ? (
                                                                    <>
                                                                        {statusInPercentage
                                                                            ? `${(
                                                                                  (analytics.completelyFlownCount /
                                                                                      (analytics.completelyFlownCount +
                                                                                          analytics.partiallyFlownCount +
                                                                                          analytics.notFlownCount)) *
                                                                                      100 ||
                                                                                  0
                                                                              ).toFixed(
                                                                                  1
                                                                              )}%`
                                                                            : `${analytics.completelyFlownCount}`}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="label">
                                                            <div className="title">
                                                                <div
                                                                    className="circle"
                                                                    style={{
                                                                        backgroundColor:
                                                                            '#08205e99',
                                                                    }}
                                                                />
                                                                <p className="name">
                                                                    Parcialmente
                                                                    voados
                                                                </p>
                                                            </div>
                                                            <div className="value">
                                                                {analytics ? (
                                                                    <>
                                                                        {statusInPercentage
                                                                            ? `${(
                                                                                  (analytics.partiallyFlownCount /
                                                                                      (analytics.completelyFlownCount +
                                                                                          analytics.partiallyFlownCount +
                                                                                          analytics.notFlownCount)) *
                                                                                      100 ||
                                                                                  0
                                                                              ).toFixed(
                                                                                  1
                                                                              )}%`
                                                                            : `${analytics.partiallyFlownCount}`}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="label">
                                                            <div className="title">
                                                                <div
                                                                    className="circle"
                                                                    style={{
                                                                        backgroundColor:
                                                                            '#939DB6',
                                                                    }}
                                                                />
                                                                <p className="name">
                                                                    Não voados
                                                                </p>
                                                            </div>
                                                            <div className="value">
                                                                {analytics ? (
                                                                    <>
                                                                        {statusInPercentage
                                                                            ? `${(
                                                                                  (analytics.notFlownCount /
                                                                                      (analytics.completelyFlownCount +
                                                                                          analytics.partiallyFlownCount +
                                                                                          analytics.notFlownCount)) *
                                                                                      100 ||
                                                                                  0
                                                                              ).toFixed(
                                                                                  1
                                                                              )}%`
                                                                            : `${analytics.notFlownCount}`}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </BoardingStatusContainer>
                                            {subscription?.subscription.plan
                                                .type !== 'FREE' ? (
                                                <PenaltiesStatusContainer>
                                                    <div className="title-status-row">
                                                        <div className="title">
                                                            <h1 className="heading-font-configuration">
                                                                Multas e
                                                                créditos
                                                            </h1>
                                                            {penaltiesInPercentage ? (
                                                                <p>
                                                                    Em valor
                                                                    percentual
                                                                    ao total
                                                                    pago.
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    Em valor
                                                                    pago.
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="toggle">
                                                            <SwitchButton
                                                                isNumber={false}
                                                                callback={() =>
                                                                    setPenaltiesInPercentage(
                                                                        !penaltiesInPercentage
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="charts-grid">
                                                        <PenaltyType>
                                                            <DoughnutPenaltyChart
                                                                color={[
                                                                    '#E2E5E5',
                                                                    '#08205E',
                                                                ]}
                                                                data={[
                                                                    {
                                                                        Value: analytics?.paidAmount,
                                                                        Label: ' ',
                                                                    },
                                                                    {
                                                                        Value: analytics?.availableCredit,
                                                                        Label: ' ',
                                                                    },
                                                                ]}
                                                            />
                                                            <div className="label-chart">
                                                                <div className="label">
                                                                    <h2>
                                                                        Crédito
                                                                    </h2>
                                                                    <p>
                                                                        Para
                                                                        reemissão
                                                                    </p>
                                                                </div>
                                                                <h1 className="value">
                                                                    {analytics ? (
                                                                        <>
                                                                            {penaltiesInPercentage
                                                                                ? `${(
                                                                                      (analytics.availableCredit /
                                                                                          analytics.paidAmount) *
                                                                                          100 ||
                                                                                      0
                                                                                  ).toFixed(
                                                                                      1
                                                                                  )}%`
                                                                                : `R$ ${
                                                                                      analytics?.availableCredit.toLocaleString(
                                                                                          'pt-br',
                                                                                          {
                                                                                              minimumFractionDigits: 0,
                                                                                              maximumFractionDigits: 0,
                                                                                          }
                                                                                      ) ||
                                                                                      0
                                                                                  }`}
                                                                        </>
                                                                    ) : null}
                                                                </h1>
                                                            </div>
                                                        </PenaltyType>
                                                        <PenaltyType>
                                                            <DoughnutPenaltyChart
                                                                color={[
                                                                    '#E2E5E5',
                                                                    '#08205E',
                                                                ]}
                                                                data={[
                                                                    {
                                                                        Value: analytics?.paidAmount,
                                                                        Label: ' ',
                                                                    },
                                                                    {
                                                                        Value: analytics?.availableRefund,
                                                                        Label: ' ',
                                                                    },
                                                                ]}
                                                            />
                                                            <div className="label-chart">
                                                                <div className="label">
                                                                    <h2>
                                                                        Crédito
                                                                    </h2>
                                                                    <p>
                                                                        Para
                                                                        reembolso
                                                                    </p>
                                                                </div>
                                                                <h1 className="value">
                                                                    {analytics ? (
                                                                        <>
                                                                            {penaltiesInPercentage
                                                                                ? `${(
                                                                                      (analytics.availableRefund /
                                                                                          analytics.paidAmount) *
                                                                                          100 ||
                                                                                      0
                                                                                  ).toFixed(
                                                                                      1
                                                                                  )}%`
                                                                                : `R$ ${
                                                                                      analytics?.availableRefund.toLocaleString(
                                                                                          'pt-br',
                                                                                          {
                                                                                              minimumFractionDigits: 0,
                                                                                              maximumFractionDigits: 0,
                                                                                          }
                                                                                      ) ||
                                                                                      0
                                                                                  }`}
                                                                        </>
                                                                    ) : null}
                                                                </h1>
                                                            </div>
                                                        </PenaltyType>
                                                        <PenaltyType>
                                                            <DoughnutPenaltyChart
                                                                color={[
                                                                    '#E2E5E5',
                                                                    '#F1C523',
                                                                ]}
                                                                data={[
                                                                    {
                                                                        Value: analytics?.paidAmount,
                                                                        Label: '',
                                                                    },
                                                                    {
                                                                        Value: analytics?.noShowPenalty,
                                                                        Label: '',
                                                                    },
                                                                ]}
                                                            />
                                                            <div className="label-chart">
                                                                <div className="label">
                                                                    <h2>
                                                                        Multa
                                                                    </h2>
                                                                    <p>
                                                                        No show
                                                                    </p>
                                                                </div>
                                                                <h1 className="value">
                                                                    {analytics ? (
                                                                        <>
                                                                            {penaltiesInPercentage
                                                                                ? `${(
                                                                                      (analytics.noShowPenalty /
                                                                                          analytics.paidAmount) *
                                                                                          100 ||
                                                                                      0
                                                                                  ).toFixed(
                                                                                      1
                                                                                  )}%`
                                                                                : `R$ ${
                                                                                      analytics?.noShowPenalty.toLocaleString(
                                                                                          'pt-br',
                                                                                          {
                                                                                              minimumFractionDigits: 0,
                                                                                              maximumFractionDigits: 0,
                                                                                          }
                                                                                      ) ||
                                                                                      0
                                                                                  }`}
                                                                        </>
                                                                    ) : null}
                                                                </h1>
                                                            </div>
                                                        </PenaltyType>
                                                        <PenaltyType>
                                                            <DoughnutPenaltyChart
                                                                color={[
                                                                    '#E2E5E5',
                                                                    '#F1C523',
                                                                ]}
                                                                data={[
                                                                    {
                                                                        Value: analytics?.paidAmount,
                                                                        Label: '',
                                                                    },
                                                                    {
                                                                        Value: analytics?.cancelPenalty,
                                                                        Label: '',
                                                                    },
                                                                ]}
                                                            />
                                                            <div className="label-chart">
                                                                <div className="label">
                                                                    <h2>
                                                                        Multa
                                                                    </h2>
                                                                    <p>
                                                                        Cancelamentos
                                                                    </p>
                                                                </div>
                                                                <h1 className="value">
                                                                    {analytics ? (
                                                                        <>
                                                                            {penaltiesInPercentage
                                                                                ? `${(
                                                                                      (analytics.cancelPenalty /
                                                                                          analytics.paidAmount) *
                                                                                          100 ||
                                                                                      0
                                                                                  ).toFixed(
                                                                                      1
                                                                                  )}%`
                                                                                : `R$ ${
                                                                                      analytics?.cancelPenalty.toLocaleString(
                                                                                          'pt-br',
                                                                                          {
                                                                                              minimumFractionDigits: 0,
                                                                                              maximumFractionDigits: 0,
                                                                                          }
                                                                                      ) ||
                                                                                      0
                                                                                  }`}
                                                                        </>
                                                                    ) : null}
                                                                </h1>
                                                            </div>
                                                        </PenaltyType>
                                                    </div>
                                                </PenaltiesStatusContainer>
                                            ) : (
                                                <FreeContainer>
                                                    <div className="img">
                                                        <img
                                                            src={freePlan}
                                                            alt="imagem-plano-grátis"
                                                            className="img-men"
                                                        />
                                                    </div>
                                                    <div className="free-plan">
                                                        <div className="info-plan">
                                                            <div className="title">
                                                                <h1>
                                                                    Não deixe
                                                                    seu crédito
                                                                    expirar.
                                                                </h1>
                                                            </div>
                                                            <div className="info">
                                                                <p>
                                                                    Faça upgrade
                                                                    para
                                                                    Business e
                                                                    tome uma
                                                                    ação já!
                                                                </p>
                                                                <p>
                                                                    Começe a ter
                                                                    total
                                                                    controle dos
                                                                    seus
                                                                    bilhetes.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <Link to="/plans">
                                                            <PrimaryButton
                                                                title="Mais informações"
                                                                color="primary"
                                                            />
                                                        </Link>
                                                    </div>
                                                </FreeContainer>
                                            )}
                                        </div>
                                    </HomeAnalyticsContainer>
                                    {subscription?.subscription.plan.type !==
                                        'FREE' && (
                                        <HomeBookingsContainer>
                                            {dashboardBookings ? (
                                                <>
                                                    <div className="title-row">
                                                        <div className="title">
                                                            <h1>
                                                                Próximos
                                                                bilhetes à
                                                                expirar
                                                            </h1>
                                                            {analytics?.nextMonthCredit &&
                                                            analytics.nextMonthCredit >
                                                                0 ? (
                                                                <>
                                                                    <FiAlertCircle />
                                                                    <p>{`R$${analytics?.nextMonthCredit.toLocaleString(
                                                                        'pt-br',
                                                                        {
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 0,
                                                                        }
                                                                    )} expiram nos próximos dias.`}</p>
                                                                </>
                                                            ) : null}
                                                        </div>
                                                        <div className="title-btns">
                                                            <Link to="/analytics">
                                                                <PrimaryButton
                                                                    icon={
                                                                        FiAlignJustify
                                                                    }
                                                                    title="Analítico"
                                                                    color="red-white"
                                                                />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <Table>
                                                        <div className="primary-row">
                                                            <h1 className="start">
                                                                Passageiro
                                                            </h1>
                                                            <h1 className="start">
                                                                Localizador
                                                            </h1>
                                                            <h1 className="center">
                                                                Companhia
                                                            </h1>
                                                            <h1 className="center">
                                                                Status
                                                            </h1>
                                                            <h1 className="center">
                                                                Crédito
                                                            </h1>
                                                            <h1 className="end">
                                                                Data de
                                                                expiração
                                                            </h1>
                                                        </div>
                                                        {dashboardBookings?.map(
                                                            (booking) => {
                                                                return (
                                                                    <SigleDashboardBooking
                                                                        key={v4()}
                                                                        booking={
                                                                            booking
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                    </Table>
                                                    <div className="legend">
                                                        <FiAlertTriangle />
                                                        <p>
                                                            Bilhetes com
                                                            possíveis alterações
                                                            de reembolso devido
                                                            à alterações
                                                            involuntárias.
                                                        </p>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="loader">
                                                    <ClipLoader />
                                                </div>
                                            )}
                                        </HomeBookingsContainer>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            ) : (
                <BigLoader />
            )}
        </Container>
    )
}
