import { startOfDay, subYears } from 'date-fns'
import React from 'react'
import { useBooking } from '../../hooks/use-booking'
import { SwitchContainer } from './styles'

interface Props {
    switchType: 'SYNTHETIC' | 'ANALYTICAL'
}

export function Switch({ switchType }: Props) {
    const { handleFilterBookings, filterBookings } = useBooking()

    return (
        <SwitchContainer
            type="button"
            onClick={() => {
                if (filterBookings?.typeOfPeriod === 'ALL_TIME') {
                    handleFilterBookings({
                        ...filterBookings,
                        creditStatus: undefined,
                        period: {
                            startDate: startOfDay(subYears(new Date(), 1)),
                            endDate: new Date(),
                        },
                        typeOfPeriod: 'YEAR',
                        recordLocator: undefined,
                        passenger: undefined,
                    })
                } else {
                    handleFilterBookings({
                        ...filterBookings,
                        creditStatus: undefined,
                        recordLocator: undefined,
                        passenger: undefined,
                    })
                }
            }}
        >
            <h1>Sintético</h1>
            <h1>Analítico</h1>
            <div
                className={`selector ${switchType === 'ANALYTICAL' && 'right'}`}
            />
        </SwitchContainer>
    )
}
